import { Component } from "react";
import "./recordPopup.scss";
import ReactDOM from "react-dom";
import {
  DKLabel,
  DKInput,
  showToast,
  TOAST_TYPE,
  isEmpty,
  DKSpinner,
  DKIcons,
  DKButton,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import DataGridUtility from "../../utility/DataGridUtility";
import Popup from "../common/Popup";
import Table from "../../services/table";
import { DealManager } from "../../managers/DealManager";
import { showAddColumnPopup } from "../common/AddColumnPopup";
import TenantManager from "../../managers/TenantManager";
import { PipelineManager } from "../../managers/PipelineManager";
import Utility, {
  getRandomHexString,
  sanitizeForExcel
} from "../../utility/Utility";
import { store } from "../../redux/store";
import {
  deleteRecord,
  fetchRecordsByTable
} from "../../redux/slices/recordSlice";
import { addRecord, updateRecord } from "../../redux/slices/recordSlice";
import TableDataParser from "../../Helper/TableDataParser";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { DEFAULT_PAGE_SIZE } from "../common/DataGridHolder";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_SEARCH_QUERY
} from "../../redux/slices/tableMetaDataSlice";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../../utility/Date";
import PermissionService from "../../services/common/permission";
import { DetailAddressInputField } from "../common/DetailedAddress";
import { IContactAddress } from "../../model/BooksContact";
import CustomNumberFormatInput from "../books/custom_number/CustomNumberFormatInput";
import {
  APP_NAME,
  CURRENCY_PRECISION,
  CUSTOM_NUMBER_INPUT_MODULES,
  ERROR_MESSAGE_FOR_FORM,
  INPUT_TYPE_TEXT_AREA,
  TEXT_AREA_DEFAULT_LENGTH
} from "../../constants/Constant";
import { Provider } from "react-redux";
import {
  fetchContactSequenceFormats,
  fetchDealSequenceFormats
} from "../../redux/slices/booksSlice";
import { IColumn } from "../../model/Table";
import { roundingOffStr } from "../../Helper/BooksHelper";
import { getCurrencySymbolFromCode } from "../../constants/Currencies";
import { AccountService } from "../../services/accounts";
import ApiConstants from "../../constants/ApiConstants";
import {
  DealApprovalHelper,
  buildPayloadForDealApproval,
  getDealApprovalAlert,
  showApprovalTriggerFailedAlert,
  showApprovalTriggerSuccessAlert
} from "../../Helper/DealApprovalHelper";
import { DealService } from "../../services/deal";
import { PhoneNumberInput } from "../common/PhoneNumberInput";
import parsePhoneNumberFromString from "libphonenumber-js";
export interface IAddNewRecordPopUpProps {
  tableName: string;
  recordId?: string;
  recordIds?: string[];
  id: string;
  onSave?: (data: any) => void;
  onClose?: () => void;
  defaultValues: any;
}
export interface IAddNewRecordPopUpState {
  formFields: any[];
  autoNumberingFormat: any;
  numberingFormat: any;
  canValidate: boolean;
  commonInvalidMsg: boolean;
}
class AddNewRecordPopUp extends Component<
  IAddNewRecordPopUpProps,
  IAddNewRecordPopUpState
> {
  columns = [];
  isUpdated: boolean = false;
  isBulkUpdate: boolean = false;
  defaultValues: any[] = [];
  permissions: any = null;
  cachedAccountForDisplay: any = null;
  cachedPriceBookForDisplay: any = null;
  allCurrencies: any = [];
  preserveDefaultValues: any[] = [];
  isMultiCurrencyEnable: boolean = false;
  contactSeqCode: string = "";
  dealSeqCode: string = "";
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      autoNumberingFormat: {},
      numberingFormat: {},
      canValidate: false,
      commonInvalidMsg: false
    };
    this.defaultValues = { ...(this.props.defaultValues || {}) };
    this.preserveDefaultValues = { ...(this.props.defaultValues || {}) };
    this.isUpdated = !isEmpty(this.props.recordId);
    this.isBulkUpdate = !isEmpty(this.props.recordIds);
    this.allCurrencies = [];
    this.isMultiCurrencyEnable = false;
    this.contactSeqCode = "";
    this.dealSeqCode = "";
  }
  componentDidMount() {
    this.buildForm();
    this.permissions = selectPermissionsByModule(
      store.getState(),
      this.props.tableName
    );

    this.props.tableName === TABLES.CONTACT &&
      store.dispatch(
        fetchContactSequenceFormats({
          payload: {
            application: APP_NAME,
            module: CUSTOM_NUMBER_INPUT_MODULES.CONTACT
          }
        })
      );
    this.props.tableName === TABLES.DEAL &&
      store.dispatch(
        fetchDealSequenceFormats({
          payload: {
            application: APP_NAME,
            module: CUSTOM_NUMBER_INPUT_MODULES.DEAL
          }
        })
      );
    this.allCurrencies = store.getState().currency?.data?.content ?? [];
    this.isMultiCurrencyEnable =
      store.getState().books?.tenantsDetails?.multicurrencyEnabled ?? false;

    this.contactSeqCode =
      store
        .getState()
        .books?.contactSequenceFormats?.find((item) => item.isDefault)
        ?.sequenceCode ?? "";
    this.dealSeqCode =
      store
        .getState()
        .books?.dealSequenceFormats?.find((item) => item.isDefault)
        ?.sequenceCode ?? "";
  }
  buildForm = () => {
    this.columns = [];
    this.columns = Utility.deepCloneObject(
      TableManger.getTableFilteredColumns(
        this.props.tableName,
        (column: IColumn) => {
          const isEditable = column.editable && column.uiVisible;

          if (![TABLES.CONTACT, TABLES.DEAL].includes(this.props.tableName))
            return isEditable;

          const isNonEditableRequiredColumn = [
            COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE,
            COLUMN_CODE.CONTACT.SEQUENCE_FORMAT,
            COLUMN_CODE.DEAL.CURRENCY_CODE,
            COLUMN_CODE.DEAL.EXCHANGE_RATE
          ].includes(column.columnCode);

          return isEditable ? true : isNonEditableRequiredColumn;
        },
        false
      )
    );
    let filteredColumns = Utility.deepCloneObject(this.columns);
    filteredColumns?.forEach((column) => {
      if (!Utility.isEmptyObject(column.options)) {
        column.options = column.options.filter(
          (option) => option?.name && !Utility.isEmptyObject(option?.name)
        );
      }
    });
    this.columns = Utility.deepCloneObject(filteredColumns);

    this.preserveDefaultValues = TableManger.getTableFilteredColumns(
      this.props.tableName,
      (column: IColumn) => {
        return column.editable && column.uiVisible;
      },
      false
    );

    let docSequenceCodeColumnId,
      sequenceFormatColumnId = null;

    if (
      this.props.tableName === TABLES.CONTACT ||
      this.props.tableName === TABLES.SEGMENT
    ) {
      const _permissionService = PermissionService.getInstance();
      const modulePermissions = _permissionService.getUserPermissionByModule(
        _permissionService.getUpdatedModuleName(this.props.tableName)
      );
      if (
        !modulePermissions.includes(
          USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM
        )
      ) {
        let index = this.columns.findIndex(
          (col) =>
            col.id ===
            TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.LABEL)
        );
        this.columns.splice(
          index + 1,
          0,
          TableManger.getColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.OWNER_ID)
        );
      }

      docSequenceCodeColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE
      );
      sequenceFormatColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.SEQUENCE_FORMAT
      );
    }

    if (this.props.tableName === TABLES.DEAL) {
      docSequenceCodeColumnId = TableManger.getColumnId(
        TABLES.DEAL,
        COLUMN_CODE.DEAL.DOCUMENT_SEQUENCE_CODE
      );
      sequenceFormatColumnId = TableManger.getColumnId(
        TABLES.DEAL,
        COLUMN_CODE.DEAL.SEQUENCE_FORMAT
      );
    }

    this.putAdditionalFields().then(() => {
      if (this.isBulkUpdate) {
        this.columns = this.columns.map((col) => ({
          ...col,
          requiredByUser: false,
          required: false
        }));
      }

      let formFields = DataGridUtility.getFormElements(
        this.columns,
        this.defaultValues
      );
      let filteredColumns = formFields;
      const _permissionService = PermissionService.getInstance();
      const modulePermissions = _permissionService.getUserPermissionByModule(
        _permissionService.getUpdatedModuleName(this.props.tableName)
      );
      const OWNER_ID = TableManger.getColumnId(
        this.props.tableName,
        COLUMN_CODE.CONTACT.OWNER_ID
      );
      const SUB_OWNER_ID = TableManger.getColumnId(
        this.props.tableName,
        COLUMN_CODE.CONTACT.SUB_OWNER_ID
      );
      if (
        [
          TABLES.ACCOUNT,
          TABLES.CONTACT,
          TABLES.DEAL,
          TABLES.ACTIVITY,
          TABLES.SEGMENT
        ].includes(this.props.tableName)
      ) {
        if (
          !modulePermissions.includes(
            USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM
          )
        ) {
          filteredColumns = formFields.filter((col) => col.id !== SUB_OWNER_ID);
          filteredColumns.forEach((col) => {
            if (col.id === OWNER_ID) {
              col.readOnly = true;
            }
            return col;
          });
        }

        const detailedAddressColumnId = TableManger.getColumnId(
          this.props.tableName,
          COLUMN_CODE.CONTACT.DETAILED_ADDRESS
        );
        const hiddenColumns =
          store.getState().userPref.hiddenColumns?.[
            this.props.tableName.toUpperCase()
          ] || [];
        if (
          this.isBulkUpdate ||
          hiddenColumns.includes(detailedAddressColumnId)
        ) {
          filteredColumns = formFields.filter(
            (col) => col.columnCode !== COLUMN_CODE.CONTACT.DETAILED_ADDRESS
          );
        }

        // Update currency field values
        let dealCurrencyIndex = filteredColumns.findIndex(
          (col) => col.columnCode === COLUMN_CODE.DEAL.CURRENCY_CODE
        );
        let dealExchangeRateIndex = filteredColumns.findIndex(
          (col) => col.columnCode === COLUMN_CODE.DEAL.EXCHANGE_RATE
        );
        let dealAmountIndex = filteredColumns.findIndex(
          (col) => col.columnCode === COLUMN_CODE.DEAL.AMOUNT
        );
        let defaultDealAmountIndex = this.preserveDefaultValues.findIndex(
          (col) => col.columnCode === COLUMN_CODE.DEAL.AMOUNT
        );

        if (dealCurrencyIndex !== -1) {
          filteredColumns[dealCurrencyIndex].value = !Utility.isEmptyObject(
            filteredColumns[dealCurrencyIndex].value
          )
            ? filteredColumns[dealCurrencyIndex].value
            : TenantManager.getCRMCurrencyCode();
          filteredColumns[dealExchangeRateIndex].value = !Utility.isEmptyObject(
            filteredColumns[dealExchangeRateIndex]?.value
          )
            ? filteredColumns[dealExchangeRateIndex].value
            : 1;
          filteredColumns[dealAmountIndex].title = `${
            this.preserveDefaultValues[defaultDealAmountIndex].name
          } (${getCurrencySymbolFromCode(
            filteredColumns[dealCurrencyIndex].value
          )})`;
        }

        const isContactLinkEnabled =
          store.getState().tenant?.crmSettings?.contactLinkingDisabledFor ?? [];
        let dealContactIdIndex = filteredColumns.findIndex(
          (col) => col.columnCode === COLUMN_CODE.DEAL.CONTACT_ID
        );
        if (
          TABLES.DEAL === this.props.tableName &&
          dealContactIdIndex !== -1 &&
          isContactLinkEnabled?.includes("DEAL")
        ) {
          filteredColumns.splice(dealContactIdIndex, 1);
        }
      }

      let updatedValues = { ...this.state.numberingFormat };
      updatedValues.defaultValue = formFields.find(
        (field) => (field.key || field.id) === docSequenceCodeColumnId
      )?.value;
      updatedValues.value = formFields?.find(
        (field) => (field.key || field.id) === sequenceFormatColumnId
      )?.value;

      let contactPriceListIdIndex = filteredColumns.findIndex(
        (col) => col.columnCode === COLUMN_CODE.CONTACT.PRICE_LIST
      );

      const priceBookPermissions = _permissionService.getUserPermissionByModule(
        _permissionService.getUpdatedModuleName(TABLES.BOOKS_PRICE_LIST)
      );

      if (
        TABLES.CONTACT === this.props.tableName &&
        contactPriceListIdIndex !== -1 &&
        !priceBookPermissions.includes(USER_ACTION_TYPES.ASSIGN_TO_CONTACT)
      ) {
        filteredColumns.splice(contactPriceListIdIndex, 1);
      }

      filteredColumns.forEach((field) => {
        if (
          !Utility.isEmptyObject(field) &&
          field.type === INPUT_TYPE.DROPDOWN
        ) {
          let fieldIndex = this.state.formFields.findIndex(
            (formField) => formField.id === field.id
          );
          if (fieldIndex !== -1) {
            field.value = this.state.formFields[fieldIndex]?.value ?? null;
          }
        }
        if (
          field.id ===
          TableManger.getColumnId(
            TABLES.CONTACT,
            COLUMN_CODE.CONTACT.PRICE_LIST
          )
        ) {
          const priceBook: any = store.getState().priceBook.priceBookList;
          const priceBookList: any = priceBook?.content ?? [];
          let index = priceBookList?.findIndex(
            (pb) => pb.id === field?.value?.[0]
          );
          if (index === -1) {
            const priceBookColumn = this.columns.find(
              (column) => column.columnCode === COLUMN_CODE.CONTACT.PRICE_LIST
            );
            const updatedPB = priceBookList.find(
              (pb) =>
                pb.name ===
                this.defaultValues[`${priceBookColumn.id}_detail`]?.[0]?.value
            );
            field.value = !Utility.isEmptyObject(updatedPB?.id)
              ? [updatedPB?.id]
              : null;
          }
        }

        if (field.type === INPUT_TYPE_TEXT_AREA) {
          let textRequired = field.required
            ? field.required
            : field?.requiredByUser ?? false;
          let className = textRequired ? "mb-xl" : "";
          field.type = INPUT_TYPE.LONG_TEXT;
          field.className = className;
          field.validator = (value) => {
            return (
              !Utility.isEmptyObject(value) &&
              value?.length <= TEXT_AREA_DEFAULT_LENGTH
            );
          };
          field.errorMessage = this.getErrorMessage(field.name, textRequired);
        }
      });

      this.setState({
        formFields: filteredColumns,
        numberingFormat: updatedValues
      });
    });
  };

  getErrorMessage = (fieldName: string, isRequired: boolean) => {
    if (isRequired) {
      return `${fieldName} is required and cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
    } else {
      return `${fieldName} cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
    }
  };

  dealFormSequence = (columns) => {
    let item_order = [
      COLUMN_CODE.DEAL.NOTE,
      COLUMN_CODE.DEAL.STAGE_ID,
      COLUMN_CODE.DEAL.PIPELINE_ID,
      COLUMN_CODE.DEAL.CLOSING_DATE,
      COLUMN_CODE.DEAL.DEAL_DATE,
      COLUMN_CODE.DEAL.CURRENCY_CODE,
      COLUMN_CODE.DEAL.AMOUNT,
      COLUMN_CODE.DEAL.OWNER_ID,
      COLUMN_CODE.DEAL.CONTACT_ID,
      COLUMN_CODE.DEAL.NAME
    ];
    columns.sort(
      (a, b) =>
        item_order.indexOf(b.columnCode) - item_order.indexOf(a.columnCode)
    );
    return columns;
  };

  /* ******** Submit & close popup handlers *************** */
  removePopUp = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById(this.props.id));
    document.getElementById(this.props.id)?.remove();
  };

  refreshTableData = () => {
    const tableMetaData = store.getState().tableMetaData[this.props.tableName];
    const fetchRecordOptions: any = {
      tableName: this.props.tableName,
      params: {
        q: tableMetaData?.searchQuery ?? DEFAULT_SEARCH_QUERY,
        pageNo: tableMetaData?.page ?? DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE
      }
    };

    let tableFilter = tableMetaData?.filter;
    if (!Utility.isEmptyObject(tableFilter?.conditions)) {
      let filterPayload = TableManger.getFilterObject(
        tableFilter?.conditions,
        tableFilter?.logicalOperator
      );
      fetchRecordOptions.payload = TableDataParser.getModifiedFilterPayload(
        filterPayload,
        TableManger.getTableColumns(this.props.tableName)
      );
    }
    store.dispatch(fetchRecordsByTable(fetchRecordOptions));
  };

  onSave = (data: { [key: string]: any }) => {
    const recId = getRandomHexString();
    const tableId = TableManger.getTableId(this.props.tableName);
    const dataToSend = { ...data };
    const selectedFormat: any = {};
    selectedFormat.value = this.state.autoNumberingFormat?.id;
    if (this.state.autoNumberingFormat.manualMode) {
      selectedFormat.defaultValue = this.state.autoNumberingFormat.text;
    } else {
      selectedFormat.defaultValue = undefined;
    }
    const sequenceFormat = selectedFormat.value?.toString();
    const documentSequenceCode = selectedFormat.defaultValue?.toString();
    const docSequenceCodeColumnId = TableManger.getColumnId(
      this.props.tableName === TABLES.DEAL ? TABLES.DEAL : TABLES.CONTACT,
      COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE
    );
    const sequenceFormatColumnId = TableManger.getColumnId(
      this.props.tableName === TABLES.DEAL ? TABLES.DEAL : TABLES.CONTACT,
      COLUMN_CODE.CONTACT.SEQUENCE_FORMAT
    );

    if (!Utility.isEmptyObject(documentSequenceCode))
      dataToSend[docSequenceCodeColumnId] = documentSequenceCode;
    if (!Utility.isEmptyObject(sequenceFormat))
      dataToSend[sequenceFormatColumnId] = sequenceFormat;
    if (this.props?.recordId === null) {
      if (!this.state.autoNumberingFormat?.manualMode) {
        delete dataToSend[docSequenceCodeColumnId];
      } else {
        delete dataToSend[sequenceFormatColumnId];
      }
    }

    if (this.isBulkUpdate) {
      this.props.onSave?.(dataToSend);
      this.removePopUp();
      return;
    }

    if (this.isUpdated) {
      store.dispatch(
        updateRecord({
          tableName: this.props.tableName,
          recId: this.props.recordId,
          record: dataToSend
        })
      );
    } else {
      store.dispatch(
        addRecord({
          tableName: this.props.tableName,
          record: { _id: recId, cells: dataToSend }
        })
      );
    }
    this.removePopUp();
    (!this.isUpdated
      ? Table.addRecord({ _id: recId, cells: dataToSend }, tableId)
      : Table.updateRecord(dataToSend, this.props.recordId, tableId)
    ).then(
      (res) => {
        if (this.props.tableName !== TABLES.DEAL) {
          this.refreshTableData();
        }
        if (this.props.onSave)
          this.props.onSave({
            _id: this.isUpdated ? this.props.recordId : recId,
            cells: dataToSend
          });
      },
      (err) => {
        let needToast = true;

        try {
          const hasErrorDetails =
            (err.errorCode || err.code) && err.errorMessage;
          needToast = !hasErrorDetails;
        } catch (err) {}

        needToast &&
          showToast(
            "Some Unknown Error Occurred at Server.Please Try Again!",
            TOAST_TYPE.FAILURE
          );

        !this.isUpdated &&
          store.dispatch(
            deleteRecord({
              tableName: this.props.tableName,
              recIds: [recId]
            })
          );

        if (
          (this.isUpdated || (err.isDuplicate && err.code === 200)) &&
          [TABLES.CONTACT, TABLES.SEGMENT, TABLES.DEAL].includes(
            this.props.tableName
          )
        ) {
          this.refreshTableData();
        }
      }
    );
  };

  getLengthOfPhone = (phoneNo, maxLen = false) => {
    const contactNumberDetails = parsePhoneNumberFromString(phoneNo);
    let length = maxLen ? 18 : 7;
    if (contactNumberDetails) {
      if (contactNumberDetails?.countryCallingCode === "1") {
        length = maxLen ? 17 : 6;
      }
    }
    return length;
  };

  isFormDataValid = () => {
    let valid = true;
    this.state.formFields.forEach((field) => {
      if (
        [
          COLUMN_CODE.CONTACT.SEQUENCE_FORMAT,
          COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE
        ].includes(field.columnCode)
      ) {
        return true;
      }
      if (field.required || field?.requiredByUser) {
        if (
          field.value === null ||
          field.value === undefined ||
          field.value === ""
        ) {
          valid = false;
        } else if (
          typeof field.value === "string" &&
          field.value.trim() === ""
        ) {
          valid = false;
        } else if (field.validator) {
          valid = field.validator(field.value);
        } else if (
          field.type === INPUT_TYPE.EMAIL &&
          !Utility.isValidEmail(field.value)
        ) {
          valid = false;
        } else if (field.type === INPUT_TYPE.NUMBER && isNaN(field.value)) {
          valid = false;
        } else if (
          (field.type === INPUT_TYPE.SELECT ||
            field.type === INPUT_TYPE.MULTI_SELECT) &&
          field.value.length === 0
        ) {
          valid = false;
        }
      }

      if (
        field.type === INPUT_TYPE.EMAIL &&
        field.value !== undefined &&
        field.value !== null &&
        field.value.length > 0 &&
        !Utility.isValidEmail(field.value)
      ) {
        valid = false;
      }

      if (
        field?.type === INPUT_TYPE.PHONE &&
        !Utility.isEmptyObject(field?.value) &&
        (field?.value?.length < this.getLengthOfPhone(field?.value) ||
          field?.value?.length > this.getLengthOfPhone(field?.value, true))
      ) {
        valid = false;
      }
    });

    let textAreaValidation = true;
    let allTextAreaField = this.state.formFields?.filter(
      (field) => field.type === INPUT_TYPE.LONG_TEXT
    );

    if (allTextAreaField?.length > 0) {
      let validate = allTextAreaField?.some(
        (field) => field?.value?.length > TEXT_AREA_DEFAULT_LENGTH
      );
      textAreaValidation = !validate;
    }

    if (!(valid && textAreaValidation)) {
      this.setState({ commonInvalidMsg: true });
    }

    return valid && textAreaValidation;
  };

  submitTapped = () => {
    this.setState({ canValidate: true, commonInvalidMsg: false });
    if (!this.isFormDataValid()) {
      return;
    }

    const dataToSave = DataGridUtility.getRowData(
      this.state.formFields,
      this.columns,
      this.props.defaultValues
    );
    this.onSave(dataToSave);
  };

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
    this.removePopUp();
  };

  /* ************** Field change handlers ***************** */
  onChange = (changedValue: any, index: number) => {
    const allFields = this.state.formFields;
    const changedData = allFields[index];

    if ([INPUT_TYPE.LONG_TEXT, INPUT_TYPE.TEXT].includes(changedData.type)) {
      changedData.value = sanitizeForExcel(changedValue);
    } else {
      changedData.value = changedValue;
    }

    if (changedData?.type === INPUT_TYPE.DATE) {
      try {
        allFields?.forEach((field) => {
          if (field.id === changedData.id) {
            field.value = DateUtil.getDateWithCurrentTime(changedData.value);
          }
        });
      } catch (error) {}
    }
    if (
      this.props.tableName === TABLES.DEAL &&
      changedData.columnCode === COLUMN_CODE.DEAL.PIPELINE_ID
    ) {
      this.onPipelineChange(changedData);
    } else if (
      this.props.tableName === TABLES.DEAL &&
      changedData.columnCode === COLUMN_CODE.DEAL.STAGE_ID
    ) {
      if (!this.onStageChange(changedData)) {
        this.buildForm();
      }
    } else if (
      this.props.tableName === TABLES.PRODUCT &&
      changedData.columnCode === COLUMN_CODE.PRODUCT.PRICE
    ) {
      this.onPriceChange(changedData);
    } else if (
      this.props.tableName === TABLES.DEAL &&
      changedData.columnCode === COLUMN_CODE.DEAL.CONTACT_ID
    ) {
      this.onLinkedValueChange();
    } else if (
      this.props.tableName === TABLES.DEAL &&
      changedData.columnCode === COLUMN_CODE.DEAL.ACCOUNT_ID
    ) {
      this.onLinkedValueChange();
    } else if (
      [TABLES.CONTACT, TABLES.SEGMENT].includes(this.props.tableName) &&
      changedData.columnCode === COLUMN_CODE.CONTACT.ACCOUNT
    ) {
      this.onLinkedValueChange();
    } else if (
      [TABLES.CONTACT, TABLES.SEGMENT].includes(this.props.tableName) &&
      changedData.columnCode === COLUMN_CODE.CONTACT.PRICE_LIST
    ) {
      this.onPriceBookValueChange();
    } else if (
      changedData.columnCode === COLUMN_CODE.CONTACT.DETAILED_ADDRESS
    ) {
      const newAddressList = changedData.value || [];
      allFields?.forEach((field) => {
        if (field.id === changedData.id) {
          field.value = newAddressList;
        }
        // commented for this ticket ZEN-11242
        // if (field.columnCode === COLUMN_CODE.CONTACT.ADDRESS) {
        //   field.value = newAddressList.find(address => address.preferred)?.full || newAddressList[0]?.full || "";
        // }
      });
      this.updateDefaultValues(allFields);
      this.setState({ formFields: allFields });
    } else {
      this.updateDefaultValues(allFields);
    }
  };

  onLinkedValueChange() {
    let fields = [...this.state.formFields];
    fields.forEach((field) => {
      if (
        [COLUMN_CODE.DEAL.CONTACT_ID, COLUMN_CODE.CONTACT.ACCOUNT].includes(
          field.columnCode
        )
      ) {
        field.value = field.value?.id ? [field.value.id] : field.value ?? null;
      }
    });
    this.updateDefaultValues(fields);
    this.setState({ formFields: fields });
  }
  onPriceBookValueChange() {
    let fields = [...this.state.formFields];
    fields.forEach((field) => {
      if ([COLUMN_CODE.CONTACT.PRICE_LIST].includes(field.columnCode)) {
        field.value = field.value?.id ? [field.value.id] : field.value ?? null;
      }
    });
    this.updateDefaultValues(fields);
    this.setState({ formFields: fields });
  }

  onPipelineChange(changedData) {
    PipelineManager.get().then((data) => {
      let pipelines = PipelineManager.processPipelineData();
      let currentPipeline = changedData.value.length
        ? pipelines[changedData.value[0]]
        : null;
      if (currentPipeline) {
        let fields = [...this.state.formFields];
        fields.forEach((field) => {
          if (field.columnCode === COLUMN_CODE.DEAL.STAGE_ID) {
            field.options = currentPipeline.stages.map((stage) => stage.name);
            field.value = [0];
          }
        });
        this.columns.forEach((column) => {
          if (column.columnCode === COLUMN_CODE.DEAL.STAGE_ID) {
            column.options = currentPipeline.stages;
            column.value = [0];
          }
        });
        this.updateDefaultValues(fields);
        this.setState({ formFields: fields });
      }
    });
  }

  onStageChange(changedData) {
    let formFields = DataGridUtility.getFormElements(
      this.columns,
      this.defaultValues
    );

    const targetStageIndex = changedData?.value?.[0];
    const sourceStageIndex = formFields?.find(
      (field) => field.columnCode === COLUMN_CODE.DEAL.STAGE_ID
    )?.value?.[0];
    const pipeline = this.state.formFields?.find(
      (field) => field.columnCode === COLUMN_CODE.DEAL.PIPELINE_ID
    )?.value;
    const stage = formFields?.find(
      (field) => field.columnCode === COLUMN_CODE.DEAL.STAGE_ID
    )?.value;

    if (targetStageIndex < sourceStageIndex) {
      const pipelines = PipelineManager.processPipelineData();
      const isApprovalFlowPresent =
        DealApprovalHelper.isDealStageApprovalFlowsPresent();
      const isApprovalRequired = DealApprovalHelper.isDealApprovalRequired({
        pipeline: [pipelines[pipeline]?.id],
        stage: [pipelines?.[pipeline]?.stages?.[stage?.[0]]?.id]
      });
      if (isApprovalFlowPresent && isApprovalRequired) {
        const data = DataGridUtility.getRowData(
          this.state.formFields,
          this.columns,
          this.props.defaultValues
        );
        getDealApprovalAlert(() => {
          const dataToSendForApproval = buildPayloadForDealApproval(
            { id: this.props.recordId },
            data,
            pipelines[pipeline]?.stages[sourceStageIndex]?.id
          );
          if (!Utility.isEmptyObject(dataToSendForApproval?.recordId)) {
            DealService.TriggerDealStageChangeApproval(
              dataToSendForApproval
            ).then((response) => {
              showApprovalTriggerSuccessAlert();
            });
          } else {
            showApprovalTriggerFailedAlert();
          }
        });
        return false;
      }
    }
    return true;
  }

  onPriceChange(changedData) {
    if (
      /^\d+(?:\.(\d{1,2})?)?$/.test(changedData.value) ||
      !changedData.value
    ) {
      this.defaultValues[changedData.id] = changedData.value;
    } else {
      /* revert back to previous value if price not within 2 decimal places */
      const newFormFields = Utility.makeCopyOfObject(this.state.formFields);
      const priceField = newFormFields.find(
        (field) => field.columnCode === COLUMN_CODE.PRODUCT.PRICE
      );
      priceField.value = this.defaultValues[changedData.id];
      this.setState({ formFields: newFormFields });
    }
  }

  updateDefaultValues(fields) {
    fields.forEach((field) => {
      this.defaultValues[field.id] = field.value;
    });
  }
  selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    const numberingFormat: any = {};
    numberingFormat.value = selected.id;
    if (selected.manualMode) {
      numberingFormat.defaultValue = selected.text;
    } else {
      numberingFormat.defaultValue = undefined;
    }
    this.setState({ autoNumberingFormat: { ...selected }, numberingFormat });
  };
  scrollToTop = () => {
    document.getElementById("dk-form-popup-window")?.scrollTo({ top: 0 });
  };

  /* ******** Additional fields ************* */
  putAdditionalFields = () => {
    switch (this.props.tableName) {
      case TABLES.CONTACT:
      case TABLES.SEGMENT:
        this.updateAccountColumnForContact();
        this.updatePriceBookColumnForContact();
        return Promise.resolve();
      case TABLES.DEAL:
        return this.addPipelineAndStage();
      default:
        return Promise.resolve();
    }
  };
  updateAccountColumnForContact = () => {
    const accountColumn = this.columns.find(
      (column) => column.columnCode === COLUMN_CODE.CONTACT.ACCOUNT
    );
    const accountColumnNameId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.NAME
    );

    if (Utility.isEmptyObject(accountColumn)) return;

    this.cachedAccountForDisplay = {
      name:
        this.defaultValues[`${accountColumn.id}_detail`]?.[0]?.cells?.[
          accountColumnNameId
        ] ||
        this.defaultValues[`${accountColumn.id}_detail`]?.[0]?.name ||
        ""
    };

    accountColumn.dropdownConfig = {
      ...TableDataParser.getLinkedRecordColumnDropdownConfig(
        TABLES.CONTACT,
        TABLES.ACCOUNT
      ),
      onSelect: (index: number, selectedAccount: any) => {
        this.cachedAccountForDisplay = selectedAccount;
      }
    };
    accountColumn.renderer = null;
    accountColumn.formatter = () => {
      return this.cachedAccountForDisplay?.name || "";
    };
  };
  updatePriceBookColumnForContact = () => {
    const priceBookColumn = this.columns.find(
      (column) => column.columnCode === COLUMN_CODE.CONTACT.PRICE_LIST
    );

    if (Utility.isEmptyObject(priceBookColumn)) return;

    this.cachedPriceBookForDisplay = {
      name: this.defaultValues[`${priceBookColumn.id}_detail`]?.[0]?.value || ""
    };
    priceBookColumn.dropdownConfig = {
      ...TableDataParser.getPriceBookColumnDropdownConfig(priceBookColumn),
      onSelect: (index: number, selectedPriceB: any) => {
        this.cachedPriceBookForDisplay = selectedPriceB;
      }
    };
    priceBookColumn.renderer = null;
    priceBookColumn.formatter = () => {
      return this.cachedPriceBookForDisplay?.name || "";
    };
  };
  getContactsForDealPopup = async (contactList: any[]) => {
    const contactColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.CONTACT_ID
    );
    if (
      this.defaultValues[contactColumnId]?.length &&
      this.defaultValues[contactColumnId][0] &&
      contactList.findIndex(
        (contactData) =>
          contactData._id === this.defaultValues[contactColumnId][0]
      ) === -1
    ) {
      if (this.defaultValues[contactColumnId + "_detail"]?.length > 0) {
        return Promise.resolve(
          DealManager.contactData([
            ...contactList,
            ...this.defaultValues[contactColumnId + "_detail"]
          ])
        );
      } else {
        return Table.getRecordById(
          this.defaultValues[contactColumnId][0],
          TableManger.getTableId(TABLES.CONTACT)
        ).then(
          (res: any) =>
            Promise.resolve(
              DealManager.contactData(
                res?.cells ? [...contactList, res] : [...contactList]
              )
            ),
          (error: any) => {
            delete this.defaultValues[contactColumnId];
            delete this.defaultValues[contactColumnId + "_detail"];
            return Promise.resolve(contactList);
          }
        );
      }
    } else {
      return Promise.resolve(DealManager.contactData(contactList));
    }
  };
  addPipelineAndStage = () => {
    let pipelines = [];
    let contacts = [];
    let accounts = store.getState().records.accounts?.data ?? [];
    accounts = AccountService.parseAccountPayload(accounts || []);
    return new Promise((resolve, reject) => {
      resolve(store.getState().records.data[TABLES.PIPELINE]);
    }).then((records: any) => {
      pipelines = DealManager.parsePipelineData(records);
      return new Promise((resolve, reject) => {
        resolve(store.getState().records.data[TABLES.STAGE]);
      }).then((res: any) => {
        DealManager.putStagesInPipeline(pipelines, res);
        return new Promise((resolve, reject) => {
          resolve(store.getState().records.data[TABLES.CONTACT]);
        })
          .then(
            (contact: any) => this.getContactsForDealPopup(contact.data),
            (error) => []
          )
          .then((newContactList) => {
            contacts = newContactList;
            const pipelineColumn = TableManger.getColumn(
              TABLES.DEAL,
              COLUMN_CODE.DEAL.PIPELINE_ID
            );
            const stageColumn = TableManger.getColumn(
              TABLES.DEAL,
              COLUMN_CODE.DEAL.STAGE_ID
            );
            const contactColumn = TableManger.getColumn(
              TABLES.DEAL,
              COLUMN_CODE.DEAL.CONTACT_ID
            );
            const accountColumn = TableManger.getColumn(
              TABLES.DEAL,
              COLUMN_CODE.DEAL.ACCOUNT_ID
            );

            this.columns.forEach((column) => {
              if (column.columnCode === "amount") {
                column.name = `${
                  column.name
                } (${TenantManager.getCRMCurrencySymbol()})`;
              }
              if (column.columnCode === "note") {
                column.type = INPUT_TYPE.LONG_TEXT;
              }
            });

            if (pipelines && pipelines.length > 0) {
              const allColumns = TableManger.getTableColumns(TABLES.DEAL);
              let accountIndex = this.columns.findIndex(
                (column) => column.columnCode === COLUMN_CODE.DEAL.ACCOUNT_ID
              );
              let accountDetails =
                this.defaultValues[accountColumn.id + "_detail"] &&
                AccountService.parseAccountPayload(
                  this.defaultValues[accountColumn.id + "_detail"]
                );
              if (
                accountIndex !== -1 &&
                this.columns[accountIndex].columnCode ===
                  COLUMN_CODE.DEAL.ACCOUNT_ID
              ) {
                this.columns[accountIndex] = {
                  ...this.columns[accountIndex],
                  renderer: null,
                  formatter: (obj) => {
                    if (Array.isArray(obj)) {
                      let value =
                        accounts?.find(
                          (account) =>
                            account.id ===
                            this.defaultValues[accountColumn.id][0]
                        )?.name ?? accountDetails?.[0]?.name;
                      return value;
                    }
                  },
                  dropdownConfig: {
                    ...accountColumn.dropdownConfig,
                    onSelect: (index, obj, rowIndex) => {
                      if (
                        accounts.findIndex(
                          (account) => account.id === obj.id
                        ) === -1
                      ) {
                        accounts.push(obj);
                      }
                    }
                  }
                };
              }

              this.columns.push({
                ...contactColumn,
                renderer: null,
                formatter: (obj) => {
                  if (Array.isArray(obj)) {
                    let value = contacts.find(
                      (contact) =>
                        contact.id === this.defaultValues[contactColumn.id][0]
                    )?.name;
                    return value;
                  }
                },
                dropdownConfig: {
                  ...contactColumn.dropdownConfig,
                  onSelect: (index, obj, rowIndex) => {
                    if (
                      contacts.findIndex((contact) => contact.id === obj.id) ===
                      -1
                    ) {
                      contacts.push(obj);
                    }
                  }
                }
              });
              this.columns.push({
                id: pipelineColumn.id,
                key: pipelineColumn.id,
                columnCode: pipelineColumn.columnCode,
                name: "Pipeline",
                required: true,
                type: INPUT_TYPE.SELECT,
                value: [0],
                options: pipelines
              });
              this.columns.push({
                id: stageColumn.id,
                key: stageColumn.id,
                columnCode: stageColumn.columnCode,
                name: "Stage",
                required: true,
                type: INPUT_TYPE.SELECT,
                value: [0],
                options:
                  pipelines[this.defaultValues[pipelineColumn.id][0]].stages
              });
              let sortedColumns = [];
              allColumns?.forEach((column) => {
                this.columns?.map((col) => {
                  if (column.id === col.id) sortedColumns.push(col);
                });
              });
              this.columns = sortedColumns;
            }
            return Promise.resolve();
          });
      });
    });
  };
  onCustomFieldAdd = () => {
    showAddColumnPopup({ tableName: this.props.tableName }, () => {
      this.buildForm();
    });
  };

  updateExchangeRate = (exchangeRate, currencyCode) => {
    let fields = [...this.state.formFields];
    let dealExchangeRateIndex = fields.findIndex(
      (col) => col.columnCode === COLUMN_CODE.DEAL.EXCHANGE_RATE
    );
    fields[dealExchangeRateIndex].value = exchangeRate;
    let defaultDealAmountIndex = this.preserveDefaultValues.findIndex(
      (col) => col.columnCode === COLUMN_CODE.DEAL.AMOUNT
    );
    let dealAmountIndex = fields.findIndex(
      (col) => col.columnCode === COLUMN_CODE.DEAL.AMOUNT
    );
    fields[dealAmountIndex].title = `${
      this.preserveDefaultValues[defaultDealAmountIndex].name
    } (${getCurrencySymbolFromCode(currencyCode)})`;
    this.updateDefaultValues(fields);
    this.setState({ formFields: fields });
  };

  /* ************** RENDERERS ***************** */
  getCurrencyField = (field, index) => {
    return (
      <DKInput
        className={"mt-m mobile-mt-s mb-m"}
        value={
          this.allCurrencies?.filter(
            (currency) => currency.currencyCode === field.value
          )?.[0] ?? {}
        }
        displayKey="label"
        title={field.name}
        direction={
          this.isBulkUpdate
            ? INPUT_VIEW_DIRECTION.VERTICAL
            : INPUT_VIEW_DIRECTION.HORIZONTAL
        }
        type={INPUT_TYPE.DROPDOWN}
        required={
          field.required ? field.required : field?.requiredByUser ?? false
        }
        readOnly={field?.readOnly || false}
        canValidate={false}
        onChange={(value) => {
          this.onChange(value.currencyCode, index);
          //save exchange rate
          this.updateExchangeRate(
            +roundingOffStr(value.currencyExchangeRate, CURRENCY_PRECISION),
            value.currencyCode
          );
        }}
        renderer={(obj) => {
          return (
            <div className="row justify-content-between">
              <div className="row currency-select-trigger">
                <div className="currency-dropdown-flag mr-s">
                  <span
                    className={`currency-dropdown-selector-flag flag ${obj.currencyCode}`}
                  ></span>
                </div>
                <DKLabel text={`${obj.currencyName}`} />
              </div>
              <DKLabel className="text-gray" text={`${obj.currencyCode}`} />
            </div>
          );
        }}
        dropdownConfig={{
          data:
            this.allCurrencies?.filter(
              (currency) => currency.currencyStatus === "ACTIVE"
            ) ?? [],
          multiSelect: false,
          allowSearch: true,
          searchableKey: "currencyName",
          selectedIndexes: [],
          renderer: (index, obj) => {
            return (
              <div className="row justify-content-between">
                <div className="row currency-select-trigger">
                  <div className="currency-dropdown-flag mr-s">
                    <span
                      className={`currency-dropdown-selector-flag flag ${obj.currencyCode}`}
                    ></span>
                  </div>
                  <DKLabel text={`${obj.currencyName}`} />
                </div>
                <DKLabel className="text-gray" text={`${obj.currencyCode}`} />
              </div>
            );
          },
          onClear: () => {},
          onSelect: (index, value) => {}
        }}
      />
    );
  };
  getOptionName = (option, isDate, isAmount, isNumber) => {
    let optionName = option;
    if (!Utility.isEmptyObject(optionName)) {
      if (isDate) {
        optionName = DateUtil.getDateStrFromDate(
          new Date(option),
          DateUtil.getOrgDateFormat()
        );
      }
      if (isAmount || isNumber) {
        optionName = parseFloat(option)?.toFixed(2);
      }
    }
    return optionName;
  };

  lookupConfigInput = (field, index) => {
    const sourceTableColumns = TableManger.getTableColumns(
      field?.lookup?.objectType.toLowerCase()
    );
    const contactNumberColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE
    );
    let isDateType = false;
    let isAmountType = false;
    let isNumberType = false;
    sourceTableColumns.forEach((column: any) => {
      if ((column.key || column.id) === field?.lookup?.sourceColumn) {
        if (column.type === INPUT_TYPE.DATE) {
          isDateType = true;
        }
        if (column.type === INPUT_TYPE.NUMBER) {
          isAmountType = true;
        } else if (
          column.type === INPUT_TYPE.NUMBER &&
          (column.id || column.columnCode) === contactNumberColumnId
        ) {
          isNumberType = true;
        }
      }
    });

    return (
      <DKInput
        className={"mt-m mobile-mt-s mb-m"}
        value={field.value}
        displayKey="label"
        title={field.name}
        direction={
          this.isBulkUpdate
            ? INPUT_VIEW_DIRECTION.VERTICAL
            : INPUT_VIEW_DIRECTION.HORIZONTAL
        }
        type={INPUT_TYPE.DROPDOWN}
        required={
          field.required ? field.required : field?.requiredByUser ?? false
        }
        readOnly={field?.readOnly || false}
        canValidate={false}
        onChange={(value) => {
          this.onChange(value, index);
        }}
        renderer={(obj) => (obj ? obj?.name || obj.value : "")}
        dropdownConfig={{
          title: `Select ${field.name}`,
          allowSearch:
            isDateType || isAmountType || isNumberType ? false : true,
          allowRemoveSelection: false,
          searchableKey: "name",
          style: { minWidth: 230 },
          className: "z-index-1 shadow-m",
          searchApiConfig: {
            method: "POST",
            getUrl: (val) => {
              return (
                ApiConstants.URL.BASE +
                ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                  field.lookup.sourceTableId
                ) +
                "?pageNo=1&pageSize=20&q=" +
                val
              );
            },
            getPayload: (search) => {
              return field?.lookup?.filter;
            },
            dataParser: (response) => {
              return (
                response?.data?.map((record) => ({
                  id: record._id,
                  name: this.getOptionName(
                    record.cells[field.lookup?.sourceColumn],
                    isDateType,
                    isAmountType,
                    isNumberType
                  )
                })) || []
              )?.filter(
                (option) => option?.name && !Utility.isEmptyObject(option?.name)
              );
            }
          },
          data: [],
          renderer: (index, obj) => {
            return (
              <div className="column parent-width">
                <DKLabel text={`${obj.name}`} />
              </div>
            );
          },
          onSelect: (index, obj, rowIndex) => {}
        }}
      />
    );
  };

  renderHeader() {
    return (
      <div className="column parent-width">
        <div className="row justify-content-between mb-s">
          <DKLabel
            text={`${this.isUpdated || this.isBulkUpdate ? "Edit" : "Add"} ${
              this.props.tableName === TABLES.SEGMENT
                ? TABLES.CONTACT
                : this.props.tableName
            }`}
            className="fw-m fs-l"
            style={{ whiteSpace: "nowrap" }}
          />
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m"
              onClick={() => this.onClose()}
            />
            <DKButton
              title="Save"
              className="dk-bg-button text-white ml-r"
              onClick={() => this.submitTapped()}
            />
          </div>
        </div>
        {this.state.commonInvalidMsg && (
          <div className="row">
            <DKLabel
              text={ERROR_MESSAGE_FOR_FORM}
              className="fs-s m-v-s bg-chip-red text-red border-red p-v-xs p-h-s border-radius-m fw-m text-wrap-none "
            />
          </div>
        )}
      </div>
    );
  }
  checkValueIsInvalid = (fieldData) => {
    if (!fieldData.requiredByUser) return false;
    if (
      fieldData.requiredByUser &&
      this.state.canValidate &&
      fieldData.value?.length === 0
    ) {
      return true;
    }
    if (
      fieldData.requiredByUser &&
      this.state.canValidate &&
      Utility.isEmptyObject(fieldData.value)
    ) {
      return true;
    }
    return false;
  };
  getDefaultSeqCode = () => {
    return this.props.tableName === TABLES.CONTACT
      ? this.contactSeqCode
      : this.dealSeqCode;
  };

  render() {
    const addressField = this.state.formFields?.find(
      (field) => field.columnCode === COLUMN_CODE.CONTACT.ADDRESS
    );
    return (
      <Provider store={store}>
        <Popup
          popupWindowStyles={{
            height: "auto",
            maxHeight: "90vh",
            justifyContent: "flex-start"
          }}
          popupBodyClassNames="dynamic-form-template"
        >
          {this.state.formFields?.length !== 0 && (
            <div
              className={`column parent-width ${
                this.isBulkUpdate ? "bulk-record-update-popup" : ""
              }`}
            >
              {this.renderHeader()}
              {this.state.formFields.map((field, index) => {
                if (!Utility.isEmptyObject(field.lookup)) {
                  return this.lookupConfigInput(field, index);
                }
                switch (field.columnCode) {
                  case COLUMN_CODE.CONTACT.DETAILED_ADDRESS:
                    return (
                      <DetailAddressInputField
                        recordId={this.props.recordId}
                        tableName={this.props.tableName}
                        required={
                          field.required
                            ? field.required
                            : field?.requiredByUser ?? false
                        }
                        errorMsg={true}
                        invalid={this.checkValueIsInvalid(field)}
                        addressList={field.value || []}
                        defaultFullAddress={
                          Utility.isEmptyObject(field.value)
                            ? addressField?.value || ""
                            : ""
                        }
                        needLocationMarker={false}
                        onEdit={(
                          addressIndex: number,
                          addressList: IContactAddress[]
                        ) => this.onChange(addressList, index)}
                        onDelete={(
                          addressIndex: number,
                          addressList: IContactAddress[]
                        ) => this.onChange(addressList, index)}
                        onAdd={(addressList: IContactAddress[]) =>
                          this.onChange(addressList, index)
                        }
                        fieldData={{
                          key: field.id,
                          title: field.name,
                          direction: this.isBulkUpdate
                            ? INPUT_VIEW_DIRECTION.VERTICAL
                            : INPUT_VIEW_DIRECTION.HORIZONTAL,
                          className: "p-v-s mb-r"
                        }}
                      />
                    );
                  case COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE:
                    if (this.isBulkUpdate) return null;
                    return this.isUpdated ||
                      !this.permissions?.[
                        USER_ACTION_TYPES.DOCUMENT_SEQUENCE_CODE_EDITABLE
                      ] ? (
                      <DKInput
                        title={field.name}
                        className={"p-v-s mb-r"}
                        value={[
                          this.state.numberingFormat?.defaultValue ??
                            this.getDefaultSeqCode()
                        ]}
                        readOnly={true}
                      />
                    ) : (
                      <div className="parent-width row flex-wrap justify-content-between align-items-start p-v-s mb-r">
                        <CustomNumberFormatInput
                          module={
                            this.props.tableName === TABLES.CONTACT
                              ? CUSTOM_NUMBER_INPUT_MODULES.CONTACT
                              : CUSTOM_NUMBER_INPUT_MODULES.DEAL
                          }
                          selectedFormat={(selectedData) =>
                            this.selectedFormat(selectedData)
                          }
                          extraClass={"mt-s top-10 right-1"}
                          isRow={true}
                          // autoNumberingFormat={this.state.autoNumberingFormat}
                          onCustomNumberPopupScrollToTop={() =>
                            this.scrollToTop()
                          }
                          label={field.name}
                        />
                      </div>
                    );
                  case COLUMN_CODE.CONTACT.SEQUENCE_FORMAT:
                    return null;

                  case COLUMN_CODE.DEAL.CURRENCY_CODE:
                    return this.isMultiCurrencyEnable
                      ? this.getCurrencyField(field, index)
                      : null;
                  case COLUMN_CODE.DEAL.EXCHANGE_RATE:
                    return null;
                  case COLUMN_CODE.CONTACT.PHONE:
                    return (
                      <PhoneNumberInput
                        accountFormControlKey={field.id}
                        saveClicked={this.state.canValidate}
                        index={index}
                        fieldData={field}
                        direction={
                          this.isBulkUpdate
                            ? INPUT_VIEW_DIRECTION.VERTICAL
                            : INPUT_VIEW_DIRECTION.HORIZONTAL
                        }
                        onFieldChange={(key, value) =>
                          this.onChange(value, index)
                        }
                        style={{ marginBottom: 12 }}
                      />
                    );
                  default:
                    return (
                      <DKInput
                        autoFocus={index === 0}
                        className={`p-v-s mb-r ${field.className ?? ""}`}
                        icon={field.icon}
                        title={field.title}
                        placeholder={field.placeholder}
                        value={field.value}
                        options={field.options}
                        type={field.type}
                        validator={field.validator}
                        canValidate={this.state.canValidate}
                        tooltip={field.tooltip}
                        dropdownConfig={field.dropdownConfig}
                        formatter={field.formatter}
                        errorMessage={field.errorMessage}
                        onChange={(value) => this.onChange(value, index)}
                        direction={
                          this.isBulkUpdate
                            ? INPUT_VIEW_DIRECTION.VERTICAL
                            : INPUT_VIEW_DIRECTION.HORIZONTAL
                        }
                        required={
                          field.required
                            ? field.required
                            : field?.requiredByUser ?? false
                        }
                        dateFormat={convertCRMDateFormatToUILibraryFormat(
                          DateUtil.getOrgDateFormat()
                        )}
                        readOnly={field?.readOnly || false}
                      />
                    );
                }
              })}
              {!!this.permissions?.[USER_ACTION_TYPES.FIELD_CREATE] &&
                this.props.tableName !== TABLES.PRODUCT &&
                !this.isBulkUpdate && (
                  <div className="row row-reverse mt-xs">
                    <DKButton
                      className="bg-gray1 border-m"
                      title={"New Field"}
                      icon={DKIcons.ic_add}
                      onClick={this.onCustomFieldAdd}
                    />
                  </div>
                )}
            </div>
          )}
          {this.state.formFields.length === 0 && (
            <div
              className="row justify-content-center"
              style={{ minHeight: 100 }}
            >
              <DKSpinner title="Loading form fields..." />
            </div>
          )}
        </Popup>
      </Provider>
    );
  }
}

export const showFormPopup = (
  config: {
    tableName: string;
    defaultValues?: any;
    recordId?: string;
    recordIds?: string[];
  },
  onSave?: (data?: { _id: string; cells: any } | any) => void,
  onClose?: () => void
) => {
  const id = `add-new-rec-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <Provider store={store}>
      <AddNewRecordPopUp
        id={id}
        tableName={config.tableName}
        onSave={onSave}
        onClose={onClose}
        defaultValues={config.defaultValues}
        recordId={config.recordId}
        recordIds={config.recordIds}
      />
    </Provider>,
    document.body.appendChild(div)
  );
};
export default AddNewRecordPopUp;
