import React, { useEffect, useState } from "react";
import {
  DKLabel,
  showToast,
  TOAST_TYPE,
  DKCheckMark,
  DKInput,
  INPUT_TYPE,
  DKButton,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import {
  CUSTOM_NUMBER_FORMAT_KEY_NAMES,
  initialCustomDocumentNumberFormValues,
  SEPRATORS
} from "./CustomNumber";
import Utility from "../../../utility/Utility";
import { useAppDispatch } from "../../../redux/hooks";
import {
  APP_NAME,
  CUSTOM_NUMBER_INPUT_MODULES,
  NUMBER_REGEX_PATTERN
} from "../../../constants/Constant";
import CustomNumberFormatService from "../../../services/CustomNumberFormat";

function CustomNumberFormat(props: any) {
  const [formState, setFormState] = useState(
    Utility.deepCloneObject(initialCustomDocumentNumberFormValues)
  );
  const [preview, setPreview] = useState("");
  const [defaultNumber, setDefaultNumber] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [sequenceCode, setSequenceCode] = useState("");

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    // if (props.passingInteraction) {
    //     props.passingInteraction({
    //         type: POPUP_CALLBACKS_TYPE.SAVE_CUSTOM_NUMBER,
    //         data: () => {
    //             saveCustomNumberFormat();
    //         }
    //     });
    // }
  };

  useEffect(() => {
    if (props.data) {
      setInitialFormData(props.data);
    } else {
      setFormState(
        Utility.deepCloneObject(initialCustomDocumentNumberFormValues)
      );
    }
    if (props.isDefaultForSetting) {
      setDefaultNumber(true);
    }
  }, [props.data]);

  const setInitialFormData = (data: any) => {
    const updatedState: any = formState;
    updatedState.displayDigit.value = data.sequenceLength;
    updatedState.prefix.value = data.prefix;
    updatedState.suffix.value = data.suffix;
    updatedState.prefixSeparator.value = SEPRATORS.find(
      (seprator: any) => seprator === data.prefixSeparator
    );
    updatedState.suffixSeparator.value = SEPRATORS.find(
      (seprator: any) => seprator === data.suffixSeparator
    );
    setSequenceCode(data.sequenceCode);
    if (data.isDefault) {
      setDefaultNumber(true);
    } else {
      setDefaultNumber(false);
    }

    setFormState({ ...updatedState });
  };

  useEffect(() => {
    registerInteractions();
    updatePreviewValue();
  });

  const payLoadCustomNumberFormat = () => {
    let PREFIX = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX].value,
      PREFIX_SEPARATOR_VALUE: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value,
      DISPLAY_DIGIT: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT].value,
      SUFFIX_SEPARATOR_VALUE: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value,
      SUFFIX = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX].value,
      payload;

    if (PREFIX_SEPARATOR_VALUE.length >= 1) {
      if (SEPRATORS[PREFIX_SEPARATOR_VALUE[0]] == SEPRATORS[5]) {
        PREFIX_SEPARATOR_VALUE = "Blank";
      } else {
        PREFIX_SEPARATOR_VALUE = SEPRATORS[PREFIX_SEPARATOR_VALUE];
      }
    } else {
      PREFIX_SEPARATOR_VALUE = SEPRATORS[PREFIX_SEPARATOR_VALUE];
    }

    if (SUFFIX_SEPARATOR_VALUE.length >= 1) {
      if (SEPRATORS[SUFFIX_SEPARATOR_VALUE[0]] == SEPRATORS[5]) {
        SUFFIX_SEPARATOR_VALUE = "Blank";
      } else {
        SUFFIX_SEPARATOR_VALUE = SEPRATORS[SUFFIX_SEPARATOR_VALUE];
      }
    } else {
      SUFFIX_SEPARATOR_VALUE = SEPRATORS[SUFFIX_SEPARATOR_VALUE];
    }

    if (
      Utility.isEmpty(PREFIX_SEPARATOR_VALUE) ||
      Utility.isEmpty(SUFFIX_SEPARATOR_VALUE)
    ) {
      if (!PREFIX_SEPARATOR_VALUE) {
        showToast("Please select Separators", TOAST_TYPE.FAILURE);
        return;
      } else if (!SUFFIX_SEPARATOR_VALUE) {
        showToast("Please select Separators", TOAST_TYPE.FAILURE);
        return;
      }
    }

    payload = {
      prefix: !Utility.isEmpty(PREFIX) ? PREFIX : null,
      prefixSeparator:
        !Utility.isEmpty(PREFIX_SEPARATOR_VALUE) && !Utility.isEmpty(PREFIX)
          ? PREFIX_SEPARATOR_VALUE === "Blank"
            ? ""
            : PREFIX_SEPARATOR_VALUE
          : "",
      suffix: !Utility.isEmpty(SUFFIX) ? SUFFIX : null,
      suffixSeparator:
        !Utility.isEmpty(SUFFIX_SEPARATOR_VALUE) && !Utility.isEmpty(SUFFIX)
          ? SUFFIX_SEPARATOR_VALUE === "Blank"
            ? ""
            : SUFFIX_SEPARATOR_VALUE
          : "",
      sequenceLength: parseInt(DISPLAY_DIGIT),
      module: props.documentType,
      application: [
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
        CUSTOM_NUMBER_INPUT_MODULES.DEAL,
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
        CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
      ].includes(props.documentType)
        ? APP_NAME
        : "ERP",
      isDefault: defaultNumber
    };

    if (formState.displayDigit.hasError) {
      showToast("Please provide valid display digit", TOAST_TYPE.FAILURE);
      return;
    } else {
      return payload;
    }
  };

  const saveCustomNumberFormat = () => {
    if (props.data) {
      let payload = {
        sequenceId: props.data.id,
        module: props.documentType,
        application: [
          CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
          CUSTOM_NUMBER_INPUT_MODULES.DEAL,
          CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
          CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
        ].includes(props.documentType)
          ? APP_NAME
          : "ERP"
      };
      if (props.data.isDefault !== defaultNumber) {
        CustomNumberFormatService.toggleIsDefault(payload).then(
          (response: any) => {
            if (response.status == 409) {
              showToast(
                "Custom number format already exist",
                TOAST_TYPE.WARNING
              );
            }

            if (response.status == 200) {
              props?.onSave();
              showToast(
                "Custom number format Created Successfully",
                TOAST_TYPE.SUCCESS
              );
            }
          },
          (err) => {
            console.error("Error while custom number format: ", err);
          }
        );
      }
      closePopup();
    } else {
      if (!Utility.isEmptyObject(payLoadCustomNumberFormat())) {
        CustomNumberFormatService.createCustomNumberFormat(
          payLoadCustomNumberFormat()
        ).then(
          (response: any) => {
            if (response.status == 409) {
              showToast(
                "Custom number format already exist",
                TOAST_TYPE.WARNING
              );
            }

            if (response.status == 200) {
              !props?.successMsg &&
                showToast(
                  "Custom number format Created Successfully",
                  TOAST_TYPE.SUCCESS
                );
              props?.onSave();
            }
          },
          (err) => {
            console.error("Error while custom number format: ", err);
          }
        );
      }
      closePopup();
    }
  };

  const closePopup = () => {
    props.onClose();
  };

  const formFieldChanged = (
    newValue: string | null,
    fieldName: CUSTOM_NUMBER_FORMAT_KEY_NAMES
  ) => {
    const updatedState: any = formState;
    const selectedField = updatedState[fieldName];

    switch (fieldName) {
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT:
        const displayDigit = newValue as string;
        const regex = new RegExp(NUMBER_REGEX_PATTERN);
        if (parseInt(displayDigit) <= 0 || parseInt(displayDigit) > 10) {
          selectedField.hasError = true;
        } else if (!regex.test(displayDigit)) {
          selectedField.hasError = true;
        } else {
          selectedField.hasError = false;
        }
        selectedField.value = newValue ? newValue : "";
        break;
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX:
        selectedField.value = newValue ? newValue : "";
        break;
    }
    setFormState({ ...updatedState });
  };

  const updatePreviewValue = () => {
    let previewValue: string = "";

    let DISPLAY_DIGIT = CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT;
    let PREFIX_VALUE = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX].value;
    let PREFIX_SEPARATOR_VALUE: any = "";
    let SUFFIX_SEPARATOR_VALUE: any = "";

    if (Utility.isEmpty(props.data)) {
      // Prefix Seprator value preview
      PREFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value;
      PREFIX_SEPARATOR_VALUE =
        SEPRATORS[
          PREFIX_SEPARATOR_VALUE.length > 1
            ? PREFIX_SEPARATOR_VALUE[0]
            : PREFIX_SEPARATOR_VALUE
        ];
      if (PREFIX_SEPARATOR_VALUE === SEPRATORS[5]) {
        PREFIX_SEPARATOR_VALUE = "";
      }
      // Suffix Seprator value preview
      SUFFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value;

      SUFFIX_SEPARATOR_VALUE =
        SEPRATORS[
          SUFFIX_SEPARATOR_VALUE.length > 1
            ? SUFFIX_SEPARATOR_VALUE[0]
            : SUFFIX_SEPARATOR_VALUE
        ];
      if (SUFFIX_SEPARATOR_VALUE === SEPRATORS[5]) {
        SUFFIX_SEPARATOR_VALUE = "";
      }
    } else {
      PREFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value;

      SUFFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value;
    }

    let DISPLAY_DIGIT_VALUE = Utility.padLeadingZeros(
      "1",
      formState[DISPLAY_DIGIT].value?.toString()
    );

    let SUFFIX_VALUE = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX].value;

    if (
      formState[DISPLAY_DIGIT].value === "1" &&
      !PREFIX_VALUE &&
      !SUFFIX_VALUE
    ) {
      previewValue = "1";
    }

    if (Utility.isEmpty(PREFIX_SEPARATOR_VALUE)) {
      PREFIX_SEPARATOR_VALUE = "";
    }

    if (Utility.isEmpty(SUFFIX_SEPARATOR_VALUE)) {
      SUFFIX_SEPARATOR_VALUE = "";
    }

    if (formState[DISPLAY_DIGIT].value > "0") {
      if (PREFIX_VALUE && SUFFIX_VALUE) {
        previewValue =
          `${PREFIX_VALUE}` +
          `${PREFIX_SEPARATOR_VALUE}` +
          `${DISPLAY_DIGIT_VALUE}` +
          `${SUFFIX_SEPARATOR_VALUE}` +
          `${SUFFIX_VALUE}`;
      } else if (!PREFIX_VALUE && SUFFIX_VALUE) {
        previewValue =
          `${DISPLAY_DIGIT_VALUE}` +
          `${SUFFIX_SEPARATOR_VALUE}` +
          `${SUFFIX_VALUE}`;
      } else if (PREFIX_VALUE && !SUFFIX_VALUE) {
        previewValue =
          `${PREFIX_VALUE}` +
          `${PREFIX_SEPARATOR_VALUE}` +
          `${DISPLAY_DIGIT_VALUE}`;
      } else if (!PREFIX_VALUE && !SUFFIX_VALUE) {
        previewValue = `${DISPLAY_DIGIT_VALUE}`;
      }

      setPreview(previewValue);
      formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREVIEW].value = previewValue;
    } else {
      setPreview("");
      formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREVIEW].value = "";
    }
  };

  const onClickCheckBoxForSetting = () => {
    setDefaultNumber((prevCheck) => !prevCheck);
  };

  return (
    <>
      <div className="row">
        <div className="row fw-h">{"Custom Number Format"}</div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={() => props?.onClose()}
          />
          <DKButton
            title="Save"
            className="bg-app text-white border-m"
            onClick={() => saveCustomNumberFormat()}
          />
        </div>
      </div>
      <div className="parent-width row flex-wrap pt-s">
        <div className="flex-1">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Preview" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={sequenceCode}
              />
            </div>
          ) : (
            <DKInput
              title="Preview"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              isDisabled={true}
              readOnly={true}
              hasError={formState.preview.hasError}
              value={preview}
            />
          )}
        </div>
      </div>
      <div
        className={`${props.data ? `mt-m` : `pt-s`} parent-width row flex-wrap`}
        style={{ gap: "2%" }}
      >
        <div className="flex-1">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Prefix" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.prefix.value
                    ? formState.prefix.value?.toString()
                    : ""
                }
              />
            </div>
          ) : (
            <DKInput
              title="Prefix"
              required={false}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.prefix.value?.toString()}
              onChange={(text: any) =>
                formFieldChanged(text, CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX)
              }
            />
          )}
        </div>
        <div className="flex-1 p-h-xs">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Separator" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.prefixSeparator.value
                    ? formState.prefixSeparator.value.toString()
                    : ""
                }
              />
            </div>
          ) : (
            <>
              <DKInput
                required={false}
                title={"Separator"}
                value={
                  formState.prefixSeparator.value >= 0
                    ? [formState.prefixSeparator.value]
                    : []
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                options={SEPRATORS}
                onChange={(value: any) =>
                  formFieldChanged(
                    value,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR
                  )
                }
                type={INPUT_TYPE.SELECT}
              />
            </>
          )}
        </div>
        <div className="flex-1">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel
                className="ml-0"
                text="Display Digit"
                style={{ whiteSpace: "nowrap" }}
              />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.displayDigit.value
                    ? formState.displayDigit.value?.toString()
                    : ""
                }
              />
            </div>
          ) : (
            <DKInput
              required={true}
              title="Display Digit"
              titleStyle={{ whiteSpace: "nowrap" }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.displayDigit.value?.toString()}
              onChange={(text: any) => {
                if (Number(text) > 0) {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT
                  );
                } else {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT
                  );
                  showToast(
                    "Display digits cannot be zero",
                    TOAST_TYPE.FAILURE
                  );
                }
              }}
              errorMessage={"Invalid Display Digit (1 - 10)"}
            />
          )}
        </div>
        <div className="flex-1 p-h-xs">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Separator" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.suffixSeparator.value
                    ? formState.suffixSeparator.value.toString()
                    : ""
                }
              />
            </div>
          ) : (
            <>
              <DKInput
                required={false}
                title={"Separator"}
                value={
                  formState.suffixSeparator.value >= 0
                    ? [formState.suffixSeparator.value]
                    : []
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                options={SEPRATORS}
                onChange={(value: any) =>
                  formFieldChanged(
                    value,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR
                  )
                }
                type={INPUT_TYPE.SELECT}
              />
            </>
          )}
        </div>
        <div className="flex-1">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Suffix" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.suffix.value
                    ? formState.suffix.value?.toString()
                    : ""
                }
              />
            </div>
          ) : (
            <DKInput
              required={false}
              title="Suffix"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.suffix.value?.toString()}
              onChange={(text: any) =>
                formFieldChanged(text, CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX)
              }
            />
          )}
        </div>
      </div>
      <div
        className={`${props.data ? `mt-m` : `pt-s`} parent-width row flex-wrap`}
      >
        {!props?.isDefaultForSetting && (
          <div className="flex-1 mt-s d-flex items-center">
            <DKCheckMark
              title="Set as Default"
              isSelected={defaultNumber}
              className="text-black"
              disabled={props.data?.isDefault ? true : false}
              onClick={onClickCheckBoxForSetting}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CustomNumberFormat;
