import ApiConstants from "../constants/ApiConstants";
import { APP_NAME, CUSTOM_NUMBER_INPUT_MODULES } from "../constants/Constant";
import httpClient from "../http/index";

export interface CustomNumberFormatAPIConfig {
  SearchTerm?: string;
  pageSize?: number;
  pageNo?: number;
}

const defaultConfig: CustomNumberFormatAPIConfig = {
  SearchTerm: "",
  pageSize: 10,
  pageNo: 1
};

class CustomNumberFormatService {
  static apiConfig: CustomNumberFormatAPIConfig;

  static createCustomNumberFormat(payload: any) {
    return httpClient
      .post(`${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.SAVE}`, payload)
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static toggleIsDefault(payload: any) {
    return httpClient
      .put(
        `${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.TOGGLE_DEFAULT}`,
        payload
      )
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static deleteCustomFormatNumber(payload: any) {
    let data: any = payload;
    return httpClient
      .delete(`${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.DELETE}`, { data })
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static changeCustomNumberFormat(payload: any) {
    return httpClient
      .put(`${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.ALTER}`, payload)
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static getCustomNumberFormat(module: any) {
    return httpClient
      .post(`${ApiConstants.URL.BOOKS.GET_SEQUENCE_FORMAT}`, {
        module: module,
        application: [
          CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
          CUSTOM_NUMBER_INPUT_MODULES.DEAL,
          CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
          CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
        ].includes(module)
          ? APP_NAME
          : "ERP"
      })
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static checkNumberFormatExistance(format: string, module: any): Promise<any> {
    return httpClient
      .post(`${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.DOES_EXIST}`, {
        module: module,
        application: [
          CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
          CUSTOM_NUMBER_INPUT_MODULES.DEAL,
          CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
          CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
        ].includes(module)
          ? APP_NAME
          : "ERP",
        code: format
      })
      .catch((err: any) => {
        console.error("Error creating Custom Number Format: ", err);
        return Promise.reject(err);
      });
  }

  static updateSeparateSequenceFormat(
    useSeparateSequenceFormat: any
  ): Promise<any> {
    return httpClient
      .patch(
        `${ApiConstants.URL.BOOKS.CUSTOM_NUMBER_FORMAT.UPDATE_SEPARATE_SEQUENCE_FORMAT}`,
        {
          useSeparateSequenceFormat
        }
      )
      .catch((err: any) => {
        console.error("Error updating separate sequence format: ", err);
        return Promise.reject(err);
      });
  }
}

export default CustomNumberFormatService;
