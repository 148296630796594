import { DKLabel, Toggle } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import { US_COUNTRY_CODE } from "../../constants/Constant";
import UserManager from "../../managers/UserManager";

export default function QuotePushBackSetting(props) {
  const dispatch = useAppDispatch();
  const tenantDetails = useAppSelector(selectCRMSettings());
  const [toggle, setToggle] = useState(
    tenantDetails?.crmQuoteSetting?.isPullbackOptionDisabled ?? false
  );
  const isCountryUS = UserManager.getUserCountry() === US_COUNTRY_CODE;

  const onSave = async (newValue) => {
    try {
      let quoteSyncPayload = {
        crmQuoteSetting: {
          isPullbackOptionDisabled: newValue
        }
      };
      const response = await updateTenantSettings(quoteSyncPayload);
      if (response) {
        // Update Redux state
        dispatch(fetchCRMSettings({}));
        // Close the popup
        props.onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onToggle = async () => {
    // Toggle the state
    setToggle(!toggle);
    // Save the state
    await onSave(!toggle); // Pass the new toggle value to onSave
  };

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between">
          <div className="column">
            <DKLabel
              text={`${isCountryUS ? "Estimate" : "Quote"} Pull Back`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text={`You can enable/disable ${isCountryUS ? "estimate" : "quote"} pull back option`}
            />
          </div>
          <div className="ml-l">
            <Toggle
              isOn={toggle}
              onChange={onToggle}
              className=""
              color="bg-green"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
