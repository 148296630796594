import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import { removeLoader, showLoader } from "deskera-ui-library";
import { APP_NAME } from "../constants/Constant";
import EventManager from "../eventTracker/EventManager";
import { TABLES } from "../managers/TableManger";
import { EVENT_TYPE } from "../eventTracker/utility/EventConstants";

export default class SupportTicket {
  static getViewsList() {
    showLoader("Fetching... Please wait");
    let params = { app_name: "CRM3", fetch_linked_records: true };
    return http
      .get(`${ApiConstants.URL.TICKET_VIEWS.GET}`, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static getTicketByViewID(params, filter) {
    params = {
      app_name: "CRM3",
      fetch_linked_records: true,
      limit: 50,
      order: "DESC",
      ...params
    };
    return http
      .post(
        `${ApiConstants.URL.TICKET_VIEWS.GET_TICKET_BY_VIEW_ID_WITH_FIlTER}`,
        filter,
        { params }
      )
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getTicketDetails(ticketId) {
    const params = {
      ticket_id: ticketId,
      app_name: "CRM3",
      fetch_linked_records: true,
      check_sender_contact_existence: true
    };
    return http
      .get(`${ApiConstants.URL.TICKET_VIEWS.GET_TICKET_DETAILS}`, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static addTicket(data) {
    return http
      .post(`${ApiConstants.URL.TICKET_VIEWS.ADD_TICKET}`, data)
      .then(
        (response) => {
          removeLoader();
          EventManager.trackEventByModule(
            TABLES.TICKET,
            EVENT_TYPE.CREATED,
            data
          );
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static fetchInternalNotes = async (
    COMPONENT_ID,
    COMPONENT_NAME,
    APP_NAME,
    SUB_COMPONENT_NAME
  ) => {
    const url = `${ApiConstants.URL.NOTE.GET}?${`component_id=${COMPONENT_ID}&component_name=${COMPONENT_NAME}&app_name=${APP_NAME}&sub_component_name=${SUB_COMPONENT_NAME}`}`;

    try {
      const response = await http.get(url);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static sendEmail(data) {
    return http
      .post(`${ApiConstants.URL.SUPPORT_EMAIL.SEND_EMAIL}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static updateTicket(data) {
    return http
      .put(`${ApiConstants.URL.TICKET_VIEWS.UPDATE_TICKET(data.id)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static getRequestor(data) {
    let params = { app_name: "CRM3", pageSize: 100, limit: 100, page: 1 };
    return http
      .get(`${ApiConstants.URL.TICKET_VIEWS.GET_REQUESTOR}`, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static deleteEmail(emailId) {
    let params = { app_name: APP_NAME };
    return http
      .delete(`${ApiConstants.URL.SUPPORT_EMAIL.DELETE_EMAIL(emailId)}`, {
        params
      })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getTicketByIdTimer(ticketId) {
    return http
      .get(`${ApiConstants.URL.TICKET_VIEWS.GET_TICKET_TIMER(ticketId)}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getTicketByTicketId(ticketId) {
    let params = { app_name: APP_NAME, fetch_linked_records: true };
    return http
      .get(`${ApiConstants.URL.TICKET_VIEWS.GET_TICKET(ticketId)}`, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static getEmails() {
    let params = { app_name: "CRM3", validate: true };
    return http
      .get(`${ApiConstants.URL.SUPPORT_EMAIL.MANAGE_EMAIL}`, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static updateSupportEmail(data, id) {
    return http
      .patch(`${ApiConstants.URL.SUPPORT_EMAIL.UPDATE_THREAD(id)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static updateTicketDetails(data, id) {
    return http
      .patch(`${ApiConstants.URL.TICKET_VIEWS.UPDATE_TICKET(id)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static deleteTicket(id, data) {
    return http
      .delete(`${ApiConstants.URL.TICKET_VIEWS.UPDATE_TICKET(id)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static getContactDetailsInBulk(tableId, ids) {
    return http
      .post(
        `${ApiConstants.URL.TICKET_VIEWS.GET_CONTACT_DETAILS(tableId)}`,
        ids
      )
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static addCustomField(data) {
    return http
      .post(`${ApiConstants.URL.CUSTOM_FIELDS.POST}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static updateCustomField = (id, payload) => {
    return http
      .put(`${ApiConstants.URL.CUSTOM_FIELDS.UPDATE(id)}`, payload)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  };

  static exportTickets(queryParams, body = null) {
    let params = { app_name: "CRM3", order: "DESC", ...queryParams };
    return http
      .post(`${ApiConstants.URL.TICKET_VIEWS.EXPORT}`, body, { params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        removeLoader();
      });
  }
  static getCustomFields(params) {
    return http
      .get(`${ApiConstants.URL.CUSTOM_FIELDS.GET + params}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static deleteCustomField(CFId) {
    return http.delete(`${ApiConstants.URL.CUSTOM_FIELDS.DELETE(CFId)}`);
  }
  static restartWatch(id) {
    return http.get(`${ApiConstants.URL.SUPPORT_EMAIL.RESTART_WATCH(id)}`);
  }

  static updateCustomFieldOrder = (payload) => {
    return http
      .patch(`${ApiConstants.URL.CUSTOM_FIELDS.UPDATE_CF_ORDER}`, payload)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}
