import React, { useEffect, useState } from "react";
import { COUNTRIES_LIST } from "../../constants/Currencies";
import {
  DKLabel,
  DKListPicker2,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from "deskera-ui-library";
import { useAppSelector } from "../../redux/hooks";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import Utility from "../../utility/Utility";
import parsePhoneNumberFromString from "libphonenumber-js";
import debounce from "../../utility/Debounce";

export const PhoneNumberInput = (props: any) => {
  const countryList = COUNTRIES_LIST;
  const [countryWithCode, setCountryWithCode] = useState([]);
  const [countryCode, setCountryCode] = useState<any>({});
  const [phoneNumber, setPhoneNumber] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [initialValue, setInitialValue] = useState(false);
  const tenantDetails = useAppSelector(getTenantsDetails);

  useEffect(() => {
    let countryOptions = [];
    countryList.forEach((country) => {
      const option = {
        label: country.name,
        value: country.code
      };
      countryOptions.push(option);
    });
    setCountryWithCode(countryOptions);

    //Country code set
    let defaultCountry = countryList?.find(
      (country) => country.code === tenantDetails?.country
    );
    setCountryCode(defaultCountry);
  }, []);

  useEffect(() => {
    if (!initialValue) {
      setInitialValue(true);
      if (!Utility.isEmptyObject(props?.fieldData?.value)) {
        const contactNumberDetails: any = parsePhoneNumberFromString(
          props?.fieldData?.value
        );
        if (contactNumberDetails) {
          let selectedCountry = countryList?.find(
            (country) =>
              country.code ===
              (contactNumberDetails?.country || tenantDetails?.country)
          );
          setCountryCode(selectedCountry);
          setPhoneNumber(contactNumberDetails?.nationalNumber);
        } else {
          setPhoneNumber(
            props?.fieldData?.value.includes("+")
              ? null
              : props?.fieldData?.value
          );
        }
      } else {
        setPhoneNumber(null);
      }
    }
  }, [props?.fieldData?.value]);

  const contactNumberBtn = (
    isAddressFieldContact: boolean = false,
    indexToUpdate: number
  ) => {
    let mainValueKey = countryWithCode;

    if (mainValueKey) {
      const { currencyCode, dial_code } = countryCode;
      return (
        <div
          style={{
            padding: "8px 10px",
            borderRadius: "4px",
            backgroundColor: "rgb(235, 235, 235)",
            border: "1px solid rgb(235, 235, 235)",
            cursor: "pointer"
          }}
          className="mr-s"
          onClick={() => {
            setShowDropdown(true);
          }}
        >
          <div
            className="currency-select-trigger"
            style={{ margin: 0, paddingTop: "1px" }}
          >
            <div
              className="row align-items-center justify-content-center currency-dropdown-flag"
              style={{ margin: 0 }}
            >
              <span
                className={`currency-dropdown-selector-flag flag ${currencyCode}`}
                style={{ margin: 0 }}
              ></span>
            </div>
            <div className="currency-dropdown-code">{dial_code}</div>
          </div>
        </div>
      );
    }
    return <div></div>;
  };

  const phoneNumberOption = (phoneNumberDetails: any) => {
    return (
      <div className="parent-width">
        <div className="currency-select-trigger">
          <div className="currency-dropdown-flag">
            <span
              className={`currency-dropdown-selector-flag flag ${phoneNumberDetails.currencyCode}`}
            ></span>
          </div>
          <div className="currency-dropdown-code">
            {`${phoneNumberDetails.name} ${phoneNumberDetails.dial_code}`}
          </div>
        </div>
      </div>
    );
  };

  const updateValueCCode = (value) => {
    if (phoneNumber) {
      let phoneNo = value?.dial_code + phoneNumber;
      props?.onFieldChange(props?.accountFormControlKey, phoneNo);
    }
  };

  const onChangeValue = debounce((key, value) => {
    props?.onFieldChange(key, value);
  }, 500);

  const getErrorMessage = (fieldName: string, isRequired: boolean) => {
    if (isRequired) {
      return `${fieldName} is required and must be between 4 to 15 digits long`;
    } else {
      return `${fieldName} must be between 4 to 15 digits long`;
    }
  };
  const getPhoneField = () => {
    const { fieldData, index, accountFormControlKey } = props;
    let direction = props?.direction ?? INPUT_VIEW_DIRECTION.VERTICAL;
    const requiredField = fieldData.required
      ? fieldData.required
      : fieldData?.requiredByUser ?? false;
    return (
      <>
        <div
          className="row phone-number-input-container"
          style={{ ...props.style }}
        >
          <div className="column parent-width">
            {direction === INPUT_VIEW_DIRECTION.VERTICAL && (
              <DKLabel
                className="parent-width"
                text={`${fieldData.name} ${fieldData?.required || fieldData?.requiredByUser ? "*" : ""}`}
              />
            )}
            <div className="row justify-content-between mt-xs">
              {direction === INPUT_VIEW_DIRECTION.HORIZONTAL && (
                <DKLabel
                  className="row-auto-width1"
                  text={`${fieldData.name} ${fieldData?.required || fieldData?.requiredByUser ? "*" : ""}`}
                />
              )}
              <div
                className={`row ${direction === INPUT_VIEW_DIRECTION.VERTICAL ? " width-auto1 " : " width-auto justify-content-end "} ${requiredField ? "mb-m" : ""}`}
                style={{
                  width:
                    direction === INPUT_VIEW_DIRECTION.VERTICAL ? "100%" : 245
                }}
              >
                <div
                  className={`${direction === INPUT_VIEW_DIRECTION.VERTICAL ? " row flex-1" : "  "}`}
                >
                  <div className="position-relative">
                    {contactNumberBtn(false, 0)}
                    {showDropdown && (
                      <DKListPicker2
                        title={fieldData.name}
                        data={countryList}
                        style={{
                          width: 280
                        }}
                        allowSearch={true}
                        searchableKey="name"
                        className="position-absolute z-index-3 shadow-m"
                        onSelect={(index: number, value: any) => {
                          setCountryCode(value);
                          setShowDropdown(false);
                          updateValueCCode(value);
                        }}
                        onClose={() => {
                          setShowDropdown(false);
                        }}
                        renderer={(index: number, obj: any) => {
                          return (
                            <div
                              className="flex flex-row w-full justify-content-between"
                              style={{ width: "15rem" }}
                            >
                              {phoneNumberOption(obj)}
                            </div>
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                <DKInput
                  key={fieldData.id}
                  title={""}
                  className={`mt-l2 phone-number-input`}
                  autoFocus={index === 0}
                  required={requiredField}
                  direction={props?.direction ?? INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.NUMBER}
                  value={phoneNumber}
                  dropdownConfig={fieldData.dropdownConfig}
                  options={fieldData.optionTitles}
                  canValidate={props?.saveClicked}
                  validator={(value) => {
                    return (
                      !Utility.isEmptyObject(value) &&
                      value.length > 3 &&
                      value?.length < 16
                    );
                  }}
                  errorMessage={getErrorMessage(fieldData.name, requiredField)}
                  readOnly={fieldData?.readOnly}
                  onChange={(value) => {
                    setPhoneNumber(value);
                    onChangeValue(
                      accountFormControlKey,
                      Utility.isEmptyObject(value)
                        ? value
                        : countryCode?.dial_code + value
                    );
                  }}
                  renderer={fieldData?.renderer}
                  formatter={fieldData?.formatter}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return getPhoneField();
};
