import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showLoader,
  removeLoader,
  DKIcons,
  showAlert,
  DKLabel
} from "deskera-ui-library";
import Popup from "../Popup";
import Utility, { isObject, sanitizeForExcel } from "../../../utility/Utility";
import { AccountService } from "../../../services/accounts";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import {
  APP_NAME,
  CUSTOM_NUMBER_INPUT_MODULES,
  ERROR_MESSAGE_FOR_FORM,
  INPUT_TYPE_TEXT_AREA,
  TEXT_AREA_DEFAULT_LENGTH
} from "../../../constants/Constant";
import UserManager from "../../../managers/UserManager";
import PermissionService from "../../../services/common/permission";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
import EventManager from "../../../eventTracker/EventManager";
import { EVENT_TYPE } from "../../../eventTracker/utility/EventConstants";
import { IContactAddress } from "../../../model/BooksContact";
import { DetailAddressInputField } from "../DetailedAddress";
import { store } from "../../../redux/store";
import { showAddColumnPopup } from "../AddColumnPopup";
import ApiConstants from "../../../constants/ApiConstants";
import CustomNumberFormatInput from "../../books/custom_number/CustomNumberFormatInput";
import {
  fetchAccountSequenceFormats,
  getAccountSequenceFormats
} from "../../../redux/slices/booksSlice";
import { useAppSelector } from "../../../redux/hooks";
import { Provider } from "react-redux";
import { PhoneNumberInput } from "../PhoneNumberInput";
import parsePhoneNumberFromString from "libphonenumber-js";
export interface IAddAccountsProps {
  account?: any;
  onAddAccount: (account: any) => void;
  onClose: () => void;
}
function AddAccounts(props: IAddAccountsProps) {
  const permissionService = PermissionService.getInstance();
  const modulePermissions = permissionService.getUserPermissionByModule(
    permissionService.getUpdatedModuleName(TABLES.ACCOUNT)
  );
  const priceBookPermissions = permissionService.getUserPermissionByModule(
    permissionService.getUpdatedModuleName(TABLES.BOOKS_PRICE_LIST)
  );
  const [autoNumberingFormat, setAutoNumberingFormat] = React.useState<any>({});
  const [numberingFormat, setNumberingFormat] = React.useState<any>({});
  const [saveClicked, setSaveClicked] = React.useState(false);
  const [accountForm, setAccountForm] = React.useState(getInitialFormState());
  const sequenceFormat = useAppSelector(getAccountSequenceFormats);
  const [commonInvalidMsg, setCommonInvalidMsg] = React.useState(false);

  let cachedPriceBookForDisplay = null;

  function getInitialFormState(formData?: any) {
    /* For resetting form, when custom field added */
    if (!formData) {
      formData = {};
    }
    const accountColumns = TableManger.getTableVisibleColumns(TABLES.ACCOUNT);
    let filteredColumns = accountColumns.filter(
      (column) => column.editable || column.columnCode === "seq_code"
    );

    const getURLErrorMessage = (fieldName: string, isRequired: boolean) => {
      if (isRequired) {
        return `${fieldName} is required and invalid`;
      } else {
        return `Enter a valid ${fieldName}`;
      }
    };

    const getErrorMessage = (fieldName: string, isRequired: boolean) => {
      if (isRequired) {
        return `${fieldName} is required and cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
      } else {
        return `${fieldName} cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
      }
    };

    if (
      !modulePermissions.includes(USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM)
    ) {
      const OWNER_COL_ID = TableManger.getColumnId(
        TABLES.ACCOUNT,
        COLUMN_CODE.ACCOUNT.OWNER_ID
      );
      const SUB_OWNER_COL_ID = TableManger.getColumnId(
        TABLES.ACCOUNT,
        COLUMN_CODE.ACCOUNT.SUB_OWNER_ID
      );
      filteredColumns = filteredColumns.filter(
        (col) => col.id !== SUB_OWNER_COL_ID
      );
      filteredColumns.forEach((column) => {
        if (column.id === OWNER_COL_ID) {
          column.readOnly = true;
        }
      });
    }

    const accountHiddenColumns =
      store.getState().userPref.hiddenColumns?.ACCOUNT || [];
    const addressColId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.ADDRESS
    );
    const detailAddressColId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.DETAILED_ADDRESS
    );
    const notesColId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.NOTE
    );
    const priceBookColId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.PRICE_LIST
    );

    if (accountHiddenColumns.includes(addressColId)) {
      filteredColumns = filteredColumns.filter(
        (col) => col.id !== addressColId
      );
    }

    if (accountHiddenColumns.includes(detailAddressColId)) {
      filteredColumns = filteredColumns.filter(
        (col) => col.id !== detailAddressColId
      );
    }
    filteredColumns = filteredColumns.filter((col) => col.id !== notesColId);

    if (!priceBookPermissions.includes(USER_ACTION_TYPES.ASSIGN_TO_ACCOUNT)) {
      filteredColumns = filteredColumns.filter(
        (col) => col.id !== priceBookColId
      );
    }

    const accountFormData = {};
    filteredColumns?.forEach((column) => {
      const columnOptions = column.options || [];
      const key = column.columnCode || column.id;
      let value = formData[key]?.value ?? props.account?.[key];

      if (column.type === INPUT_TYPE.DATE) {
        value = Utility.isValidDate(value) ? new Date(value) : null;
      } else if (column.type === INPUT_TYPE.NUMBER) {
        value = Utility.isValidNumber(value) ? value : undefined;
      } else if (!Utility.isEmptyObject(column.lookup)) {
        value =
          props.account && props.account[key + "_detail"]
            ? props.account[key + "_detail"]?.[0]
            : isObject(formData[key]?.value)
              ? formData[key]?.value
              : null;
      } else if (
        [
          INPUT_TYPE.SELECT,
          INPUT_TYPE.MULTI_SELECT,
          INPUT_TYPE.DROPDOWN,
          "address"
        ].includes(column.type)
      ) {
        value = Utility.isEmptyObject(value) ? [] : value;
      } else if (Utility.isEmptyObject(value)) {
        value = "";
      }
      if (column.id === priceBookColId) {
        value = props?.account?.[priceBookColId + "_detail"]?.[0];
      }

      if (column.columnCode === COLUMN_CODE.ACCOUNT.DOCUMENT_SEQUENCE_CODE) {
        let updatedValues: any = { ...numberingFormat };
        updatedValues.defaultValue = formData[key]?.value;
        updatedValues.value = formData[key]?.value;
      }

      let readOnly = column?.readOnly || false;
      if (column.columnCode === COLUMN_CODE.ACCOUNT.STATUS) {
        readOnly = !modulePermissions.includes(USER_ACTION_TYPES.STATUS_UPDATE);
        value = Utility.isEmptyObject(value) ? [1] : value;
      }

      accountFormData[key] = {
        id: column.id,
        name: column.name,
        value: value,
        required: column.required
          ? column.required
          : column?.requiredByUser ?? false,
        type: column.type,
        options: columnOptions,
        readOnly: readOnly,
        optionTitles: columnOptions.map((option) => option.name),
        dropdownConfig: column.dropdownConfig,
        lookup: column.lookup
      };

      if (column.type === INPUT_TYPE.URL) {
        accountFormData[key]["validator"] = (value) => {
          return !Utility.isEmptyObject(value) && Utility.isValidURL(value);
        };

        accountFormData[key]["errorMessage"] = getURLErrorMessage(
          column.name,
          column.required ? column.required : column?.requiredByUser ?? false
        );
      }

      if (
        column.columnCode === COLUMN_CODE.ACCOUNT.NOTE ||
        column.columnCode === COLUMN_CODE.ACCOUNT.ADDITIONAL_INFO ||
        column.type === INPUT_TYPE_TEXT_AREA
      ) {
        accountFormData[key]["validator"] = (value) => {
          return (
            !Utility.isEmptyObject(value) &&
            value?.length < TEXT_AREA_DEFAULT_LENGTH
          );
        };

        accountFormData[key]["errorMessage"] = getErrorMessage(
          column.name,
          column.required ? column.required : column?.requiredByUser ?? false
        );
      }

      if (TableManger.isSelectField(column)) {
        const selectedOptionIndices = (accountFormData?.[key]?.value || [])
          .map((selectedOptionId: any) =>
            columnOptions.findIndex((option) => option.id === selectedOptionId)
          )
          .filter((selectedOptionIndex: number) => selectedOptionIndex !== -1);
        accountFormData[key].value = selectedOptionIndices;
      }

      if (
        column.columnCode === COLUMN_CODE.ACCOUNT.OWNER_ID &&
        Utility.isEmptyObject(
          accountFormData[column.columnCode || column.id].value
        )
      ) {
        const selectedOwnerIndex = column.options?.findIndex(
          (option) => option.id === UserManager.getUserIamID()
        );
        accountFormData[column.columnCode || column.id].value = [
          selectedOwnerIndex ?? 0
        ];
      }

      if (
        column.columnCode === COLUMN_CODE.ACCOUNT.NOTE ||
        column.columnCode === COLUMN_CODE.ACCOUNT.ADDITIONAL_INFO ||
        column.type === INPUT_TYPE_TEXT_AREA
      ) {
        accountFormData[column.columnCode || column.id].type =
          INPUT_TYPE.LONG_TEXT;
      }
    });
    return accountFormData;
  }

  useEffect(() => {
    store.dispatch(
      fetchAccountSequenceFormats({
        payload: {
          application: APP_NAME,
          module: CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT
        }
      })
    );
  }, []);

  const scrollToTop = () => {
    document.getElementById("dk-form-popup-window")?.scrollTo({ top: 0 });
  };

  const onLookupFieldChange = (fieldKey: string, value: any) => {
    const newAccountFormData = {
      ...accountForm,
      [fieldKey]: {
        ...accountForm[fieldKey],
        value
      }
    };
    setAccountForm(newAccountFormData);
  };

  const onFieldChange = (fieldKey: string, value: any, fieldData: any) => {
    const priceBookColumnId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.PRICE_LIST
    );

    let sanitizeValue = value;

    if (
      [INPUT_TYPE.LONG_TEXT, INPUT_TYPE.TEXT, INPUT_TYPE.URL].includes(
        fieldData.type
      )
    ) {
      sanitizeValue = sanitizeForExcel(value);
    }

    if (fieldKey === priceBookColumnId) {
      sanitizeValue = [value?.id];
    }
    const newAccountFormData = {
      ...accountForm,
      [fieldKey]: {
        ...accountForm[fieldKey],
        value: sanitizeValue
      }
    };

    // if (fieldKey === COLUMN_CODE.ACCOUNT.DETAILED_ADDRESS && newAccountFormData[COLUMN_CODE.ACCOUNT.ADDRESS]) {
    //   newAccountFormData[COLUMN_CODE.ACCOUNT.ADDRESS].value = value?.find((address: IContactAddress) => address.preferred)?.full || value?.[0]?.full || "";
    // }

    setAccountForm(newAccountFormData);
  };

  const getLengthOfPhone = (phoneNo, maxLen = false) => {
    const contactNumberDetails = parsePhoneNumberFromString(phoneNo);
    let length = maxLen ? 18 : 7;
    if (contactNumberDetails) {
      if (contactNumberDetails?.countryCallingCode === "1") {
        length = maxLen ? 17 : 6;
      }
    }
    return length;
  };

  const hasInvalidData = () => {
    let flag = false;

    if (
      Utility.isEmptyObject(
        accountForm[COLUMN_CODE.ACCOUNT.NAME]?.value?.trim()
      ) ||
      (!Utility.isEmptyObject(accountForm[COLUMN_CODE.ACCOUNT.EMAIL]?.value) &&
        !Utility.isValidEmail(accountForm[COLUMN_CODE.ACCOUNT.EMAIL]?.value)) ||
      (!Utility.isEmptyObject(
        accountForm[COLUMN_CODE.ACCOUNT.WEBSITE_URL]?.value
      ) &&
        !Utility.isValidURL(
          accountForm[COLUMN_CODE.ACCOUNT.WEBSITE_URL]?.value
        ))
    ) {
      flag = true;
    }
    const accountColumns = TableManger.getTableVisibleColumns(TABLES.ACCOUNT);
    accountColumns.forEach((column) => {
      const accColumn = accountForm[column.columnCode || column.id];
      if (accColumn?.type !== INPUT_TYPE.EMAIL) {
        if (
          accColumn?.type !== INPUT_TYPE.LONG_TEXT &&
          accColumn?.required &&
          (accColumn?.value?.length === 0 ||
            accColumn?.value === "" ||
            accColumn?.value === null)
        ) {
          flag = true;
        } else if (
          accColumn?.value?.length > TEXT_AREA_DEFAULT_LENGTH ||
          (accColumn?.required &&
            (accColumn?.value?.length === 0 ||
              accColumn?.value === "" ||
              accColumn?.value === null))
        ) {
          flag = true;
        } else if (
          accColumn?.type === INPUT_TYPE.PHONE &&
          !Utility.isEmptyObject(accColumn?.value) &&
          (accColumn?.value?.length < getLengthOfPhone(accColumn?.value) ||
            accColumn?.value?.length > getLengthOfPhone(accColumn?.value, true))
        ) {
          flag = true;
        }
      } else {
        if (accColumn.required && Utility.isEmptyObject(accColumn.value)) {
          flag = true;
        } else if (
          !Utility.isEmptyObject(accColumn.value) &&
          !Utility.isValidEmail(accColumn.value)
        ) {
          flag = true;
        }
      }
    });

    if (flag) {
      setCommonInvalidMsg(true);
    }

    return flag;
  };
  const addAccount = async () => {
    setSaveClicked(true);
    setCommonInvalidMsg(false);
    if (hasInvalidData()) return;

    showLoader(
      `${Utility.isEmptyObject(props.account) ? "Adding" : "Updating"} Account`
    );
    try {
      let results;
      let formDataToSubmit = {};

      const copyOfAccountForm = Utility.deepCloneObject(accountForm);
      const keyList = Object.keys(Utility.deepCloneObject(accountForm));
      keyList.forEach((accountFormControlKey) => {
        let value = copyOfAccountForm[accountFormControlKey].value;
        const columnType = copyOfAccountForm[accountFormControlKey].type;

        if (typeof value === "string") {
          value = value.trim();
        }
        if (
          [INPUT_TYPE.SELECT, INPUT_TYPE.MULTI_SELECT].includes(columnType) &&
          !Utility.isEmptyObject(value)
        ) {
          const options = copyOfAccountForm[accountFormControlKey].options;
          value = value
            ?.map((optionIndex: number) => options[optionIndex]?.id)
            .filter(
              (optionId: any) => optionId !== null && optionId !== undefined
            );
        }
        if ([INPUT_TYPE.DROPDOWN].includes(columnType) && value) {
          value = [value.id];
        }

        formDataToSubmit[accountFormControlKey] = value;
      });

      if (Utility.isEmptyObject(props.account)) {
        const selectedFormat: any = {};
        selectedFormat.value = autoNumberingFormat?.id;
        if (autoNumberingFormat.manualMode) {
          selectedFormat.defaultValue = autoNumberingFormat.text;
        } else {
          selectedFormat.defaultValue = undefined;
        }
        const sequenceFormat = selectedFormat.value?.toString();
        const documentSequenceCode = selectedFormat.defaultValue?.toString();
        const docSequenceCodeColumnId = TableManger.getColumnId(
          TABLES.ACCOUNT,
          COLUMN_CODE.ACCOUNT.DOCUMENT_SEQUENCE_CODE
        );
        const sequenceFormatColumnId = TableManger.getColumnId(
          TABLES.ACCOUNT,
          COLUMN_CODE.ACCOUNT.SEQUENCE_FORMAT
        );

        if (!Utility.isEmptyObject(documentSequenceCode))
          formDataToSubmit[COLUMN_CODE.ACCOUNT.DOCUMENT_SEQUENCE_CODE] =
            documentSequenceCode;
        if (!Utility.isEmptyObject(sequenceFormat))
          formDataToSubmit[COLUMN_CODE.ACCOUNT.SEQUENCE_FORMAT] =
            sequenceFormat;

        if (!Utility.isEmptyObject(props.account)) {
          if (!this.state.autoNumberingFormat?.manualMode) {
            delete formDataToSubmit[docSequenceCodeColumnId];
          } else {
            delete formDataToSubmit[sequenceFormatColumnId];
          }
        }
      }
      const payload = {
        cells: AccountService.buildAccountPayload(formDataToSubmit)
      };

      if (Utility.isEmptyObject(props.account)) {
        results = await AccountService.createAccount(payload);
        if (results) {
          EventManager.trackEventByModule(
            TABLES.ACCOUNT,
            EVENT_TYPE.CREATED,
            payload
          );
        }
      } else {
        results = await AccountService.updateAccount(props.account.id, payload);
      }

      if (results && results.isDuplicate && results.data?.success) {
        showAlert(
          "Duplicate record updated!",
          "Existing record was updated based on duplication settings."
        );
      }

      if (!Utility.isEmptyObject(results)) {
        props.onAddAccount?.(results);
      }
    } catch (error) {
      if (typeof error === "string") {
        let errorArr = error.split(",");
        showAlert(
          errorArr?.[0] || "Error",
          errorArr?.[1] || "Something went wrong!"
        );
      }
    } finally {
      removeLoader();
    }
    setSaveClicked(true);
  };
  const getHeader = () => {
    return (
      <div className="column parent-width">
        <div className="row align-items-center justify-content-between parent-width">
          <div className="row fs-l fw-h">
            {Utility.isEmptyObject(props.account) ? "Add" : "Update"} Account
          </div>
          <div className="row-reverse action-btn-wrapper">
            <DKButton
              className="border-m ml-r bg-button text-white"
              title="Save"
              onClick={addAccount}
            />
            <DKButton
              className="border-m bg-white"
              title="Cancel"
              onClick={props.onClose}
            />
          </div>
        </div>
        {commonInvalidMsg && (
          <div className="row">
            <DKLabel
              text={ERROR_MESSAGE_FOR_FORM}
              className="fs-s mt-m bg-chip-red text-red border-red p-v-xs p-h-s border-radius-m fw-m text-wrap-none "
            />
          </div>
        )}
      </div>
    );
  };
  const onCustomFieldAdd = () => {
    showAddColumnPopup({ tableName: TABLES.ACCOUNT }, () => {
      const newFormData = getInitialFormState(accountForm);
      setAccountForm(newFormData);
    });
  };
  const checkValueIsInvalid = (fieldData) => {
    if (!fieldData.required) return false;
    if (fieldData.required && saveClicked && fieldData.value?.length === 0) {
      return true;
    }
    return false;
  };
  const lookupConfigInput = (field, index) => {
    return (
      <DKInput
        className={"mt-m mobile-mt-s mb-m"}
        value={field.value}
        displayKey="label"
        title={field.name}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        required={
          field.required ? field.required : field?.requiredByUser ?? false
        }
        readOnly={field?.readOnly || false}
        canValidate={false}
        onChange={(value) => {
          onLookupFieldChange(field.id, value);
        }}
        renderer={(obj) => (obj ? obj?.name || obj.value : "")}
        dropdownConfig={{
          title: `Select ${field.name}`,
          allowSearch: true,
          allowRemoveSelection: false,
          searchableKey: "name",
          style: { minWidth: 230 },
          className: "z-index-1 shadow-m",
          searchApiConfig: {
            method: "POST",
            getUrl: (val) => {
              return (
                ApiConstants.URL.BASE +
                ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                  field.lookup.sourceTableId
                ) +
                "?pageNo=1&pageSize=20&q=" +
                val
              );
            },
            getPayload: (search) => {
              return field.lookup?.filter;
            },
            dataParser: (response) => {
              return (
                response?.data?.map((record) => ({
                  id: record._id,
                  name: record.cells[field.lookup?.sourceColumn]
                })) || []
              )?.filter(
                (option) => option?.name && !Utility.isEmptyObject(option?.name)
              );
            }
          },
          data: [],
          renderer: (index, obj) => {
            return (
              <div className="column parent-width">
                <DKLabel text={`${obj.name}`} />
              </div>
            );
          },
          onSelect: (index, obj, rowIndex) => {}
        }}
      />
    );
  };

  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    const numberingFormat: any = {};
    numberingFormat.value = selected.id;
    if (selected.manualMode) {
      numberingFormat.defaultValue = selected.text;
    } else {
      numberingFormat.defaultValue = undefined;
    }
    setAutoNumberingFormat({ ...selected, numberingFormat });
  };

  const getPhoneField = (
    fieldData: any,
    index: number,
    accountFormControlKey: any
  ) => {
    return (
      <PhoneNumberInput
        accountFormControlKey={"phone"}
        saveClicked={saveClicked}
        index={index}
        fieldData={fieldData}
        onFieldChange={(key, value) => onFieldChange(key, value, fieldData)}
        style={{ marginTop: 18 }}
      />
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width mb-xs">
        {Object.keys(accountForm).map((accountFormControlKey, index) => {
          const fieldData = accountForm[accountFormControlKey];
          if (
            accountFormControlKey === COLUMN_CODE.ACCOUNT.DOCUMENT_SEQUENCE_CODE
          ) {
            let seqFormate =
              sequenceFormat?.find((item) => item?.isDefault)?.sequenceCode ??
              "";
            return (
              <>
                {!Utility.isEmptyObject(props.account) ||
                !modulePermissions.includes(
                  USER_ACTION_TYPES.DOCUMENT_SEQUENCE_CODE_EDITABLE
                ) ? (
                  <DKInput
                    title={fieldData.name}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    className={"mt-m"}
                    value={fieldData.value || seqFormate}
                    readOnly={true}
                  />
                ) : (
                  <div className="parent-width row flex-wrap justify-content-between align-items-start mt-m">
                    <CustomNumberFormatInput
                      module={CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT}
                      selectedFormat={(selectedData) =>
                        selectedFormat(selectedData)
                      }
                      extraClass={"account-custom-number"}
                      isRow={false}
                      // autoNumberingFormat={this.state.autoNumberingFormat}
                      onCustomNumberPopupScrollToTop={() => scrollToTop()}
                      label={fieldData.name}
                    />
                  </div>
                )}
              </>
            );
          }
          if (
            fieldData.type === "address" ||
            accountFormControlKey === COLUMN_CODE.ACCOUNT.DETAILED_ADDRESS
          ) {
            return (
              <DetailAddressInputField
                recordId={props.account?.id}
                required={fieldData.required}
                invalid={checkValueIsInvalid(fieldData)}
                tableName={TABLES.ACCOUNT}
                addressList={fieldData.value || []}
                defaultFullAddress={
                  Utility.isEmptyObject(fieldData.value)
                    ? props.account?.address || ""
                    : ""
                }
                errorMsg={true}
                needLocationMarker={false}
                onEdit={(index: number, addressList: IContactAddress[]) =>
                  onFieldChange(accountFormControlKey, addressList, fieldData)
                }
                onDelete={(index: number, addressList: IContactAddress[]) =>
                  onFieldChange(accountFormControlKey, addressList, fieldData)
                }
                onAdd={(addressList: IContactAddress[]) =>
                  onFieldChange(accountFormControlKey, addressList, fieldData)
                }
                fieldData={{
                  key: fieldData.id,
                  title: fieldData.name,
                  direction: INPUT_VIEW_DIRECTION.VERTICAL,
                  className: "mt-l"
                }}
              />
            );
          }
          if (!Utility.isEmptyObject(fieldData.lookup)) {
            return lookupConfigInput(fieldData, index);
          }
          const priceBookColumnId = TableManger.getColumnId(
            TABLES.ACCOUNT,
            COLUMN_CODE.ACCOUNT.PRICE_LIST
          );
          if (fieldData?.id === priceBookColumnId) {
            cachedPriceBookForDisplay = {
              name: fieldData?.value?.value || fieldData?.value?.name
            };

            fieldData.dropdownConfig = {
              ...fieldData.dropdownConfig,
              onSelect: (index: number, selectedPriceB: any) => {
                cachedPriceBookForDisplay = selectedPriceB;
              }
            };
            fieldData.renderer = null;
            fieldData.formatter = () => {
              return cachedPriceBookForDisplay?.name || "";
            };
          }
          if (fieldData?.type === INPUT_TYPE.PHONE) {
            return getPhoneField(fieldData, index, accountFormControlKey);
          }

          return (
            <DKInput
              key={fieldData.id}
              title={fieldData.name}
              className="mt-l"
              autoFocus={index === 0}
              required={fieldData.required}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={fieldData.type}
              value={fieldData.value}
              dropdownConfig={fieldData.dropdownConfig}
              options={fieldData.optionTitles}
              canValidate={saveClicked}
              validator={fieldData.validator}
              errorMessage={fieldData.errorMessage}
              readOnly={fieldData?.readOnly}
              onChange={(value) =>
                onFieldChange(accountFormControlKey, value, fieldData)
              }
              renderer={fieldData?.renderer}
              formatter={fieldData?.formatter}
            />
          );
        })}
        {!!modulePermissions.includes(USER_ACTION_TYPES.FIELD_CREATE) && (
          <div className="row row-reverse mt-l">
            <DKButton
              className="bg-gray1 border-m"
              title={"New Field"}
              icon={DKIcons.ic_add}
              onClick={() => onCustomFieldAdd()}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <Popup>
      {getHeader()}
      {getBody()}
    </Popup>
  );
}

export default AddAccounts;

export const showAddAccountPopup = (
  config: { account?: any } = {},
  onSave?: (response: any) => void
) => {
  const id = `add-account-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "add-account-popup app-font";
  div.setAttribute("id", id);

  const handleOnClose = () => {
    const accountPopupElement = document.getElementById(id);
    ReactDOM.unmountComponentAtNode(accountPopupElement);
    accountPopupElement?.remove();
  };

  ReactDOM.render(
    <Provider store={store}>
      <AddAccounts
        {...config}
        onAddAccount={(account: any) => {
          handleOnClose();
          onSave(account);
        }}
        onClose={handleOnClose}
      />
    </Provider>,
    document.body.appendChild(div)
  );
};
