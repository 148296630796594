import { intersection } from "lodash";
import { numberWithCommas } from "../utility/Utility";

/**
 * @description - Source (2021) : United Nations (list of UN member states and permanent observers plus Kosovo and Taiwan)
 */
export const COUNTRIES_LIST = [
  {
    name: "Afghanistan",
    currencyCode: "AFN",
    code: "AF",
    dial_code: "+93",
    timezone: "UTC+04:30"
  },
  {
    name: "Åland Islands",
    currencyCode: "EUR",
    code: "AX",
    dial_code: "+358",
    timezone: "UTC+02:00"
  },
  {
    name: "Albania",
    currencyCode: "ALL",
    code: "AL",
    dial_code: "+355",
    timezone: "UTC+01:00"
  },
  {
    name: "Algeria",
    currencyCode: "DZD",
    code: "DZ",
    dial_code: "+213",
    timezone: "UTC+01:00"
  },
  {
    name: "American Samoa",
    currencyCode: "USD",
    code: "AS",
    dial_code: "+1684",
    timezone: "GMT-11:00"
  },
  {
    name: "Andorra",
    currencyCode: "EUR",
    code: "AD",
    dial_code: "+376",
    timezone: "UTC+01:00"
  },
  {
    name: "Angola",
    currencyCode: "AOA",
    code: "AO",
    dial_code: "+244",
    timezone: "UTC+01:00"
  },
  {
    name: "Anguilla",
    currencyCode: "XCD",
    code: "AI",
    dial_code: "+1264",
    timezone: "GMT-04:00"
  },
  {
    name: "Antarctica",
    currencyCode: "AUD",
    code: "AQ",
    dial_code: "+672",
    timezone: "GMT-03:00"
  },
  {
    name: "Antigua and Barbuda",
    currencyCode: "XCD",
    code: "AG",
    dial_code: "+1268",
    timezone: "GMT-04:00"
  },
  {
    name: "Argentina",
    currencyCode: "ARS",
    code: "AR",
    dial_code: "+54",
    timezone: "UTC-03:00"
  },
  {
    name: "Armenia",
    currencyCode: "AMD",
    code: "AM",
    dial_code: "+374",
    timezone: "UTC+04:00"
  },
  {
    name: "Aruba",
    currencyCode: "AWG",
    code: "AW",
    dial_code: "+43",
    timezone: "UTC-04:00"
  },
  {
    name: "Australia",
    currencyCode: "AUD",
    code: "AU",
    dial_code: "+61",
    timezone: "UTC+05:00"
  },
  {
    name: "Austria",
    currencyCode: "EUR",
    code: "AT",
    dial_code: "+43",
    timezone: "UTC+01:00"
  },
  {
    name: "Azerbaijan",
    currencyCode: "AZN",
    code: "AZ",
    dial_code: "+994",
    timezone: "UTC+04:00"
  },
  {
    name: "Bahamas",
    currencyCode: "BSD",
    code: "BS",
    dial_code: "+1242",
    timezone: "GMT-05:00"
  },
  {
    name: "Bahrain",
    currencyCode: "BHD",
    code: "BH",
    dial_code: "+973",
    timezone: "UTC+03:00"
  },
  {
    name: "Bangladesh",
    currencyCode: "BDT",
    code: "BD",
    dial_code: "+880",
    timezone: "UTC+06:00"
  },
  {
    name: "Barbados",
    currencyCode: "BBD",
    code: "BB",
    dial_code: "+1246",
    timezone: "GMT-04:00"
  },
  {
    name: "Belarus",
    currencyCode: "BYN",
    code: "BY",
    dial_code: "+375",
    timezone: "UTC+03:00"
  },
  {
    name: "Belgium",
    currencyCode: "EUR",
    code: "BE",
    dial_code: "+32",
    timezone: "UTC+01:00"
  },
  {
    name: "Belize",
    currencyCode: "BZD",
    code: "BZ",
    dial_code: "+501",
    timezone: "GMT-06:00"
  },
  {
    name: "Benin",
    currencyCode: "XOF",
    code: "BJ",
    dial_code: "+229",
    timezone: "UTC+01:00"
  },
  {
    name: "Bermuda",
    currencyCode: "BMD",
    code: "BM",
    dial_code: "+267",
    timezone: "UTC-04:00"
  },
  {
    name: "Bhutan",
    currencyCode: "BTN",
    code: "BT",
    dial_code: "+975",
    timezone: "UTC+06:00"
  },
  {
    name: "Bolivia",
    currencyCode: "BOB",
    code: "BO",
    dial_code: "+246",
    timezone: "UTC-04:00"
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    currencyCode: "USD",
    code: "BQ",
    dial_code: "+5997",
    timezone: "GMT-04:00"
  },
  {
    name: "Bosnia and Herzegovina",
    currencyCode: "BAM",
    code: "BA",
    dial_code: "+387",
    timezone: "UTC+01:00"
  },
  {
    name: "Botswana",
    currencyCode: "BWP",
    code: "BW",
    dial_code: "+267",
    timezone: "UTC+02:00"
  },
  {
    name: "Bouvet Island",
    currencyCode: "NOK",
    code: "BV",
    dial_code: "+",
    timezone: "UTC+01:00"
  },
  {
    name: "Brazil",
    currencyCode: "BRL",
    code: "BR",
    dial_code: "+55",
    timezone: "UTC-03:00"
  },
  {
    name: "British Indian Ocean Territory",
    currencyCode: "USD",
    code: "IO",
    dial_code: "+246",
    timezone: "UTC+06:00"
  },
  {
    name: "United States Minor Outlying Islands",
    currencyCode: "USD",
    code: "UM",
    dial_code: "+",
    timezone: "GMT-11:00"
  },
  {
    name: "Uruguay",
    currencyCode: "UYU",
    code: "UY",
    dial_code: "+47",
    timezone: "UTC-03:00"
  },
  {
    name: "Virgin Islands (U.S.)",
    currencyCode: "USD",
    code: "VI",
    dial_code: "+1 340",
    timezone: "GMT-04:00"
  },
  {
    name: "Brunei Darussalam",
    currencyCode: "BND",
    code: "BN",
    dial_code: "+673",
    timezone: "UTC+08:00"
  },
  {
    name: "Bulgaria",
    currencyCode: "BGN",
    code: "BG",
    dial_code: "+359",
    timezone: "UTC+02:00"
  },
  {
    name: "Burkina Faso",
    currencyCode: "XOF",
    code: "BF",
    dial_code: "+226",
    timezone: "GMT"
  },
  {
    name: "Burundi",
    currencyCode: "BIF",
    code: "BI",
    dial_code: "+257",
    timezone: "UTC+02:00"
  },
  {
    name: "Cambodia",
    currencyCode: "KHR",
    code: "KH",
    dial_code: "+855",
    timezone: "UTC+07:00"
  },
  {
    name: "Cameroon",
    currencyCode: "XAF",
    code: "CM",
    dial_code: "+237",
    timezone: "UTC+01:00"
  },
  {
    name: "Canada",
    currencyCode: "CAD",
    code: "CA",
    dial_code: "+1",
    timezone: "GMT-08:00"
  },
  {
    name: "Cabo Verde",
    currencyCode: "CVE",
    code: "CV",
    dial_code: "+238",
    timezone: "GMT-01:00"
  },
  {
    name: "Cayman Islands",
    currencyCode: "KYD",
    code: "KY",
    dial_code: "+1664",
    timezone: "UTC-05:00"
  },
  {
    name: "Central African Republic",
    currencyCode: "XAF",
    code: "CF",
    dial_code: "+236",
    timezone: "UTC+01:00"
  },
  {
    name: "Chad",
    currencyCode: "XAF",
    code: "TD",
    dial_code: "+235",
    timezone: "UTC+01:00"
  },
  {
    name: "Chile",
    code: "CL",
    currencyCode: "CLP",
    dial_code: "+682",
    timezone: "UTC-04:00"
  },
  {
    name: "China",
    currencyCode: "CNY",
    code: "CN",
    dial_code: "+86",
    timezone: "UTC+08:00"
  },
  {
    name: "Christmas Island",
    currencyCode: "AUD",
    code: "CX",
    dial_code: "+61",
    timezone: "UTC+07:00"
  },
  {
    name: "Cocos (Keeling) Islands",
    currencyCode: "AUD",
    code: "CC",
    dial_code: "+61",
    timezone: "UTC+06:30"
  },
  {
    name: "Colombia",
    currencyCode: "COP",
    code: "CO",
    dial_code: "+57",
    timezone: "UTC-05:00"
  },
  {
    name: "Comoros",
    currencyCode: "KMF",
    code: "KM",
    dial_code: "+269",
    timezone: "UTC+03:00"
  },
  {
    name: "Congo",
    currencyCode: "XAF",
    code: "CG",
    dial_code: "+242",
    timezone: "UTC+01:00"
  },
  {
    name: "Congo (Democratic Republic of the)",
    currencyCode: "CDF",
    code: "CD",
    dial_code: "+243",
    timezone: "UTC+01:00"
  },
  {
    name: "Cook Islands",
    currencyCode: "NZD",
    code: "CK",
    dial_code: "+242",
    timezone: "UTC-10:00"
  },
  {
    name: "Costa Rica",
    currencyCode: "CRC",
    code: "CR",
    dial_code: "+506",
    timezone: "GMT-06:00"
  },
  {
    name: `Cote d'Ivoire`,
    currencyCode: "CFA",
    code: "CI",
    dial_code: "+225",
    timezone: "UTC+00:00"
  },
  {
    name: "Croatia",
    currencyCode: "HRK",
    code: "HR",
    dial_code: "+385",
    timezone: "UTC+01:00"
  },
  {
    name: "Cuba",
    currencyCode: "CUP",
    code: "CU",
    dial_code: "+53",
    timezone: "GMT-05:00"
  },
  {
    name: "Curacao",
    currencyCode: "ANG",
    code: "CW",
    dial_code: "+253",
    timezone: "UTC-04:00"
  },
  {
    name: "Cyprus",
    currencyCode: "EUR",
    code: "CY",
    dial_code: "+357",
    timezone: "UTC+02:00"
  },
  {
    name: "Czech Republic",
    currencyCode: "CZK",
    code: "CZ",
    dial_code: "+420",
    timezone: "UTC+01:00"
  },
  {
    name: "Denmark",
    currencyCode: "DKK",
    code: "DK",
    dial_code: "+45",
    timezone: "GMT-04:00"
  },
  {
    name: "Djibouti",
    currencyCode: "DJF",
    code: "DJ",
    dial_code: "+253",
    timezone: "UTC+03:00"
  },
  {
    name: "Dominica",
    currencyCode: "XCD",
    code: "DM",
    dial_code: "+1767",
    timezone: "GMT-04:00"
  },
  {
    name: "Dominican Republic",
    currencyCode: "DOP",
    code: "DO",
    dial_code: "+1809",
    timezone: "GMT-04:00"
  },
  {
    name: "Ecuador",
    currencyCode: "USD",
    code: "EC",
    dial_code: "+593",
    timezone: "GMT-06:00"
  },
  {
    name: "Egypt",
    currencyCode: "EGP",
    code: "EG",
    dial_code: "+20",
    timezone: "UTC+02:00"
  },
  {
    name: "El Salvador",
    currencyCode: "USD",
    code: "SV",
    dial_code: "+503",
    timezone: "GMT-06:00"
  },
  {
    name: "Equatorial Guinea",
    currencyCode: "XAF",
    code: "GQ",
    dial_code: "+240",
    timezone: "UTC+01:00"
  },
  {
    name: "Eritrea",
    currencyCode: "ERN",
    code: "ER",
    dial_code: "+291",
    timezone: "UTC+03:00"
  },
  {
    name: "Estonia",
    currencyCode: "EUR",
    code: "EE",
    dial_code: "+372",
    timezone: "UTC+02:00"
  },
  {
    name: "Ethiopia",
    currencyCode: "ETB",
    code: "ET",
    dial_code: "+251",
    timezone: "UTC+03:00"
  },
  {
    name: "Falkland Islands",
    currencyCode: "FKP",
    code: "FK",
    dial_code: "+49",
    timezone: "UTC-03:00"
  },
  {
    name: "Faroe Islands",
    currencyCode: "DKK",
    code: "FO",
    dial_code: "+298",
    timezone: "UTC+00:00"
  },
  {
    name: "Fiji",
    currencyCode: "FJD",
    code: "FJ",
    dial_code: "+995",
    timezone: "UTC+12:00"
  },
  {
    name: "Finland",
    currencyCode: "EUR",
    code: "FI",
    dial_code: "+358",
    timezone: "UTC+02:00"
  },
  {
    name: "France",
    currencyCode: "EUR",
    code: "FR",
    dial_code: "+33",
    timezone: "GMT-10:00"
  },
  {
    name: "French Guiana",
    currencyCode: "EUR",
    code: "GF",
    dial_code: "+594",
    timezone: "GMT-03:00"
  },
  {
    name: "French Polynesia",
    currencyCode: "XPF",
    code: "PF",
    dial_code: "+992",
    timezone: "UTC-10:00"
  },
  {
    name: "French Southern Territories",
    currencyCode: "EUR",
    code: "TF",
    dial_code: "+",
    timezone: "UTC+05:00"
  },
  {
    name: "Gabon",
    currencyCode: "XAF",
    code: "GA",
    dial_code: "+241",
    timezone: "UTC+01:00"
  },
  {
    name: "Gambia",
    currencyCode: "GMD",
    code: "GM",
    dial_code: "+220",
    timezone: "UTC+00:00"
  },
  {
    name: "Georgia",
    currencyCode: "GEL",
    code: "GE",
    dial_code: "+995",
    timezone: "GMT-05:00"
  },
  {
    name: "Germany",
    currencyCode: "EUR",
    code: "DE",
    dial_code: "+49",
    timezone: "UTC+01:00"
  },
  {
    name: "Ghana",
    currencyCode: "GHS",
    code: "GH",
    dial_code: "+233",
    timezone: "UTC+00:00"
  },
  {
    name: "Gibraltar",
    currencyCode: "GIP",
    code: "GI",
    dial_code: "+595",
    timezone: "UTC+01:00"
  },
  {
    name: "Greece",
    currencyCode: "EUR",
    code: "GR",
    dial_code: "+30",
    timezone: "UTC+02:00"
  },
  {
    name: "Greenland",
    currencyCode: "DKK",
    code: "GL",
    dial_code: "+299",
    timezone: "GMT-04:00"
  },
  {
    name: "Grenada",
    currencyCode: "XCD",
    code: "GD",
    dial_code: "+1473",
    timezone: "GMT-04:00"
  },
  {
    name: "Guadeloupe",
    currencyCode: "EUR",
    code: "GP",
    dial_code: "+590",
    timezone: "GMT-04:00"
  },
  {
    name: "Guam",
    currencyCode: "USD",
    code: "GU",
    dial_code: "+1671",
    timezone: "UTC+10:00"
  },
  {
    name: "Guatemala",
    currencyCode: "GTQ",
    code: "GT",
    dial_code: "+502",
    timezone: "GMT-06:00"
  },
  {
    name: "Guernsey",
    currencyCode: "GBP",
    code: "GG",
    dial_code: "+44",
    timezone: "UTC+00:00"
  },
  {
    name: "Guinea",
    currencyCode: "GNF",
    code: "GN",
    dial_code: "+224",
    timezone: "GMT"
  },
  {
    name: "Guinea-Bissau",
    currencyCode: "XOF",
    code: "GW",
    dial_code: "+245",
    timezone: "GMT"
  },
  {
    name: "Guyana",
    currencyCode: "GYD",
    code: "GY",
    dial_code: "+1 876",
    timezone: "UTC-04:00"
  },
  {
    name: "Haiti",
    currencyCode: "HTG",
    code: "HT",
    dial_code: "+509",
    timezone: "GMT-05:00"
  },
  {
    name: "Heard Island and McDonald Islands",
    currencyCode: "AUD",
    code: "HM",
    dial_code: "+",
    timezone: "UTC+05:00"
  },
  {
    name: "Holy See",
    currencyCode: "EUR",
    code: "VA",
    dial_code: "+379",
    timezone: "UTC+01:00"
  },
  {
    name: "Honduras",
    currencyCode: "HNL",
    code: "HN",
    dial_code: "+504",
    timezone: "GMT-06:00"
  },
  {
    name: "Hong kong",
    currencyCode: "HKD",
    code: "HK",
    dial_code: "+852",
    timezone: "UTC+08:00"
  },
  {
    name: "Hungary",
    currencyCode: "HUF",
    code: "HU",
    dial_code: "+36",
    timezone: "UTC+01:00"
  },
  {
    name: "Iceland",
    currencyCode: "ISK",
    code: "IS",
    dial_code: "+354",
    timezone: "GMT"
  },
  {
    name: "India",
    currencyCode: "INR",
    code: "IN",
    dial_code: "+91",
    timezone: "UTC+05:30"
  },
  {
    name: "Indonesia",
    currencyCode: "IDR",
    code: "ID",
    dial_code: "+62",
    timezone: "UTC+07:00"
  },
  {
    name: "Côte d'Ivoire",
    currencyCode: "XOF",
    code: "CI",
    dial_code: "+225",
    timezone: "GMT"
  },
  {
    name: "Iran (Islamic Republic of)",
    currencyCode: "IRR",
    code: "IR",
    dial_code: "+98",
    timezone: "UTC+03:30"
  },
  {
    name: "Iraq",
    currencyCode: "IQD",
    code: "IQ",
    dial_code: "+964",
    timezone: "UTC+03:00"
  },
  {
    name: "Ireland",
    currencyCode: "EUR",
    code: "IE",
    dial_code: "+353",
    timezone: "GMT"
  },
  {
    name: "Isle of Man",
    currencyCode: "GBP",
    code: "IM",
    dial_code: "+44",
    timezone: "UTC+00:00"
  },
  {
    name: "Israel",
    currencyCode: "ILS",
    code: "IL",
    dial_code: "+972",
    timezone: "UTC+02:00"
  },
  {
    name: "Italy",
    currencyCode: "EUR",
    code: "IT",
    dial_code: "+39",
    timezone: "UTC+01:00"
  },
  {
    name: "Jamaica",
    currencyCode: "JMD",
    code: "JM",
    dial_code: "+1876",
    timezone: "GMT-05:00"
  },
  {
    name: "Japan",
    currencyCode: "JPY",
    code: "JP",
    dial_code: "+81",
    timezone: "UTC+09:00"
  },
  {
    name: "Jersey",
    currencyCode: "GBP",
    code: "JE",
    dial_code: "+44",
    timezone: "UTC+01:00"
  },
  {
    name: "Jordan",
    currencyCode: "JOD",
    code: "JO",
    dial_code: "+962",
    timezone: "UTC+03:00"
  },
  {
    name: "Kazakhstan",
    currencyCode: "KZT",
    code: "KZ",
    dial_code: "+76",
    timezone: "UTC+05:00"
  },
  {
    name: "Kenya",
    currencyCode: "KES",
    code: "KE",
    dial_code: "+254",
    timezone: "UTC+03:00"
  },
  {
    name: "Kiribati",
    currencyCode: "AUD",
    code: "KI",
    dial_code: "+686",
    timezone: "UTC+12:00"
  },
  {
    name: "Kuwait",
    currencyCode: "KWD",
    code: "KW",
    dial_code: "+965",
    timezone: "UTC+03:00"
  },
  {
    name: "Kyrgyzstan",
    currencyCode: "KGS",
    code: "KG",
    dial_code: "+996",
    timezone: "UTC+06:00"
  },
  {
    name: "Lao People's Democratic Republic",
    currencyCode: "LAK",
    code: "LA",
    dial_code: "+856",
    timezone: "UTC+07:00"
  },
  {
    name: "Latvia",
    currencyCode: "EUR",
    code: "LV",
    dial_code: "+371",
    timezone: "UTC+02:00"
  },
  {
    name: "Lebanon",
    currencyCode: "LBP",
    code: "LB",
    dial_code: "+961",
    timezone: "UTC+02:00"
  },
  {
    name: "Lesotho",
    currencyCode: "LSL",
    code: "LS",
    dial_code: "+266",
    timezone: "UTC+02:00"
  },
  {
    name: "Liberia",
    currencyCode: "LRD",
    code: "LR",
    dial_code: "+231",
    timezone: "GMT"
  },
  {
    name: "Libya",
    currencyCode: "LYD",
    code: "LY",
    dial_code: "+218",
    timezone: "UTC+01:00"
  },
  {
    name: "Liechtenstein",
    currencyCode: "CHF",
    code: "LI",
    dial_code: "+423",
    timezone: "UTC+01:00"
  },
  {
    name: "Lithuania",
    currencyCode: "EUR",
    code: "LT",
    dial_code: "+370",
    timezone: "UTC+02:00"
  },
  {
    name: "Luxembourg",
    currencyCode: "EUR",
    code: "LU",
    dial_code: "+352",
    timezone: "UTC+01:00"
  },
  {
    name: "Macao",
    currencyCode: "MOP",
    code: "MO",
    dial_code: "+63",
    timezone: "UTC+08:00"
  },
  {
    name: "Macedonia (the former Yugoslav Republic of)",
    currencyCode: "MKD",
    code: "MK",
    dial_code: "+389",
    timezone: "UTC+01:00"
  },
  {
    name: "Madagascar",
    currencyCode: "MGA",
    code: "MG",
    dial_code: "+261",
    timezone: "UTC+03:00"
  },
  {
    name: "Malawi",
    currencyCode: "MWK",
    code: "MW",
    dial_code: "+265",
    timezone: "UTC+02:00"
  },
  {
    name: "Malaysia",
    currencyCode: "MYR",
    code: "MY",
    dial_code: "+60",
    timezone: "UTC+08:00"
  },
  {
    name: "Maldives",
    currencyCode: "MVR",
    code: "MV",
    dial_code: "+960",
    timezone: "UTC+05:00"
  },
  {
    name: "Mali",
    currencyCode: "XOF",
    code: "ML",
    dial_code: "+223",
    timezone: "GMT"
  },
  {
    name: "Malta",
    currencyCode: "EUR",
    code: "MT",
    dial_code: "+356",
    timezone: "UTC+01:00"
  },
  {
    name: "Marshall Islands",
    currencyCode: "USD",
    code: "MH",
    dial_code: "+692",
    timezone: "UTC+12:00"
  },
  {
    name: "Martinique",
    currencyCode: "EUR",
    code: "MQ",
    dial_code: "+596",
    timezone: "GMT-04:00"
  },
  {
    name: "Mauritania",
    currencyCode: "MRU",
    code: "MR",
    dial_code: "+222",
    timezone: "GMT"
  },
  {
    name: "Mauritius",
    currencyCode: "MUR",
    code: "MU",
    dial_code: "+230",
    timezone: "UTC+04:00"
  },
  {
    name: "Mayotte",
    currencyCode: "EUR",
    code: "YT",
    dial_code: "+262",
    timezone: "UTC+03:00"
  },
  {
    name: "Mexico",
    currencyCode: "MXN",
    code: "MX",
    dial_code: "+52",
    timezone: "GMT-08:00"
  },
  {
    name: "Micronesia",
    currencyCode: "USD",
    code: "FM",
    dial_code: "+233",
    timezone: "UTC+11:00"
  },
  {
    name: "Moldova (Republic of)",
    currencyCode: "MDL",
    code: "MD",
    dial_code: "+373",
    timezone: "UTC+02:00"
  },
  {
    name: "Monaco",
    currencyCode: "EUR",
    code: "MC",
    dial_code: "+377",
    timezone: "UTC+01:00"
  },
  {
    name: "Mongolia",
    currencyCode: "MNT",
    code: "MN",
    dial_code: "+976",
    timezone: "UTC+07:00"
  },
  {
    name: "Montenegro",
    currencyCode: "EUR",
    code: "ME",
    dial_code: "+382",
    timezone: "UTC+01:00"
  },
  {
    name: "Montserrat",
    currencyCode: "XCD",
    code: "MS",
    dial_code: "+1664",
    timezone: "GMT-04:00"
  },
  {
    name: "Morocco",
    currencyCode: "MAD",
    code: "MA",
    dial_code: "+212",
    timezone: "GMT"
  },
  {
    name: "Mozambique",
    currencyCode: "MZN",
    code: "MZ",
    dial_code: "+258",
    timezone: "UTC+02:00"
  },
  {
    name: "Myanmar",
    currencyCode: "MMK",
    code: "MM",
    dial_code: "+95",
    timezone: "UTC+06:30"
  },
  {
    name: "Namibia",
    currencyCode: "NAD",
    code: "NA",
    dial_code: "+264",
    timezone: "UTC+01:00"
  },
  {
    name: "Nauru",
    currencyCode: "AUD",
    code: "NR",
    dial_code: "+674",
    timezone: "UTC+12:00"
  },
  {
    name: "Nepal",
    currencyCode: "NPR",
    code: "NP",
    dial_code: "+977",
    timezone: "UTC+05:45"
  },
  {
    name: "Netherlands",
    currencyCode: "EUR",
    code: "NL",
    dial_code: "+31",
    timezone: "GMT-04:00"
  },
  {
    name: "New Caledonia",
    currencyCode: "XPF",
    code: "NC",
    dial_code: "+232",
    timezone: "UTC+11:00"
  },
  {
    name: "New Zealand",
    currencyCode: "NZD",
    dial_code: "+64",
    code: "NZ",
    timezone: "UTC+13:00"
  },
  {
    name: "Nicaragua",
    currencyCode: "NIO",
    code: "NI",
    dial_code: "+505",
    timezone: "GMT-06:00"
  },
  {
    name: "Niger",
    currencyCode: "XOF",
    code: "NE",
    dial_code: "+227",
    timezone: "UTC+01:00"
  },
  {
    name: "Nigeria",
    currencyCode: "NGN",
    code: "NG",
    dial_code: "+234",
    timezone: "UTC+01:00"
  },
  {
    name: "Niue",
    currencyCode: "NZD",
    code: "NU",
    dial_code: "+683",
    timezone: "UTC-11:00"
  },
  {
    name: "Norfolk Island",
    currencyCode: "AUD",
    code: "NF",
    dial_code: "+672",
    timezone: "UTC+11:30"
  },
  {
    name: "Korea (Democratic People's Republic of)",
    currencyCode: "KPW",
    code: "KP",
    dial_code: "+850",
    timezone: "UTC+09:00"
  },
  {
    name: "Northern Mariana Islands",
    currencyCode: "USD",
    code: "MP",
    dial_code: "+1670",
    timezone: "UTC+10:00"
  },
  {
    name: "Norway",
    currencyCode: "NOK",
    code: "NO",
    dial_code: "+47",
    timezone: "UTC+01:00"
  },
  {
    name: "Oman",
    currencyCode: "OMR",
    code: "OM",
    dial_code: "+968",
    timezone: "UTC+04:00"
  },
  {
    name: "Pakistan",
    currencyCode: "PKR",
    code: "PK",
    dial_code: "+92",
    timezone: "UTC+05:00"
  },
  {
    name: "Palau",
    currencyCode: "USD",
    code: "PW",
    dial_code: "+688",
    timezone: "UTC+09:00"
  },
  {
    name: "Palestine, State of",
    currencyCode: "ILS",
    code: "PS",
    dial_code: "+970",
    timezone: "UTC+02:00"
  },
  {
    name: "Panama",
    currencyCode: "PAB",
    code: "PA",
    dial_code: "+507",
    timezone: "GMT-05:00"
  },
  {
    name: "Papua New Guinea",
    currencyCode: "PGK",
    code: "PG",
    dial_code: "+66",
    timezone: "UTC+10:00"
  },
  {
    name: "Paraguay",
    currencyCode: "PYG",
    code: "PY",
    dial_code: "+256",
    timezone: "UTC-04:00"
  },
  {
    name: "Peru",
    currencyCode: "PEN",
    code: "PE",
    dial_code: "+41",
    timezone: "UTC-05:00"
  },
  {
    name: "Philippines",
    currencyCode: "PHP",
    code: "PH",
    dial_code: "+63",
    timezone: "UTC+08:00"
  },
  {
    name: "Pitcairn",
    currencyCode: "NZD",
    code: "PN",
    dial_code: "+216",
    timezone: "UTC-08:00"
  },
  {
    name: "Poland",
    currencyCode: "PLN",
    code: "PL",
    dial_code: "+48",
    timezone: "UTC+01:00"
  },
  {
    name: "Portugal",
    currencyCode: "EUR",
    code: "PT",
    dial_code: "+351",
    timezone: "GMT-01:00"
  },
  {
    name: "Puerto Rico",
    currencyCode: "USD",
    code: "PR",
    dial_code: "+1",
    timezone: "GMT-04:00"
  },
  {
    name: "Qatar",
    currencyCode: "QAR",
    code: "QA",
    dial_code: "+974",
    timezone: "UTC+03:00"
  },
  {
    name: "Republic of Kosovo",
    currencyCode: "EUR",
    code: "XK",
    dial_code: "+383",
    timezone: "UTC+01:00"
  },
  {
    name: "Réunion",
    currencyCode: "EUR",
    code: "RE",
    dial_code: "+262",
    timezone: "UTC+04:00"
  },
  {
    name: "Romania",
    currencyCode: "RON",
    code: "RO",
    dial_code: "+40",
    timezone: "UTC+02:00"
  },
  {
    name: "Russian Federation",
    currencyCode: "RUB",
    code: "RU",
    dial_code: "+7",
    timezone: "UTC+03:00"
  },
  {
    name: "Rwanda",
    currencyCode: "RWF",
    code: "RW",
    dial_code: "+250",
    timezone: "UTC+02:00"
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    currencyCode: "SHP",
    code: "SH",
    dial_code: "+290",
    timezone: "UTC+00:00"
  },
  {
    name: "Saint Barthélemy",
    currencyCode: "EUR",
    code: "BL",
    dial_code: "+590",
    timezone: "GMT-04:00"
  },
  {
    name: "Saint Kitts and Nevis",
    currencyCode: "XCD",
    code: "KN",
    dial_code: "+1869",
    timezone: "GMT-04:00"
  },
  {
    name: "Saint Lucia",
    currencyCode: "XCD",
    code: "LC",
    dial_code: "+1758",
    timezone: "GMT-04:00"
  },
  {
    name: "Saint Martin (French part)",
    currencyCode: "EUR",
    code: "MF",
    dial_code: "+590",
    timezone: "GMT-04:00"
  },
  {
    name: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
    code: "PM",
    dial_code: "+508",
    timezone: "GMT-03:00"
  },
  {
    name: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
    code: "VC",
    dial_code: "+1784",
    timezone: "GMT-04:00"
  },
  {
    name: "Samoa",
    currencyCode: "WST",
    code: "WS",
    dial_code: "+685",
    timezone: "UTC+13:00"
  },
  {
    name: "San Marino",
    currencyCode: "EUR",
    code: "SM",
    dial_code: "+378",
    timezone: "UTC+01:00"
  },
  {
    name: "Sao Tome and Principe",
    currencyCode: "STD",
    code: "ST",
    dial_code: "+239",
    timezone: "GMT"
  },
  {
    name: "Saudi Arabia",
    currencyCode: "SAR",
    code: "SA",
    dial_code: "+966",
    timezone: "UTC+03:00"
  },
  {
    name: "Senegal",
    currencyCode: "XOF",
    code: "SN",
    dial_code: "+221",
    timezone: "GMT"
  },
  {
    name: "Serbia",
    currencyCode: "RSD",
    code: "RS",
    dial_code: "+381",
    timezone: "UTC+01:00"
  },
  {
    name: "Seychelles",
    currencyCode: "SCR",
    code: "SC",
    dial_code: "+248",
    timezone: "UTC+04:00"
  },
  {
    name: "Sierra Leone",
    currencyCode: "SLL",
    code: "SL",
    dial_code: "+232",
    timezone: "GMT"
  },
  {
    name: "Singapore",
    currencyCode: "SGD",
    code: "SG",
    dial_code: "+65",
    timezone: "UTC+08:00"
  },
  {
    name: "Sint Maarten",
    currencyCode: "ANG",
    code: "SX",
    dial_code: "+44",
    timezone: "UTC-04:00"
  },
  {
    name: "Slovakia",
    currencyCode: "EUR",
    code: "SK",
    dial_code: "+421",
    timezone: "UTC+01:00"
  },
  {
    name: "Slovenia",
    currencyCode: "EUR",
    code: "SI",
    dial_code: "+386",
    timezone: "UTC+01:00"
  },
  {
    name: "Solomon Islands",
    currencyCode: "SBD",
    code: "SB",
    dial_code: "+681",
    timezone: "UTC+11:00"
  },
  {
    name: "Somalia",
    currencyCode: "SOS",
    code: "SO",
    dial_code: "+252",
    timezone: "UTC+03:00"
  },
  {
    name: "South Africa",
    currencyCode: "ZAR",
    code: "ZA",
    dial_code: "+27",
    timezone: "UTC+02:00"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    currencyCode: "GBP",
    code: "GS",
    dial_code: "+500",
    timezone: "GMT-02:00"
  },
  {
    name: "Korea (Republic of)",
    currencyCode: "KRW",
    code: "KR",
    dial_code: "+82",
    timezone: "UTC+09:00"
  },
  {
    name: "South Sudan",
    currencyCode: "SSP",
    code: "SS",
    dial_code: "+211",
    timezone: "UTC+03:00"
  },
  {
    name: "Spain",
    currencyCode: "EUR",
    code: "ES",
    dial_code: "+34",
    timezone: "GMT"
  },
  {
    name: "Sri Lanka",
    currencyCode: "LKR",
    code: "LK",
    dial_code: "+94",
    timezone: "UTC+05:30"
  },
  {
    name: "Sudan",
    currencyCode: "SDG",
    code: "SD",
    dial_code: "+249",
    timezone: "UTC+03:00"
  },
  {
    name: "Suriname",
    currencyCode: "SRD",
    dial_code: "+597",
    code: "SR",
    timezone: "SR UTC-03:00"
  },
  {
    name: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
    code: "SJ",
    dial_code: "+4779",
    timezone: "UTC+01:00"
  },
  {
    name: "Swaziland",
    currencyCode: "SZL",
    code: "SZ",
    dial_code: "+268",
    timezone: "UTC+02:00"
  },
  {
    name: "Sweden",
    currencyCode: "SEK",
    code: "SE",
    dial_code: "+46",
    timezone: "UTC+01:00"
  },
  {
    name: "Switzerland",
    currencyCode: "CHF",
    code: "CH",
    dial_code: "+41",
    timezone: "UTC+01:00"
  },
  {
    name: "Syrian Arab Republic",
    currencyCode: "SYP",
    code: "SY",
    dial_code: "+963",
    timezone: "UTC+02:00"
  },
  {
    name: "Taiwan",
    currencyCode: "TWD",
    code: "TW",
    dial_code: "+886",
    timezone: "UTC+08:00"
  },
  {
    name: "Tajikistan",
    currencyCode: "TJS",
    code: "TJ",
    dial_code: "+992",
    timezone: "UTC+05:00"
  },
  {
    name: "Tanzania, United Republic of",
    currencyCode: "TZS",
    code: "TZ",
    dial_code: "+255",
    timezone: "UTC+03:00"
  },
  {
    name: "Thailand",
    currencyCode: "THB",
    code: "TH",
    dial_code: "+66",
    timezone: "UTC+07:00"
  },
  {
    name: "Timor-Leste",
    currencyCode: "USD",
    code: "TL",
    dial_code: "+670",
    timezone: "UTC+09:00"
  },
  {
    name: "Togo",
    currencyCode: "XOF",
    code: "TG",
    dial_code: "+228",
    timezone: "GMT"
  },
  {
    name: "Tokelau",
    currencyCode: "NZD",
    code: "TK",
    dial_code: "+258",
    timezone: "UTC+13:00"
  },
  {
    name: "Tonga",
    currencyCode: "TOP",
    code: "TO",
    dial_code: "+7",
    timezone: "UTC+13:00"
  },
  {
    name: "Trinidad and Tobago",
    currencyCode: "TTD",
    code: "TT",
    dial_code: "+1868",
    timezone: "GMT-04:00"
  },
  {
    name: "Tunisia",
    currencyCode: "TND",
    code: "TN",
    dial_code: "+216",
    timezone: "UTC+01:00"
  },
  {
    name: "Turkey",
    currencyCode: "TRY",
    code: "TR",
    dial_code: "+90",
    timezone: "UTC+03:00"
  },
  {
    name: "Turkmenistan",
    currencyCode: "TMT",
    code: "TM",
    dial_code: "+872",
    timezone: "UTC+05:00"
  },
  {
    name: "Turks and Caicos Islands",
    currencyCode: "USD",
    code: "TC",
    dial_code: "+1649",
    timezone: "GMT-04:00"
  },
  {
    name: "Tuvalu",
    currencyCode: "AUD",
    code: "TV",
    dial_code: "+688",
    timezone: "UTC+12:00"
  },
  {
    name: "Uganda",
    currencyCode: "UGX",
    code: "UG",
    dial_code: "+256",
    timezone: "UTC+03:00"
  },
  {
    name: "Ukraine",
    currencyCode: "UAH",
    code: "UA",
    dial_code: "+380",
    timezone: "UTC+02:00"
  },
  {
    name: "United Arab Emirates",
    currencyCode: "AED",
    code: "AE",
    dial_code: "+971",
    timezone: "UTC+04"
  },
  {
    name: "United Kingdom",
    currencyCode: "GBP",
    code: "UK",
    dial_code: "+44",
    timezone: "GMT-08:00"
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    currencyCode: "GBP",
    code: "GB",
    dial_code: "+44",
    timezone: "GMT-08:00"
  },
  {
    name: "United States of America",
    currencyCode: "USD",
    code: "US",
    dial_code: "+1",
    timezone: "GMT-12:00"
  },
  {
    name: "Uzbekistan",
    currencyCode: "UZS",
    code: "UZ",
    dial_code: "+998",
    timezone: "UTC+05:00"
  },
  {
    name: "Vanuatu",
    currencyCode: "VUV",
    code: "VU",
    dial_code: "+1 340",
    timezone: "UTC+11:00"
  },
  {
    name: "Venezuela",
    currencyCode: "VEF",
    code: "VE",
    dial_code: "+670",
    timezone: "UTC-04:00"
  },
  {
    name: "Virgin Islands (UK)",
    currencyCode: "USD",
    code: "VG",
    dial_code: "+58",
    timezone: "UTC-04:00"
  },
  {
    name: "Viet Nam",
    currencyCode: "VND",
    code: "VN",
    dial_code: "+84",
    timezone: "UTC+07:00"
  },
  {
    name: "Wallis and Futuna",
    currencyCode: "XPF",
    code: "WF",
    dial_code: "+681",
    timezone: "UTC+12:00"
  },
  {
    name: "Western Sahara",
    currencyCode: "MAD",
    code: "EH",
    dial_code: "+212",
    timezone: "UTC+00:00"
  },
  {
    name: "Yemen",
    currencyCode: "YER",
    code: "YE",
    dial_code: "+967",
    timezone: "UTC+03:00"
  },
  {
    name: "Zambia",
    currencyCode: "ZMW",
    code: "ZM",
    dial_code: "+260",
    timezone: "UTC+02:00"
  },
  {
    name: "Zimbabwe",
    currencyCode: "BWP",
    code: "ZW",
    dial_code: "+263",
    timezone: "UTC+02:00"
  }
];

export const COUNTRIES_WITH_CURRENCIES = [
  {
    country: "Afghanistan",
    currencyName: "Afghan afghani",
    currencyCode: "AFN",
    countryCode: "AF"
  },
  {
    country: "Albania",
    currencyName: "Albanian lek",
    currencyCode: "ALL",
    countryCode: "AL"
  },
  {
    country: "Algeria",
    currencyName: "Algerian dinar",
    currencyCode: "DZD",
    countryCode: "DZ"
  },
  {
    country: "Andorra",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Angola",
    currencyName: "Angolan kwanza",
    currencyCode: "AOA",
    countryCode: "AO"
  },
  {
    country: "Antigua and Barbuda",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Argentina",
    currencyName: "Argentine peso",
    currencyCode: "ARS",
    countryCode: "AR"
  },
  {
    country: "Armenia",
    currencyName: "Armenian dram",
    currencyCode: "AMD",
    countryCode: "AM"
  },
  {
    country: "Australia",
    currencyName: "Australian dollar",
    currencyCode: "AUD",
    countryCode: "AQ"
  },
  {
    country: "Austria",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Azerbaijan",
    currencyName: "Azerbaijani manat",
    currencyCode: "AZN",
    countryCode: "AZ"
  },
  {
    country: "Bahamas",
    currencyName: "Bahamian dollar",
    currencyCode: "BSD",
    countryCode: "BS"
  },
  {
    country: "Bahrain",
    currencyName: "Bahraini dinar",
    currencyCode: "BHD",
    countryCode: "BH"
  },
  {
    country: "Bangladesh",
    currencyName: "Bangladeshi taka",
    currencyCode: "BDT",
    countryCode: "BD"
  },
  {
    country: "Barbados",
    currencyName: "Barbadian dollar",
    currencyCode: "BBD",
    countryCode: "BB"
  },
  {
    country: "Belarus",
    currencyName: "Belarusian ruble",
    currencyCode: "BYR",
    countryCode: "BY"
  },
  {
    country: "Belgium",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Belize",
    currencyName: "Belize dollar",
    currencyCode: "BZD",
    countryCode: "BZ"
  },
  {
    country: "Benin",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Bhutan",
    currencyName: "Bhutanese ngultrum",
    currencyCode: "BTN",
    countryCode: "BT"
  },
  {
    country: "Bolivia",
    currencyName: "Bolivian boliviano",
    currencyCode: "BOB",
    countryCode: "BO"
  },
  {
    country: "Bosnia and Herzegovina",
    currencyName: "Bosnia and Herzegovina convertible mark",
    currencyCode: "BAM",
    countryCode: "BA"
  },
  {
    country: "Botswana",
    currencyName: "Botswana pula",
    currencyCode: "BWP",
    countryCode: "BW"
  },
  {
    country: "Brazil",
    currencyName: "Brazilian real",
    currencyCode: "BRL",
    countryCode: "BR"
  },
  {
    country: "Brunei",
    currencyName: "Brunei dollar",
    currencyCode: "BND",
    countryCode: "BN"
  },
  {
    country: "Bulgaria",
    currencyName: "Bulgarian lev",
    currencyCode: "BGN",
    countryCode: "BG"
  },
  {
    country: "Burkina Faso",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Burundi",
    currencyName: "Burundian franc",
    currencyCode: "BIF",
    countryCode: "BI"
  },
  {
    country: "Cambodia",
    currencyName: "Cambodian riel",
    currencyCode: "KHR",
    countryCode: "KH"
  },
  {
    country: "Cameroon",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Canada",
    currencyName: "Canadian dollar",
    currencyCode: "CAD",
    countryCode: "CA"
  },
  {
    country: "Cape Verde",
    currencyName: "Cape Verdean escudo",
    currencyCode: "CVE",
    countryCode: "CV"
  },
  {
    country: "Central African Republic",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Chad",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Chile",
    currencyName: "Chilean peso",
    currencyCode: "CLP",
    countryCode: "CL"
  },
  {
    country: "China",
    currencyName: "Chinese yuan",
    currencyCode: "CNY",
    countryCode: "CN"
  },
  {
    country: "Comoros",
    currencyName: "Comorian franc",
    currencyCode: "KMF",
    countryCode: "KM"
  },
  {
    country: "Cook Islands",
    currencyName: "New zealand Dollar",
    currencyCode: "NZD",
    countryCode: "CK"
  },
  {
    country: "Costa Rica",
    currencyName: "Costa Rican colón",
    currencyCode: "CRC",
    countryCode: "CR"
  },
  {
    country: "Colombia",
    currencyName: "Colombian Peso",
    currencyCode: "COP",
    countryCode: "COL"
  },
  {
    country: "Cote d'Ivoire",
    currencyName: "CFA franc",
    currencyCode: "CFA",
    countryCode: "CIV"
  },
  {
    country: "Croatia",
    currencyName: "Croatian kuna",
    currencyCode: "HRK",
    countryCode: "HR"
  },
  {
    country: "Cyprus",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Czech Republic",
    currencyName: "Czech koruna",
    currencyCode: "CZK",
    countryCode: "CZ"
  },
  {
    country: "Democratic Republic of Congo",
    currencyName: "Congolese franc",
    currencyCode: "CDF",
    countryCode: "CD"
  },
  {
    country: "Denmark",
    currencyName: "Danish krone",
    currencyCode: "DKK",
    countryCode: "DK"
  },
  {
    country: "Djibouti",
    currencyName: "Djiboutian franc",
    currencyCode: "DJF",
    countryCode: "DJ"
  },
  {
    country: "Dominica",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Dominican Republic",
    currencyName: "Dominican peso",
    currencyCode: "DOP",
    countryCode: "DO"
  },
  {
    country: "East Timor",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "TLS"
  },
  {
    country: "Ecuador",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "EC"
  },
  {
    country: "Egypt",
    currencyName: "Egyptian pound",
    currencyCode: "EGP",
    countryCode: "EG"
  },
  {
    country: "El Salvador",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "SLV"
  },
  {
    country: "Equatorial Guinea",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Eritrea",
    currencyName: "Eritrean nakfa",
    currencyCode: "ERN",
    countryCode: "ER"
  },
  {
    country: "Estonia",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Eswatini",
    currencyName: "Swazi lilangeni",
    currencyCode: "SZL",
    countryCode: "SZ"
  },
  {
    country: "Ethiopia",
    currencyName: "Ethiopian birr",
    currencyCode: "ETB",
    countryCode: "ET"
  },
  {
    country: "Fiji",
    currencyName: "Fijian dollar",
    currencyCode: "FJD",
    countryCode: "FJ"
  },
  {
    country: "Finland",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "France",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "French Polynesia",
    currencyName: "CFP Franc",
    currencyCode: "XPF",
    countryCode: "PF"
  },
  {
    country: "Gabon",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Gambia",
    currencyName: "Gambian dalasi",
    currencyCode: "GMD",
    countryCode: "GM"
  },
  {
    country: "Georgia",
    currencyName: "Georgian lari",
    currencyCode: "GEL",
    countryCode: "GE"
  },
  {
    country: "Germany",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Ghana",
    currencyName: "Ghanaian cedi",
    currencyCode: "GHS",
    countryCode: "GH"
  },
  {
    country: "Greece",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Grenada",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Guatemala",
    currencyName: "Guatemalan quetzal",
    currencyCode: "GTQ",
    countryCode: "GT"
  },
  {
    country: "Guinea",
    currencyName: "Guinean franc",
    currencyCode: "GNF",
    countryCode: "GN"
  },
  {
    country: "Guinea-Bissau",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Guyana",
    currencyName: "Guyanese dollar",
    currencyCode: "GYD",
    countryCode: "GY"
  },
  {
    country: "Haiti",
    currencyName: "Haitian gourde",
    currencyCode: "HTG",
    countryCode: "HT"
  },
  {
    country: "Honduras",
    currencyName: "Honduran lempira",
    currencyCode: "HNL",
    countryCode: "HN"
  },
  {
    country: "Hungary",
    currencyName: "Hungarian forint",
    currencyCode: "HUF",
    countryCode: "HU"
  },
  {
    country: "Hong kong",
    currencyName: "Hong Kong dollar",
    currencyCode: "HKD",
    countryCode: "HK"
  },
  {
    country: "Iceland",
    currencyName: "Icelandic króna",
    currencyCode: "ISK",
    countryCode: "IS"
  },
  {
    country: "India",
    currencyName: "Indian rupee",
    currencyCode: "INR",
    countryCode: "IN"
  },
  {
    country: "Indonesia",
    currencyName: "Indonesian rupiah",
    currencyCode: "IDR",
    countryCode: "ID"
  },
  {
    country: "Iran",
    currencyName: "Iranian rial",
    currencyCode: "IRR",
    countryCode: "IR"
  },
  {
    country: "Iraq",
    currencyName: "Iraqi dinar",
    currencyCode: "IQD",
    countryCode: "IQ"
  },
  {
    country: "Ireland",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Israel",
    currencyName: "Israeli new shekel",
    currencyCode: "ILS",
    countryCode: "IL"
  },
  {
    country: "Italy",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Ivory Coast",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Jamaica",
    currencyName: "Jamaican dollar",
    currencyCode: "JMD",
    countryCode: "JM"
  },
  {
    country: "Japan",
    currencyName: "Japanese yen",
    currencyCode: "JPY",
    countryCode: "JP"
  },
  {
    country: "Jordan",
    currencyName: "Jordanian dinar",
    currencyCode: "JOD",
    countryCode: "JO"
  },
  {
    country: "Kazakhstan",
    currencyName: "Kazakhstani tenge",
    currencyCode: "KZT",
    countryCode: "KZ"
  },
  {
    country: "Kenya",
    currencyName: "Kenyan shilling",
    currencyCode: "KES",
    countryCode: "KE"
  },
  {
    country: "Kiribati",
    currencyName: "Australian dollar",
    currencyCode: "AUD",
    countryCode: "AQ"
  },
  {
    country: "Korea, North",
    currencyName: "North Korean won",
    currencyCode: "KPW",
    countryCode: "KP"
  },
  {
    country: "Korea, South",
    currencyName: "South Korean won",
    currencyCode: "KRW",
    countryCode: "KR"
  },
  {
    country: "Kosovo",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Kuwait",
    currencyName: "Kuwaiti dinar",
    currencyCode: "KWD",
    countryCode: "KW"
  },
  {
    country: "Kyrgyzstan",
    currencyName: "Kyrgyzstani som",
    currencyCode: "KGS",
    countryCode: "KG"
  },
  {
    country: "Laos",
    currencyName: "Lao kip",
    currencyCode: "LAK",
    countryCode: "LA"
  },
  {
    country: "Latvia",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Lebanon",
    currencyName: "Lebanese pound",
    currencyCode: "LBP",
    countryCode: "LB"
  },
  {
    country: "Lesotho",
    currencyName: "Lesotho loti",
    currencyCode: "LSL",
    countryCode: "LS"
  },
  {
    country: "Liberia",
    currencyName: "Liberian dollar",
    currencyCode: "LRD",
    countryCode: "LR"
  },
  {
    country: "Libya",
    currencyName: "Libyan dinar",
    currencyCode: "LYD",
    countryCode: "LY"
  },
  {
    country: "Liechtenstein",
    currencyName: "Swiss franc",
    currencyCode: "CHF",
    countryCode: "LI"
  },
  {
    country: "Lithuania",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Luxembourg",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Madagascar",
    currencyName: "Malagasy ariary",
    currencyCode: "MGA",
    countryCode: "MG"
  },
  {
    country: "Malawi",
    currencyName: "Malawian kwacha",
    currencyCode: "MWK",
    countryCode: "MW"
  },
  {
    country: "Malaysia",
    currencyName: "Malaysian ringgit",
    currencyCode: "MYR",
    countryCode: "MY"
  },
  {
    country: "Maldives",
    currencyName: "Maldivian rufiyaa",
    currencyCode: "MVR",
    countryCode: "MV"
  },
  {
    country: "Mali",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Malta",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Marshall Islands",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "MHL"
  },
  {
    country: "Mauritania",
    currencyName: "Mauritanian ouguiya",
    currencyCode: "MRO",
    countryCode: "MR"
  },
  {
    country: "Mauritius",
    currencyName: "Mauritian rupee",
    currencyCode: "MUR",
    countryCode: "MU"
  },
  {
    country: "Mexico",
    currencyName: "Mexican peso",
    currencyCode: "MXN",
    countryCode: "MX"
  },
  {
    country: "Micronesia",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "FSM"
  },
  {
    country: "Moldova",
    currencyName: "Moldovan leu",
    currencyCode: "MDL",
    countryCode: "MD"
  },
  {
    country: "Monaco",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Mongolia",
    currencyName: "Mongolian tögrög",
    currencyCode: "MNT",
    countryCode: "MN"
  },
  {
    country: "Montenegro",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Morocco",
    currencyName: "Moroccan dirham",
    currencyCode: "MAD",
    countryCode: "MA"
  },
  {
    country: "Mozambique",
    currencyName: "Mozambican metical",
    currencyCode: "MZN",
    countryCode: "MZ"
  },
  {
    country: "Myanmar",
    currencyName: "Burmese kyat",
    currencyCode: "MMK",
    countryCode: "MM"
  },
  {
    country: "Namibia",
    currencyName: "Namibian dollar",
    currencyCode: "NAD",
    countryCode: "NA"
  },
  {
    country: "Nauru",
    currencyName: "Australian dollar",
    currencyCode: "AUD",
    countryCode: "AQ"
  },
  {
    country: "Nepal",
    currencyName: "Nepalese rupee",
    currencyCode: "NPR",
    countryCode: "NP"
  },
  {
    country: "Netherlands",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "New Zealand",
    currencyName: "New Zealand dollar",
    currencyCode: "NZD",
    countryCode: "CK"
  },
  {
    country: "Nicaragua",
    currencyName: "Nicaraguan córdoba",
    currencyCode: "NIO",
    countryCode: "NI"
  },
  {
    country: "Niger",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Nigeria",
    currencyName: "Nigerian naira",
    currencyCode: "NGN",
    countryCode: "NG"
  },
  {
    country: "North Macedonia",
    currencyName: "Macedonian denar",
    currencyCode: "MKD",
    countryCode: "MK"
  },
  {
    country: "Norway",
    currencyName: "Norwegian krone",
    currencyCode: "NOK",
    countryCode: "BV"
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    currencyName: "Saint Helena pound",
    currencyCode: "SHP",
    countryCode: "SH"
  },
  {
    country: "Oman",
    currencyName: "Omani rial",
    currencyCode: "OMR",
    countryCode: "OM"
  },
  {
    country: "Pakistan",
    currencyName: "Pakistani rupee",
    currencyCode: "PKR",
    countryCode: "PK"
  },
  {
    country: "Palau",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "PLW"
  },
  {
    country: "Palestine",
    currencyName: "Israeli new shekel",
    currencyCode: "ILS",
    countryCode: "IL"
  },
  {
    country: "Panama",
    currencyName: "Panamanian balboa",
    currencyCode: "PAB",
    countryCode: "PA"
  },
  {
    country: "Papua New Guinea",
    currencyName: "Papua New Guinean kina",
    currencyCode: "PGK",
    countryCode: "PG"
  },
  {
    country: "Paraguay",
    currencyName: "Paraguayan guaraní",
    currencyCode: "PYG",
    countryCode: "PY"
  },
  {
    country: "Peru",
    currencyName: "Peruvian sol",
    currencyCode: "PEN",
    countryCode: "PE"
  },
  {
    country: "Philippines",
    currencyName: "Philippine peso",
    currencyCode: "PHP",
    countryCode: "PH"
  },
  {
    country: "Poland",
    currencyName: "Polish zloty",
    currencyCode: "PLN",
    countryCode: "PL"
  },
  {
    country: "Portugal",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Qatar",
    currencyName: "Qatari riyal",
    currencyCode: "QAR",
    countryCode: "QA"
  },
  {
    country: "Republic of the Congo",
    currencyName: "Central African CFA franc",
    currencyCode: "XAF",
    countryCode: "CM"
  },
  {
    country: "Romania",
    currencyName: "Romanian leu",
    currencyCode: "RON",
    countryCode: "RO"
  },
  {
    country: "Russia",
    currencyName: "Russian ruble",
    currencyCode: "RUB",
    countryCode: "RU"
  },
  {
    country: "Rwanda",
    currencyName: "Rwandan franc",
    currencyCode: "RWF",
    countryCode: "RW"
  },
  {
    country: "Saint Kitts and Nevis",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Saint Lucia",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Saint Vincent and the Grenadines",
    currencyName: "East Caribbean dollar",
    currencyCode: "XCD",
    countryCode: "AI"
  },
  {
    country: "Samoa",
    currencyName: "Samoan tala",
    currencyCode: "WST",
    countryCode: "WS"
  },
  {
    country: "San Marino",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "São Tomé and Príncipe",
    currencyName: "São Tomé and Príncipe dobra",
    currencyCode: "STD",
    countryCode: "ST"
  },
  {
    country: "Saudi Arabia",
    currencyName: "Saudi riyal",
    currencyCode: "SAR",
    countryCode: "SA"
  },
  {
    country: "Senegal",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Serbia",
    currencyName: "Serbian dinar",
    currencyCode: "RSD",
    countryCode: "RS"
  },
  {
    country: "Seychelles",
    currencyName: "Seychellois rupee",
    currencyCode: "SCR",
    countryCode: "SC"
  },
  {
    country: "Sierra Leone",
    currencyName: "Sierra Leonean leone",
    currencyCode: "SLL",
    countryCode: "SL"
  },
  {
    country: "Singapore",
    currencyName: "Singapore dollar",
    currencyCode: "SGD",
    countryCode: "SG"
  },
  {
    country: "Slovakia",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Slovenia",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Solomon Islands",
    currencyName: "Solomon Islands dollar",
    currencyCode: "SBD",
    countryCode: "SB"
  },
  {
    country: "Somalia",
    currencyName: "Somali shilling",
    currencyCode: "SOS",
    countryCode: "SO"
  },
  {
    country: "South Africa",
    currencyName: "South African rand",
    currencyCode: "ZAR",
    countryCode: "ZA"
  },
  {
    country: "South Sudan",
    currencyName: "South Sudanese pound",
    currencyCode: "SSP",
    countryCode: "SS"
  },
  {
    country: "Spain",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Sri Lanka",
    currencyName: "Sri Lankan rupee",
    currencyCode: "LKR",
    countryCode: "LK"
  },
  {
    country: "Sudan",
    currencyName: "Sudanese pound",
    currencyCode: "SDG",
    countryCode: "SD"
  },
  {
    country: "Suriname",
    currencyName: "Surinamese dollar",
    currencyCode: "SRD",
    countryCode: "SR"
  },
  {
    country: "Sweden",
    currencyName: "Swedish krona",
    currencyCode: "SEK",
    countryCode: "SE"
  },
  {
    country: "Switzerland",
    currencyName: "Swiss franc",
    currencyCode: "CHF",
    countryCode: "LI"
  },
  {
    country: "Syria",
    currencyName: "Syrian pound",
    currencyCode: "SYP",
    countryCode: "SY"
  },
  {
    country: "Taiwan",
    currencyName: "New Taiwan dollar",
    currencyCode: "TWD",
    countryCode: "TW"
  },
  {
    country: "Tajikistan",
    currencyName: "Tajikistani somoni",
    currencyCode: "TJS",
    countryCode: "TJ"
  },
  {
    country: "Tanzania",
    currencyName: "Tanzanian shilling",
    currencyCode: "TZS",
    countryCode: "TZ"
  },
  {
    country: "Thailand",
    currencyName: "Thai baht",
    currencyCode: "THB",
    countryCode: "TH"
  },
  {
    country: "Togo",
    currencyName: "West African CFA franc",
    currencyCode: "XOF",
    countryCode: "BJ"
  },
  {
    country: "Tonga",
    currencyName: "Tongan pa'anga",
    currencyCode: "TOP",
    countryCode: "TO"
  },
  {
    country: "Trinidad and Tobago",
    currencyName: "Trinidad and Tobago dollar",
    currencyCode: "TTD",
    countryCode: "TT"
  },
  {
    country: "Tunisia",
    currencyName: "Tunisian dinar",
    currencyCode: "TND",
    countryCode: "TN"
  },
  {
    country: "Turkey",
    currencyName: "Turkish lira",
    currencyCode: "TRY",
    countryCode: "TR"
  },
  {
    country: "Turkmenistan",
    currencyName: "Turkmenistan manat",
    currencyCode: "TMT",
    countryCode: "TM"
  },
  {
    country: "Tuvalu",
    currencyName: "Australian dollar",
    currencyCode: "AUD",
    countryCode: "AQ"
  },
  {
    country: "Uganda",
    currencyName: "Ugandan shilling",
    currencyCode: "UGX",
    countryCode: "UG"
  },
  {
    country: "Ukraine",
    currencyName: "Ukrainian hryvnia",
    currencyCode: "UAH",
    countryCode: "UA"
  },
  {
    country: "United Arab Emirates",
    currencyName: "United Arab Emirates dirham",
    currencyCode: "AED",
    countryCode: "AE"
  },
  {
    country: "United Kingdom",
    currencyName: "British pound",
    currencyCode: "GBP",
    countryCode: "GG"
  },
  {
    country: "United States of America",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "US"
  },
  {
    country: "Uruguay",
    currencyName: "Uruguayan peso",
    currencyCode: "UYU",
    countryCode: "UY"
  },
  {
    country: "Uzbekistan",
    currencyName: "Uzbekistani som",
    currencyCode: "UZS",
    countryCode: "UZ"
  },
  {
    country: "Vanuatu",
    currencyName: "Vanuatu vatu",
    currencyCode: "VUV",
    countryCode: "VU"
  },
  {
    country: "Vatican City",
    currencyName: "Euro",
    currencyCode: "EUR",
    countryCode: "AX"
  },
  {
    country: "Venezuela",
    currencyName: "Venezuelan bolívar",
    currencyCode: "VEF",
    countryCode: "VE"
  },
  {
    country: "Vietnam",
    currencyName: "Vietnamese dong",
    currencyCode: "VND",
    countryCode: "VN"
  },
  {
    country: "Yemen",
    currencyName: "Yemeni rial",
    currencyCode: "YER",
    countryCode: "YE"
  },
  {
    country: "Zambia",
    currencyName: "Zambian kwacha",
    currencyCode: "ZMW",
    countryCode: "ZM"
  },
  {
    country: "Zimbabwe",
    currencyName: "United States dollar",
    currencyCode: "USD",
    countryCode: "ZWE"
  }
];
export const CURRENCY_SYMBOLS = {
  ZWD: "$",
  ZMW: "ZK",
  ZMK: "ZK",
  ZAR: "R",
  YER: "﷼",
  XPF: "₣",
  XOF: "CFA",
  XCD: "$",
  XAF: "FCFA",
  WST: "WS$",
  VUV: "VT",
  VND: "₫",
  VEF: "Bs",
  UZS: "лв",
  UYU: "$U",
  USD: "US$",
  UGX: "USh",
  UAH: "₴",
  TZS: "TSh",
  TWD: "NT$",
  TTD: "TT$",
  TRY: "₺",
  TOP: "T$",
  TND: "د.ت",
  TMT: "T",
  TMM: "T",
  TJS: "ЅM",
  THB: "฿",
  SZL: "E",
  SYP: "£",
  STD: "Db",
  SSP: "£",
  SRD: "$",
  SOS: "S",
  SLL: "Le",
  SKK: "Sk",
  SHP: "£",
  SGD: "S$",
  SEK: "kr",
  SDG: "SD",
  SCR: "₨",
  SBD: "Si$",
  SAR: "﷼",
  RWF: "FRw",
  RUB: "₽",
  RSD: "Дин.",
  RON: "lei",
  QAR: "﷼",
  PYG: "₲",
  PLN: "zł",
  PKR: "₨",
  PHP: "₱",
  PGK: "K",
  PEN: "S/",
  PAB: "B/.",
  OMR: "﷼",
  NZD: "$",
  NPR: "₨",
  NOK: "kr",
  NIO: "C$",
  NGN: "₦",
  NAD: "$",
  MZN: "MT",
  MYR: "RM",
  MXN: "$",
  MWK: "MK",
  MVR: "Rf",
  MUR: "₨",
  MTL: "₤",
  MRO: "UM",
  MOP: "MOP$",
  MNT: "₮",
  MMK: "K",
  MKD: "ден",
  MGA: "Ar",
  MDL: "L",
  MAD: "DH",
  LYD: "ل.د",
  LVL: "Ls",
  LTL: "Lt",
  LSL: "L",
  LRD: "$",
  LKR: "₨",
  LBP: "£",
  LAK: "₭",
  KZT: "лв",
  KYD: "$",
  KWD: "د.ك",
  KRW: "₩",
  KPW: "₩",
  KMF: "CF",
  KHR: "៛",
  KGS: "лв",
  KES: "KSh,",
  JPY: "¥",
  JOD: "د.ا",
  JMD: "J$",
  ISK: "kr",
  IRR: "﷼",
  IQD: "ع.د",
  INR: "₹",
  ILS: "₪",
  IDR: "Rp",
  HUF: "Ft",
  HTG: "G",
  HRK: "kn",
  HNL: "L",
  HKD: "$",
  GYD: "GY$",
  GTQ: "Q",
  GNF: "FG",
  GMD: "D",
  GIP: "£",
  GHS: "₵",
  GHC: "GH₵",
  GEL: "ლ",
  GBP: "£",
  FKP: "£",
  FJD: "FJ$",
  EUR: "€",
  ETB: "ብር",
  ERN: "ናቕፋ",
  EGP: "£",
  EEK: "EEK",
  DZD: "دج",
  DOP: "RD$",
  DKK: "kr.",
  DJF: "Fdj",
  CZK: "Kč",
  CYP: "£",
  CVE: "$",
  CUC: "$",
  CRC: "₡",
  COP: "$",
  CNY: "¥",
  CLP: "$",
  CHF: "CHF",
  CDF: "FC",
  CAD: "$",
  BZD: "BZ$",
  BYR: "Br",
  BWP: "P",
  BTN: "Nu.",
  BSD: "$",
  BRL: "R$",
  BOB: "b$",
  BND: "$",
  BMD: "$",
  BIF: "FBu",
  BHD: ".د.ب",
  BGN: "лв",
  BDT: "৳",
  BBD: "$",
  BAM: "KM",
  AZN: "₼",
  AWG: "Afl",
  AUD: "$",
  ARS: "$",
  AOA: "Kz",
  ANG: "Naf",
  AMD: "դր",
  ALL: "Lek",
  AFN: "؋",
  AED: "د.إ"
};
export const RAZORPAY_CURRENCIES = [
  "AED",
  "ALL",
  "AMD",
  "ARS",
  "AUD",
  "AWG",
  "BBD",
  "BDT",
  "BMD",
  "BND",
  "BOB",
  "BSD",
  "BWP",
  "BZD",
  "CAD",
  "CHF",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CZK",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ETB",
  "EUR",
  "FJD",
  "GBP",
  "GHS",
  "GIP",
  "GMD",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "JMD",
  "KES",
  "KGS",
  "KHR",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "MAD",
  "MDL",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "QAR",
  "RUB",
  "SAR",
  "SCR",
  "SEK",
  "SGD",
  "SLL",
  "SOS",
  "SSP",
  "SVC",
  "SZL",
  "THB",
  "TTD",
  "TZS",
  "USD",
  "UYU",
  "UZS",
  "YER",
  "ZAR"
];

export const STRIPE_CURRENCIES = [
  "INR",
  "SGD",
  "USD",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CNY",
  "COP",
  "CRC",
  "CVE",
  "CZK",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "GBP",
  "GEL",
  "GIP",
  "GMD",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "ISK",
  "JMD",
  "KES",
  "KGS",
  "KHR",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "MAD",
  "MDL",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRO",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "SAR",
  "SBD",
  "SCR",
  "SEK",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "STD",
  "SZL",
  "THB",
  "TJS",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UYU",
  "UZS",
  "WST",
  "XCD",
  "YER",
  "ZAR",
  "ZMW"
];

export const STRIPE_COUNTRIES = [
  { country: "Australia", code: "AU" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bulgaria", code: "BG" },
  { country: "Brazil ", code: "BR" },
  { country: "Canada", code: "CA" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Denmark", code: "DK" },
  { country: "Estonia", code: "EE" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hong Kong", code: "HK" },
  { country: "Hungary", code: "HU" },
  { country: "India", code: "IN" },
  { country: "Ireland", code: "IE" },
  { country: "Italy", code: "IT" },
  { country: "Japan", code: "JP" },
  { country: "Latvia", code: "LV" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Malaysia", code: "MY" },
  { country: "Malta", code: "MT" },
  { country: "Mexico ", code: "MX" },
  { country: "Netherlands", code: "NL" },
  { country: "New Zealand", code: "NZ" },
  { country: "Norway", code: "NO" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Singapore", code: "SG" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
  { country: "United Kingdom", code: "GB" },
  { country: "United States of America", code: "US" }
];
/**
 * @description This will give the list of currencies from stripe and razorpay
 * @returns {Array} - list of currencies
 */
export const getCommonCurrencies = () => {
  let currencies = [];
  currencies = intersection(STRIPE_CURRENCIES, RAZORPAY_CURRENCIES);
  return currencies;
};
export const getAmountFormattedToPrint = (
  currency: string,
  amount: any,
  amountInPercent: boolean = false
) => {
  return amountInPercent
    ? amount
      ? amount + "%"
      : ""
    : currency + " " + (amount || amount === "" ? numberWithCommas(amount) : 0);
};
export const getAmountFormatted = (currencyCode: string, amount: any) => {
  const country = COUNTRIES_WITH_CURRENCIES.find(
    (currency) => currency.currencyCode === currencyCode
  );
  const symbol = CURRENCY_SYMBOLS[country.currencyCode] || "";
  return (
    (symbol ? symbol : "") +
    " " +
    (amount || amount === "" ? numberWithCommas(amount) : 0)
  );
};
/**
 *
 * @param currencyCode - currency code
 * @returns - currency symbol for given currency code otherwise empty string
 */
export const getCurrencySymbolFromCode = (currencyCode: string) => {
  const country = COUNTRIES_WITH_CURRENCIES.find(
    (currency) => currency.currencyCode === currencyCode
  );
  const symbol = CURRENCY_SYMBOLS[country?.currencyCode] || "";
  return symbol;
};

export const getCountryNameByCountryCode = (countryCode: string) => {
  const countryObject = COUNTRIES_WITH_CURRENCIES.find(
    (country) => country.countryCode === countryCode
  );
  return countryObject?.country || "";
};

export const getCurrencyNameFromCode = (currencyCode: string) => {
  const country = COUNTRIES_WITH_CURRENCIES.find(
    (currency) => currency.currencyCode === currencyCode
  );
  return country?.currencyName;
};
