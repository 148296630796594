import {
  CUSTOM_NUMBER_INPUT_MODULES,
  FORM_ELEMENTS
} from "../../../constants/Constant";

export enum CUSTOM_NUMBER_FORMAT_KEY_NAMES {
  PREVIEW = "preview",
  PREFIX = "prefix",
  PREFIX_SEPRATOR = "prefixSeparator",
  DISPLAY_DIGIT = "displayDigit",
  SUFFIX_SEPRATOR = "suffixSeparator",
  SUFFIX = "suffix"
}

export const SEPRATORS = ["-", "_", "/", "\\", "#", "Blank"];

export const initialCustomDocumentNumberFormValues: CustomNumberState = {
  preview: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREVIEW,
    hidden: false,
    value: "",
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  },
  prefix: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX,
    hidden: false,
    value: "",
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  },
  prefixSeparator: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR,
    hidden: false,
    value: 0,
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  },
  displayDigit: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT,
    hidden: false,
    value: "1",
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true
  },
  suffix: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX,
    hidden: false,
    value: "",
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  },
  suffixSeparator: {
    key: CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR,
    hidden: false,
    value: 0,
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  }
};

export interface CustomNumberState {
  preview: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
  prefix: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
  prefixSeparator: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
  displayDigit: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
  suffix: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
  suffixSeparator: InputTag<CUSTOM_NUMBER_FORMAT_KEY_NAMES>;
}

export interface InputTag<KeyType> {
  value: string | number;
  hidden: boolean;
  key: KeyType;
  type: FORM_ELEMENTS;
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
}

export const defaultCustomNumberFormatPreFixSuffix = (
  module: any,
  sequenceCode: any
) => {
  if (
    [
      CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
      CUSTOM_NUMBER_INPUT_MODULES.DEAL,
      CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
      CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
    ].includes(module) &&
    sequenceCode === "0000000"
  ) {
    return true;
  } else {
    return false;
  }
};

export const generateSequenceCodeWithPrefixSuffix = (sequenceFormat: any) => {
  return `${sequenceFormat.prefix ? sequenceFormat.prefix : ""}${
    sequenceFormat.prefixSeparator ? sequenceFormat.prefixSeparator : ""
  }${sequenceFormat.sequenceCode ? sequenceFormat.sequenceCode : ""}${
    sequenceFormat.suffixSeparator ? sequenceFormat.suffixSeparator : ""
  }${sequenceFormat.suffix ? sequenceFormat.suffix : ""}`;
};
