import ic_urgent from "../assets/icons/ic_urgent.png";
import ic_high from "../assets/icons/ic_high.png";
import ic_normal from "../assets/icons/ic_normal.png";
import ic_low from "../assets/icons/ic_low.png";

export const ENV = {
  DEV: "DEV",
  QA: "QA",
  QA2: "QA2",
  STAGING: "STAGING",
  PROD: "PROD"
};
export const PRODUCTS = {
  AIO: "aio",
  ERP: "erp",
  CRM: "crm",
  CRM_PLUS: "crm_plus",
  PEOPLE: "people",
  WEBSITE_BUILDER: "website builder",
  SHOP: "shop",
  REPORT_BUILDER: "report_builder",
  DOCUMENT_BUILDER: "document builder",
  DOCUMENT_SCANNER: "document scanner",
  ORGANISATION: "organisation",
  CONSOLE: "console",
  WORKFLOW_ENGINE: "workflow_engine",
  ATTENDANCE: "attendance",
  EXPENSE: "expense",
  CHAT: "chat",
  EM: "em", //Expense
  DS: "ds", //Shop
  EL: "el" //Leave Management
};

export enum HTTP_STATUS_CODES {
  SUCCESS = 200,
  NOT_FOUND = 404
}

export enum APP_DOM_SELECTORS {
  CHAT_BUBBLE = "#deskera-chat-root",
  AI_CHAT_BUBBLE = "#deskera-ai-chat-root"
}

export const PLANS = {
  STARTUP: "startup",
  ESSENTIAL: "essential",
  PROFESSIONAL: "professional"
};

export const AUTHORIZATION_STATUS = {
  INITIATE: "INITIATE",
  PENDING: "PENDING",
  DENIED: "DENIED",
  AUTHORIZED: "AUTHORIZED",
  TRIAL_AVAILABLE: "TRIAL_AVAILABLE"
};

export const INTERVALS = {
  YEARLY: "yearly",
  MONTHLY: "monthly"
};

export const ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
  JUSTIFY: "justify"
};

export const FORM_TYPE = {
  BOOK_A_DEMO: "BOOK_A_DEMO",
  SIGN_UP_LEAD_GENERATION: "SIGN_UP_LEAD_GENERATION",
  CONTACT_US: "CONTACT_US"
};

export const API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_201: 201
};

export const USER_ROLE = {
  OWNER: "Owner",
  ADMIN: "Admin",
  MANAGER: "Manager",
  MEMBER: "User"
};
export const USER_TYPE = {
  GUEST: "Guest",
  PAID: "Paid"
};
export const USER_STATUS = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
  PENDING: "Pending"
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: "In trial",
  TRIAL_PENDING: "Trial available",
  EXPIRED: "Expired",
  ACTIVE: "Active",
  TRIAL_ENDED: "Trial over",
  CANCELLED: "Cancelled"
};

export const ACTION_KEY = {
  DELETE_COMAPANY: "delete_company",
  RESET_COMPANY: "reset_company"
};

export const DELETE_OPTIONS = [
  {
    label: "It's too costly",
    value: "It's too costly"
  },
  {
    label: "I found another product that fulfills my needs.",
    value: "I found another product that fulfills my needs."
  },
  {
    label: "I don't use it enough.",
    value: "I don't use it enough."
  },
  {
    label: "Some features I need are missing",
    value: "Some features I need are missing"
  },
  {
    label: "Other",
    value: "Other"
  }
];

export const PASSWORD_OBJ = {
  OLD_PASSWORD: "old password",
  NEW_PASSWORD: "new password",
  CONFIRM_NEW_PASSWORD: "confirm new password"
};

export const PASSWORD_SETTING = {
  SHOW: "show",
  HIDE: "hide"
};

export const PASSWORD_ERROR = {
  ERROR_API: "error: api",
  ERROR_BLANK: "error: blank",
  ERROR_NOT_MATCHED: "error: mismatched",
  ERROR_NOT_MATCHED_FIELD: "error: mismatched other field",
  ERROR_LENGTH: "error: length invalid"
};

export const PLAN_UPDATE_TYPE = {
  TRIAL_START: "TrialStart",
  PLAN_START: "PlanStart",
  UPGRADE: "Upgrade",
  DOWNGRADE: "Downgrade"
};
export const INVOICE_STATUS = {
  PAID: "paid",
  FAILED: "failed"
};

export const CODE_TYPE = {
  PREPAID_CODE: "prepaid",
  PROMO_CODE: "promo / discount",
  PLAN_CODE: "plan",
  ADDON_CODE: "addon",
  COUPON_CODE: "coupon"
};

export const WEBSITE_TYPE = {
  HELP_DESK: "helpdesk",
  BUILDER: "builder"
};

export const CHART_TYPE = {
  BAR_VERTICAL: {
    id: "BAR_VERTICAL",
    name: "Vertical Bar Graph"
  },
  MULTI_BAR_VERTICAL: {
    id: "MULTI_BAR_VERTICAL",
    name: "Vertical Multi-Bar Graph"
  },
  BAR_HORIZONTAL: {
    id: "BAR_HORIZONTAL",
    name: "Horizontal Bar Graph"
  },
  MULTI_BAR_HORIZONTAL: {
    id: "MULTI_BAR_HORIZONTAL",
    name: "Horizontal Multi-Bar Graph"
  },
  LINE: {
    id: "LINE",
    name: "Line Chart"
  },
  DOUGHNUT: {
    id: "DOUGHNUT",
    name: "Doughnut Chart"
  }
};

export const CHART_API_SOURCES = {
  INVOICES: "INVOICES",
  AGE_RECEIVABLE: "AGE_RECEIVABLE",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  WON_AND_LOST_DEALS: "WON_AND_LOST_DEALS",
  PIPELINES: "PIPELINES",
  CONTACTS: "CONTACTS",
  EXPENSES_CLAIMED: "EXPENSES_CLAIMED",
  PAYROLL: "PAYROLL",
  LEAVES_PER_MONTH: "LEAVES_PER_MONTH"
};
export const VISIBILITY_TYPE = {
  VISIBLITY_1: "Private",
  VISIBLITY_2: "Team",
  VISIBLITY_3: "Everyone"
};
export const DEAL_STATUS_CODE = {
  DEFAULT: 0,
  WON: 1,
  LOST: 2
};
export const ACTIVITY_VIEW = {
  CALENDAR: "Calendar",
  GRID: "Grid"
};
export const ACTIVITY_STATUS_CODE = {
  COMPLETED: 1,
  PENDING: 2,
  OVERDUE: 3
};
export const VISIBILITY = [
  { name: "Private", id: 1 },
  { name: "Team", id: 2 },
  { name: "Everyone", id: 3 }
];
export const CONTACT_TYPE = {
  PERSON: 1,
  ORGANIZATION: 2
};

export enum DEAL_CONTEXT_MENU_KEYS {
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
  DEAL_WON = "DEAL_WON",
  DEAL_LOST = "DEAL_LOST",
  ATTACHMENT = "ATTACHMENT",
  DELETE = "DELETE",
  ASSIGN_OWNER = "ASSIGN_OWNER",
  ASSIGN_SUB_OWNER = "ASSIGN_SUB_OWNER",
  ASSIGN_TEAM = "ASSIGN_TEAM"
}

export const MESSAGE_TYPE = {
  TEXT: "text",
  MULTIMEDIA: "multimedia"
};
export const FILE_TYPE = {
  IMAGE: "img",
  PDF: "pdf",
  DOC: "doc",
  EXCEL: "excel",
  POWERPOINT: "powerpoint",
  FILE: "file"
};
export enum NOTIFICATION_ACTION_TYPE {
  REC_CREATE = "REC_CREATE",
  REC_UPDATE = "REC_UPDATE",
  REC_DELETE = "REC_DELETE",
  REC_BULK_DELETE = "REC_BULK_DELETE",
  REC_BULK_CREATE = "REC_BULK_CREATE",
  REC_BULK_UPDATE = "REC_BULK_UPDATE",
  REC_IMPORT = "REC_IMPORT",
  NEW_CHAT_MESSAGE = "NEW_CHAT_MESSAGE",
  REC_PERMISSION_CHANGED = "REC_PERMISSION_CHANGED",
  TABLE_PERMISSION_CHANGED = "TABLE_PERMISSION_CHANGED",
  SEGMENT_CREATE = "SEGMENT_CREATE",
  NOTE_CREATE = "NOTE_CREATE",
  NOTE_DELETED = "NOTE_DELETED",
  NOTE_UPDATED = "NOTE_UPDATED",
  REC_UPDATE_REJECTED = "REC_UPDATE_REJECTED",
  REC_CREATE_REJECTED = "REC_CREATE_REJECTED",
  REC_BULK_CREATE_REJECTED = "REC_BULK_CREATE_REJECTED",
  REC_BULK_UPDATE_REJECTED = "REC_BULK_UPDATE_REJECTED",
  OWNER_ASSIGNED = "OWNER_ASSIGNED",
  REQUIRED_COLUMN_SETTING_CHANGED = "REQUIRED_COLUMN_SETTING_CHANGED",
  REC_LINKED = "REC_LINKED",
  REC_UNLINKED = "REC_UNLINKED",
  ATTACHMENT_CREATE = "ATTACHMENT_CREATE",
  ATTACHMENT_DELETE = "ATTACHMENT_DELETE",
  ATTACHMENT_UPDATE = "ATTACHMENT_UPDATE",
  NOTE_DELETE = "NOTE_DELETE",
  NOTE_UPDATE = "NOTE_UPDATE",
  SUPPORT_EMAIL_CREATE = "SUPPORT_EMAIL_CREATE",
  SUPPORT_EMAIL_DELETE = "SUPPORT_EMAIL_DELETE",
  CRM3_RECORD_APPROVAL_REQUEST_CREATED = "CRM3_RECORD_APPROVAL_REQUEST_CREATED",
  CRM3_RECORD_APPROVAL_REQUEST_UPDATED = "CRM3_RECORD_APPROVAL_REQUEST_UPDATED",
  CRM3_RECORD_APPROVAL_REQUEST_DELETED = "CRM3_RECORD_APPROVAL_REQUEST_DELETED",
  COLUMN_CREATE = "COLUMN_CREATE",
  COLUMN_DELETE = "COLUMN_DELETE",
  COLUMN_UPDATE = "COLUMN_UPDATE"
}
export const TICKET_TYPES = [
  "Incident/Support Request",
  "Technical Query",
  "Suspected Hardware Failure",
  "Question",
  "Task",
  "Problem"
];
export const TICKET_PRIORITIES = [
  { name: "Blocker", icon: ic_urgent },
  { name: "Critical", icon: ic_high },
  { name: "Major", icon: ic_normal },
  { name: "Minor", icon: ic_low }
];
export const TICKET_STATUS = [
  "Waiting for Support",
  "In Progress",
  "Monitoring",
  "Waiting for Customer",
  "Fix Available",
  "Resolved",
  "Closed",
  "Open"
];
export const ALL_TICKET_FILTER = "All";
export enum CHAT_BUBBLE_POSITION {
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  TOP_RIGHT = "TOP_RIGHT",
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_LEFT = "BOTTOM_LEFT"
}
export enum TICKET_VALUES_NAME {
  STATUS = "status",
  CONTACT = "crm3_contact_id",
  PRIORITY = "priority",
  ACCOUNT = "crm3_account_id",
  REQUESTOR = "requestor"
}
export const TICKET_SORTING_OPTION = [
  { name: "Priority", value: "priority" },
  { name: "Status", value: "status" },
  { name: "Waiting For Support", value: "custom_sort" },
  { name: "Requestor", value: "requestor" },
  { name: "Ticket No", value: "ticket_no" },
  { name: "Type", value: "type" },
  { name: "Updated At", value: "updated_at" },
  { name: "Email", value: "email" },
  { name: "Subject", value: "subject" }
];

export enum LOCAL_STORAGE_KEYS {
  SELECTED_PIPELINE = "selectedPipeline",
  SELECTED_ACTIVITY_VIEW = "selectedActivityView",
  ACTIVITY_LIST_VIEW = "activityListView",
  IS_SIDE_BAR_EXPANDED = "isSideBarExpanded",
  CAMPAIGN_CONTACT_ADDRESS = "contactAddress",
  CALENDAR_VIEW_INDEX = "calendarViewIndex",
  SELECTED_DEAL_DAY_FILTER = "selectedDealDayFilter",
  SELECTED_DEAL_VIEW = "selectedDealView",
  NEW_EMAIL_AUTOMATION_CAMPAIGN = "newEmailAutomationCampaign",
  NEW_EMAIL_AUTOMATION_LANDING_PAGE = "newEmailAutomationLandingPage",
  SELECTED_ACCOUNT_VIEW = "selectedAccountView"
}
export enum TICKET_EVENT_TYPES {
  TICKET = "TICKET",
  SUPPORT_EMAIL = "SUPPORT_EMAIL"
}

export enum FILTER_OPERATORS {
  EQUAL = "eq",
  NOT_EQUAL = "neq",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUAL = "gte",
  GREATER_EQUAL = "gteq" /* only used in automation for dates */,
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUAL = "lte",
  CONTAINS = "c",
  IN = "in",
  IS = "is",
  NOT_CONTAINS = "nc"
}
export enum BOOKS_CUSTOM_FIELD_OPERATORS {
  eq = "=",
  gt = ">",
  lt = "<",
  sw = "a_",
  ew = "_a",
  c = "_a_"
}

export enum FILTER_LOGICAL_OPERATORS {
  AND = "and",
  OR = "or"
}
export enum AUTOMATION_LOG_NODE_TYPE {
  CREATE_DEAL = "CREATE_DEAL",
  CREATE_CONTACT = "CREATE_CONTACT",
  UPDATE_DEAL = "UPDATE_DEAL",
  UPDATE_CONTACT = "UPDATE_CONTACT",
  SEND_EMAIL = "SEND_EMAIL",
  SPLIT = "SPLIT",
  WAIT_FOR_TIME = "WAIT_FOR_TIME",
  // SEND_SMS_MESSAGE = "SEND_SMS_MESSAGE",
  SEND_WHATSAPP_MESSAGE = "SEND_WHATSAPP_MESSAGE",
  ASSIGN_OWNER = "ASSIGN_OWNER",
  END_WORKFLOW = "END_WORKFLOW",
  CAMPAIGN_PUBLISHED = "CAMPAIGN_PUBLISHED",
  FORM_SUBMITTED = "FORM_SUBMITTED",
  CONTACT_ADDED = "CONTACT_ADDED",
  CONTACT_UPDATED = "CONTACT_UPDATED",
  FACEBOOK_LEAD_ADDED = "FACEBOOK_LEAD_ADDED",
  DEAL_UPDATED = "DEAL_UPDATED",
  DEAL_STAGE_CHANGED = "DEAL_STAGE_CHANGED",
  ACTIVITY_UPDATED = "ACTIVITY_UPDATED",
  CRM_DATE_ALERT_TRIGGER = "CRM_DATE_ALERT_TRIGGER",
  BOOKS_CREATE_QUOTATION = "BOOKS_CREATE_QUOTATION",
  BOOKS_PLUS_APPROVAL = "BOOKS_PLUS_APPROVAL",
  DEAL_UPDATE_APPROVAL = "DEAL_UPDATE_APPROVAL",
  UPDATE_TICKET = "UPDATE_TICKET",
  TICKET_CREATED = "TICKET_CREATED",
  TICKET_UPDATED = "TICKET_UPDATED"
}
export enum AUTOMATION_ACTIONS_TITLE {
  SEND_EMAIL = "Send an e-mail",
  CREATE_CONTACT = "Create a contact",
  UPDATE_CONTACT = "Update a contact",
  CREATE_DEAL = "Create a deal",
  UPDATE_DEAL = "Update a deal",
  SPLIT = "Split Path",
  WAIT_FOR_TIME = "Wait for (time)",
  // SEND_SMS_MESSAGE = "Send a text message",
  SEND_WHATSAPP_MESSAGE = "Send a whatsapp message",
  // CRM_DATE_ALERT_TRIGGER = "Date alert trigger",
  ASSIGN_OWNER = "Assign owner",
  BOOKS_PLUS_APPROVAL = "Send for approval",
  BOOKS_CREATE_QUOTATION = "Quotation created",
  BOOKS_UPDATE_QUOTATION = "Quotation updated",
  BOOKS_REJECT_QUOTATION = "Quotation rejected",
  UPDATE_TICKET = "Ticket updated",
  TICKET_CREATED = "Ticket created",
  TICKET_UPDATED = "Ticket updated"
}
export enum AUTOMATION_TRIGGER_TITLE {
  CAMPAIGN_PUBLISHED = "Campaign is published",
  FORM_SUBMITTED = "Form submitted",
  CONTACT_ADDED = "Contact added",
  CONTACT_UPDATED = "Contact field changed",
  // FACEBOOK_LEAD_ADDED = "Facebook lead added",
  DEAL_UPDATED = "Deal field changed",
  DEAL_STAGE_CHANGED = "Deal stage changed",
  ACTIVITY_UPDATED = "Activity field changed",
  CRM_DATE_ALERT_TRIGGER = "Date alert trigger",
  BOOKS_CREATE_QUOTATION = "Quotation created",
  BOOKS_UPDATE_QUOTATION = "Quotation updated",
  BOOKS_REJECT_QUOTATION = "Quotation rejected",
  CRM_DEAL_APPROVAL = "Send for Approval",
  TICKET_CREATED = "Ticket created",
  TICKET_UPDATED = "Ticket field changed"
}
export enum AUDIT_ACTIONS_TITLE {
  REC_CREATE = "is created",
  REC_UPDATE = "is updated",
  REC_DELETE = "is deleted",
  REC_BULK_CREATE = "are created",
  REC_BULK_UPDATE = "are updated",
  REC_BULK_DELETE = "are deleted",
  REC_PERMISSION_CHANGED = "permission changed",
  TABLE_PERMISSION_CHANGED = "permission changed",
  SEGMENT_CREATE = "Segment is created",
  REC_UPDATE_REJECTED = "update is rejected due to duplication",
  REC_CREATE_REJECTED = "create is rejected due to duplication",
  REC_BULK_CREATE_REJECTED = "create is rejected due to duplication",
  REC_BULK_UPDATE_REJECTED = "update is rejected due to duplication",
  OWNER_ASSIGNED = "OWNER_ASSIGNED",
  REQUIRED_COLUMN_SETTING_CHANGED = "Required Fields setting changed",
  REC_LINKED = "is linked",
  REC_UNLINKED = "is unlinked",
  NOTE_CREATE = "is created",
  NOTE_UPDATE = "is updated",
  NOTE_DELETE = "is deleted",
  ATTACHMENT_CREATE = "is created",
  ATTACHMENT_UPDATE = "is updated",
  ATTACHMENT_DELETE = "is deleted",
  SUPPORT_EMAIL_CREATE = "is created",
  SUPPORT_EMAIL_DELETE = "is deleted",
  COLUMN_CREATE = "is created",
  COLUMN_UPDATE = "is updated",
  COLUMN_DELETE = "is deleted"
}
export enum AUDIT_ACTIONS_FILTER {
  REC_CREATE = "Record created",
  REC_UPDATE = "Record updated",
  REC_UPDATE_REJECTED = "Record created is rejected",
  REC_CREATE_REJECTED = "Record updated is rejected",
  REC_DELETE = "Record deleted",
  REC_BULK_CREATE = "Bulk record created",
  REC_BULK_UPDATE = "Bulk record updated",
  REC_BULK_CREATE_REJECTED = "Bulk record created is rejected",
  REC_BULK_UPDATE_REJECTED = "Bulk record updated is rejected",
  REC_BULK_DELETE = "Bulk record deleted",
  SEGMENT_CREATE = "Segment is created",
  REQUIRED_COLUMN_SETTING_CHANGED = "Required Fields setting changed"
}
export enum AUDIT_OBJECT_TYPE {
  ACCOUNT = "Account",
  ACTIVITY = "Activity",
  CAMPAIGN = "Campaign",
  CONTACT = "Contact",
  DEAL = "Deal",
  FORM = "Form",
  LANDING_PAGE = "Landing Page",
  PIPELINE = "Pipeline",
  STAGE = "Stage",
  AUTOMATION = "Automation",
  ORDER = "Order",
  CHAT_THREAD = "Chat Thread",
  CHAT_MESSAGE = "Chat Message",
  TICKET = "Ticket",
  SUPPORT_EMAIL = "Support Email",
  QUOTE = "Quote",
  SALES_INVOICE = "Invoice"
}
export enum AUDIT_BULK_OBJECT_TYPE {
  ACCOUNT = "Accounts",
  ACTIVITY = "Activities",
  CAMPAIGN = "Campaigns",
  CONTACT = "Contacts",
  DEAL = "Deals",
  FORM = "Forms",
  LANDING_PAGE = "Landing Pages",
  PIPELINE = "Pipelines",
  STAGE = "Stages",
  FUNNEL = "Funnels",
  AUTOMATION = "Automations",
  ORDER = "Orders",
  CHAT_THREAD = "Chat Threads",
  CHAT_MESSAGE = "Chat Messages",
  TICKET = "Tickets",
  SUPPORT_EMAIL = "Support Emails",
  ORGANIZATION = "Organizations",
  QUOTE = "Quotes",
  SALES_INVOICE = "Invoices"
}
export enum OBJECT_TYPE {
  ACTIVITY = "ACTIVITY",
  CAMPAIGN = "CAMPAIGN",
  CONTACT = "CONTACT",
  DEAL = "DEAL",
  FORM = "FORM",
  LANDING_PAGE = "LANDING_PAGE",
  PIPELINE = "PIPELINE",
  STAGE = "STAGE",
  FUNNEL = "FUNNEL",
  AUTOMATION = "AUTOMATION",
  ORDER = "ORDER",
  CHAT_THREAD = "CHAT_THREAD",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  TICKET = "TICKET",
  SUPPORT_EMAIL = "SUPPORT_EMAIL",
  ORGANIZATION = "ORGANIZATION",
  QUOTE = "QUOTE",
  INVOICE = "SALES_INVOICE",
  CUSTOM_FIELD = "CUSTOM_FIELD"
}
export enum REPORTS_TAB {
  STANDARD = "standard",
  CUSTOM = "custom"
}
export enum FILTER_CONDITION {
  EQUALS = "EQUALS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_EQUALS = "LESS_THAN_EQUALS"
}
export enum THREAD_TYPE {
  OPEN = 0,
  CLOSED = 1
}

export enum PREFIXES {
  CELLS = "cells."
}
export enum FILE_INPUT_TYPE {
  FILE = "file"
}
export enum TAX_RESIDENCY {
  AE = "ae" /* uae */,
  ID = "id" /* indonesia */,
  IN = "in" /* india */,
  MY = "my" /* malaysia */
}

export enum APP_NAME {
  CRM_3 = "CRM3",
  ERP = "ERP",
  MRP = "MRP"
}

export enum CONTACT_DETAIL_TAB {
  EMAIL = "contactEmail",
  TICKET = "tickets",
  DEAL = "deals",
  ACTIVITY = "activities",
  QUOTES = "quotes",
  INVOICES = "invoices",
  NOTES = "notes",
  ATTACHMENTS = "Attachments",
  LOGS = "logs"
}
export enum DEAL_DETAIL_TAB {
  ACTIVITY = "activity",
  CONTACTS = "contact",
  QUOTES = "quotes",
  INVOICES = "invoices",
  NOTES = "notes",
  ATTACHMENTS = "attachments",
  LOGS = "logs"
}
export enum ACCOUNT_DETAIL_TAB {
  EMAIL = "Email",
  DEAL = "Deals",
  TICKET = "Tickets",
  ACTIVITY = "Activities",
  QUOTES = "Quotes",
  INVOICES = "Invoices",
  NOTES = "Notes",
  ATTACHMENTS = "Attachments",
  LOGS = "Logs"
}

export enum QUERY_PARAM_KEY {
  CONTACT_DETAIL_TAB = "activeTab"
}
export enum DATE_RNGE_FILTER_OPTION {
  ALL_DATA = "All Data",
  THIS_WEEK = "This Week",
  THIS_MONTH = "This Month",
  THIS_QUARTER = "This Quarter",
  THIS_YEAR = "This Year",
  CUSTOM_DATE = "Custom Date"
}

export enum CAMPAIGN_TYPE {
  EMAIL = 1,
  WHATSAPP = 2
}

export enum CAMPAIGN_STATUS {
  DRAFT = 1,
  RUNNING = 2,
  SENT = 3,
  SCHEDULED = 4,
  CANCELED = 5,
  FAILED = 6,
  SCHEDULED_NOW = 7,
  PARTIALLY_SENT = 8
}

export enum ACCOUNT_ACTION_TYPE {
  EDIT = "EDIT",
  OPEN = "OPEN",
  ASSIGN_OWNER = "ASSIGN_OWNER",
  ASSIGN_SUB_OWNER = "ASSIGN_SUB_OWNER",
  ASSIGN_TEAM = "ASSIGN_TEAM",
  CREATE_QUOTE = "CREATE_QUOTE",
  CREATE_INVOICE = "CREATE_INVOICE",
  DELETE = "DELETE"
}

export enum INDIA_MART_LEAD_DATA_KEYS {
  UNIQUE_QUERY_ID = "UNIQUE_QUERY_ID",
  QUERY_TYPE = "QUERY_TYPE",
  QUERY_TIME = "QUERY_TIME",
  SENDER_NAME = "SENDER_NAME",
  SENDER_MOBILE = "SENDER_MOBILE",
  SENDER_EMAIL = "SENDER_EMAIL",
  SUBJECT = "SUBJECT",
  SENDER_COMPANY = "SENDER_COMPANY",
  SENDER_ADDRESS = "SENDER_ADDRESS",
  SENDER_CITY = "SENDER_CITY",
  SENDER_STATE = "SENDER_STATE",
  SENDER_PINCODE = "SENDER_PINCODE",
  SENDER_COUNTRY_ISO = "SENDER_COUNTRY_ISO",
  SENDER_MOBILE_ALT = "SENDER_MOBILE_ALT",
  SENDER_PHONE = "SENDER_PHONE",
  SENDER_PHONE_ALT = "SENDER_PHONE_ALT",
  SENDER_EMAIL_ALT = "SENDER_EMAIL_ALT",
  QUERY_PRODUCT_NAME = "QUERY_PRODUCT_NAME",
  QUERY_MCAT_NAME = "QUERY_MCAT_NAME",
  QUERY_MESSAGE = "QUERY_MESSAGE",
  CALL_DURATION = "CALL_DURATION",
  RECEIVER_MOBILE = "RECEIVER_MOBILE"
}

export enum JUST_DIAL_LEAD_DATA_KEYS {
  NAME = "name",
  MOBILE = "mobile",
  EMAIL = "email",
  CATEGORY = "category",
  AREA = "area",
  CITY = "city",
  PINCODE = "pincode",
  PHONE = "phone",
  COMPANY = "company",
  BRANCH_AREA = "brancharea",
  BRANCH_PINCODE = "branchpin",
  DATE = "date"
}

export enum APPROVAL_STATUS {
  EDITING = "EDITING",
  PENDING_FOR_APPROVAL = "PENDING_FOR_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  NOT_REQUIRED = "NOT_REQUIRED",
  DELETE = "DELETE",
  PULLED_BACK = "PULLED_BACK"
}

export enum TEMPLATE_MODULE_ID {
  TICKET = 1,
  CONTACT = 2,
  DEAL = 3,
  ACTIVITY = 4
}
export enum PIPELINE_CONTEXT_MENU_KEYS {
  EDIT = "EDIT",
  DELETE = "DELETE",
  ASSIGN_OWNER = "ASSIGN_OWNER",
  ASSIGN_SUB_OWNER = "ASSIGN_SUB_OWNER",
  ASSIGN_TEAM = "ASSIGN_TEAM",
  ASSIGN_DEFAULT_PIPELINE = "ASSIGN_DEFAULT_PIPELINE"
}

export enum DUPLICATE_CHECK_OPERATORS {
  AND = "and",
  OR = "or"
}

export enum DUPLICATE_CHECK_ACTIONS {
  UPDATE = "UPDATE",
  REJECT = "REJECT"
}
export enum LOGS_TAB {
  LEAD_SYNC = "leadSyncLogs",
  AUTOMATION = "automationLogs",
  AUDIT = "auditLogs",
  IMPORT = "importLogs",
  PRICE_BOOK_LOGS = "priceBookLogs"
}

export const COMPOSITION_TAX_PERCENT: any = {
  ONE_PERCENT: 1,
  TWO_PERCENT: 2,
  FIVE_PERCENT: 5,
  SIX_PERCENT: 6
};

export enum GST_TYPE {
  DEFAULT,
  INTER,
  INTRA,
  EXEMPT
}
export enum COMPLIANCE_SPECIFIC_FIELD_NAME {
  CONTACT = "CONTACT",
  PRODUCT = "PRODUCT",
  INVOICE = "INVOICE",
  QUOTATION = "QUOTATION",
  QUOTE = "QUOTE"
}
export enum PRODUCT_TYPE {
  TRACKED = "TRACKED",
  NON_TRACKED = "NONTRACKED",
  BILL_OF_MATERIALS = "BILL_OF_MATERIALS"
}

export enum PRODUCT_TRANSACTION_TYPE {
  PURCHASE = "PURCHASE",
  SALES = "SALES",
  BOTH = "BOTH"
}

export const TICKET_FIELD_VISIBLE = {
  crm3_contact_id: "Linked Contact",
  crm3_account_id: "Linked Account",
  updated_at: "Updated At",
  assignee: "Assignee",
  note: "Note",
  resolved_at: "Resolved Date",
  closed_at: "Closed Date",
  team_id: "Teams"
};

export const TICKET_FIELD_KEYS_WITH_DETAIL = {
  CUSTOM_FIELD: "custom_field",
  OTHER_REQUESTORS: "other_requestors",
  ASSIGNEE_DETAIL: "assignee_detail",
  CREATED_BY_DETAIL: "created_by_detail",
  UPDATED_BY_DETAIL: "updated_by_detail",
  CRM3_ACCOUNT_ID_DETAIL: "crm3_account_id_detail",
  CRM3_CONTACT_ID_DETAIL: "crm3_contact_id_detail",
  C_FIELD: "c_field",
  UPDATED_AT: "updated_at",
  CRM3_CONTACT_ID: "crm3_contact_id",
  CRM3_ACCOUNT_ID: "crm3_account_id",
  ASSIGNEE: "assignee",
  COMPONENT_ID_DETAIL: "component_id_detail",
  OWNER_ID_DETAIL: "owner_id_detail",
  NOTE: "note",
  RESOLVED_AT: "resolved_at",
  RESOLVED_BY_DETAIL: "resolved_by_detail",
  RESOLVED_BY: "resolved_by",
  EMAIL: "email",
  UPDATED_BY: "updated_by",
  OWNER_ID: "owner_id",
  CREATED_BY: "created_by",
  CREATED_AT: "created_at",
  CLOSED_AT: "closed_at",
  CLOSED_BY: "closed_by",
  STATUS: "status",
  PRIORITY: "priority",
  TYPES: "type",
  SRC_WF_ID: "src_wf_id",
  SRC_WF_NODE_ID: "src_wf_node_id",
  SRC_WF_NODE_EXEC_AUDIT_ID: "src_wf_node_exec_audit_id",
  TEAM_ID: "team_id"
};

export const QUOTE_AUDIT_LOG_FIELDS = {
  APPROVAL_STATUS: "approval_status",
  MULTI_APPROVAL_DETAILS: "multi_approval_details",
  ADDITIONAL_CHARGES: "additional_charges",
  CURRENCY: "currency",
  CUSTOM_FIELD: "custom_field",
  CUSTOMER_INFO: "customer_info",
  DOCUMENT_CODE: "document_seq_code",
  DOCUMENT_DATE: "document_dt",
  PRICE_LIST: "price_list_name",
  SHIP_BY_DATE: "shipby_dt",
  VALID_TILL_DATE: "valid_till_dt",
  TOTAL_AMOUNT: "total_amount_in_base_currency",
  LOCKED: "locked_flg",
  MEMO: "memo",
  STATUS: "status",
  EDITING_LOCKED: "editing_locked",
  EDITING_BY: "editing_by",
  EDITING_AT: "editing_at"
};

export const AUDIT_LOG_EMPTY_DISPLAY_VALUE = "None";

export enum ADDITIONAL_CHARGES_TYPE {
  BOTH = "BOTH",
  SELL = "SELL",
  BUY = "BUY"
}

export enum PRODUCT_OFFERING_TYPE {
  GOODS = "GOODS",
  SERVICES = "SERVICES"
}

export enum TAX_TYPES {
  PURCHASE = "PURCHASE",
  SELL = "SELL",
  BOTH = "BOTH",
  SALES = "SALES"
}

export enum ACCOUNT_GROUPS {
  EXPENSES = "Expenses",
  INCOME = "Income",
  OTHER_INCOME = "Other Income",
  OTHER_EXPENSES = "Other Expenses",
  COST_OF_GOODS_SOLD = "Cost of Goods Sold",
  OPERATING_EXPENSES = "Operating Expenses",
  CURRENT_ASSETS = "Current Assets",
  OTHER_ASSETS = "Other Assets",
  OTHER_CURRENT_ASSETS = "Other Current Assets",
  REVENUE = "Revenue"
}

export const ADDITIONAL_CHARGE_INCOME_GROUPS = [
  ACCOUNT_GROUPS.INCOME,
  ACCOUNT_GROUPS.OTHER_INCOME,
  ACCOUNT_GROUPS.CURRENT_ASSETS,
  ACCOUNT_GROUPS.OTHER_ASSETS,
  ACCOUNT_GROUPS.OTHER_CURRENT_ASSETS,
  ACCOUNT_GROUPS.REVENUE,
  ACCOUNT_GROUPS.OTHER_INCOME
];

export const ADDITIONAL_CHARGE_EXPENSE_GROUPS = [
  ACCOUNT_GROUPS.EXPENSES,
  ACCOUNT_GROUPS.COST_OF_GOODS_SOLD,
  ACCOUNT_GROUPS.OPERATING_EXPENSES,
  ACCOUNT_GROUPS.OTHER_EXPENSES
];

export enum PRICE_BOOK_PERMISSION_ERROR_CODE {
  UNAUTHORIZED = 403,
  DEFAULT = 400
}

export enum QUOTE_DOC_KEYS {
  PRODUCT = "product",
  PRODUCT_QUANTITY = "productQuantity",
  TAX_AMOUNT = "taxAmount",
  TAX = "tax",
  QUANTITY = "productQuantity",
  UOM = "uom",
  UNIT_PRICE = "unitPrice",
  UPLIFT_PRICE = "upliftPrice",
  DOWNLIFT_PRICE = "downliftPrice",
  DISCOUNT_IN_PERCENT = "isDiscountInPercentage",
  DISCOUNT = "discount",
  OPTIONAL = "optional",
  UNIT_DISCOUNT = "unitDiscount"
}

export enum PRODUCT_COLUMN_CODES {
  BARCODE = "barcode",
  ATTACHMENT = "attachments",
  OUTGOING = "outgoing",
  REORDER = "reorder",
  PRODUCT_GROUP_IDS = "productGroupIds",
  STATUS = "status",
  ACTIVE = "active",
  TYPE = "type"
}

export enum AUDIT_LOG_RECORD_ID_PREFIX {
  TICKET = "TK",
  TICKET_NOTE = "TK_NT",
  TICKET_SUPPORT_EMAIL = "TK_SE",
  TICKET_ATTACHMENT = "TK_AT"
}

export enum CUSTOM_INPUT_TYPES {
  JSON = "json",
  JSON_ARRAY = "json_array"
}
