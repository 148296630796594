import {
  DKLabel,
  Toggle,
  showAlert,
  DKIcon,
  DKIcons,
  DKTooltipWrapper
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import {
  CUSTOM_NUMBER_INPUT_MODULES,
  SETTING_MODULE,
  TenantInfoSeqCodeStatus
} from "../../constants/Constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import Popup from "../common/Popup";
import CustomNumberFormat from "../books/custom_number/CustomNumberFormat";
import { fetchTables } from "../../redux/slices/tableSlice";

export interface ISequenceCodeSettings {
  // onClose: () => void;
}

export default function SequenceCodeSettings(props: ISequenceCodeSettings) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const [contactSeqCodeToggle, setContactSeqCodeToggle] = useState(false);
  const [autoNumberPopupToggle, setAutoNumberPopupToggle] = useState(false);
  const [dealSeqCodeToggle, setDealSeqCodeToggle] = useState(false);
  const [accountSeqCodeToggle, setAccountSeqCodeToggle] = useState(false);
  const [activitySeqCodeToggle, setActivitySeqCodeToggle] = useState(false);
  const [selectedModule, setSelectedModule] = useState("");
  const settingPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, SETTING_MODULE)
  );

  const saveSequenceCodeSettings = async (table: string) => {
    try {
      let payload = {};
      if (table === CUSTOM_NUMBER_INPUT_MODULES.CONTACT) {
        payload = {
          contactSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === CUSTOM_NUMBER_INPUT_MODULES.DEAL) {
        payload = {
          dealSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT) {
        payload = {
          accountSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY) {
        payload = {
          activitySeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        showAlert(
          "Updated successfully",
          "Sequence numbering enabled successfully. Please wait a few seconds for all records to update."
        );
        dispatch(fetchCRMSettings({}));
        dispatch(fetchTables());
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    setContactSeqCodeToggle(
      [
        TenantInfoSeqCodeStatus.ACTIVE,
        TenantInfoSeqCodeStatus.IN_PROGRESS
      ].includes(crmSettings?.contactSeqCodeStatus)
    );
    setAccountSeqCodeToggle(
      [
        TenantInfoSeqCodeStatus.ACTIVE,
        TenantInfoSeqCodeStatus.IN_PROGRESS
      ].includes(crmSettings?.accountSeqCodeStatus)
    );
    setDealSeqCodeToggle(
      [
        TenantInfoSeqCodeStatus.ACTIVE,
        TenantInfoSeqCodeStatus.IN_PROGRESS
      ].includes(crmSettings?.dealSeqCodeStatus)
    );
    setActivitySeqCodeToggle(
      [
        TenantInfoSeqCodeStatus.ACTIVE,
        TenantInfoSeqCodeStatus.IN_PROGRESS
      ].includes(crmSettings?.activitySeqCodeStatus)
    );
  }, []);

  const onSave = () => {
    switch (selectedModule) {
      case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
        setContactSeqCodeToggle(true);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
        setAccountSeqCodeToggle(true);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.DEAL:
        setDealSeqCodeToggle(true);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY:
        setActivitySeqCodeToggle(true);
        break;
    }
    saveSequenceCodeSettings(selectedModule);
  };

  const getAutoNumberSection = () => {
    return (
      <Popup
        title=""
        allowClose={false}
        onClose={() => {}}
        popupWindowStyles={{
          padding: 15
        }}
      >
        <div className="parent-width row flex-wrap justify-content-between align-items-start mb-m">
          <CustomNumberFormat
            data={""}
            isDefaultForSetting={true}
            successMsg={true}
            documentType={selectedModule}
            onClose={() => setAutoNumberPopupToggle(false)}
            onSave={() => {
              onSave();
              setAutoNumberPopupToggle(false);
            }}
          />
        </div>
      </Popup>
    );
  };

  const getInfoIcon = () => {
    return (
      <DKTooltipWrapper
        content={`Please wait a few seconds for all records to update`}
      >
        <DKIcon
          src={DKIcons.ic_info}
          className="ic-xs cursor-hand p-h-s"
          style={{ opacity: 0.75 }}
        />
      </DKTooltipWrapper>
    );
  };

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between mb-s">
          <div className="column">
            <DKLabel
              text={`Enable Sequence Code`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text="You cannot disable sequence code once it is enabled"
            />
          </div>
          <div className="ml-l"></div>
        </div>
        {settingPermissions[USER_ACTION_TYPES.CONTACT_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel text={`Contact`} className="mb-xs fw-m" />
              {crmSettings?.contactSeqCodeStatus ===
                TenantInfoSeqCodeStatus.IN_PROGRESS && getInfoIcon()}
            </div>
            <div className="ml-l">
              <Toggle
                isOn={contactSeqCodeToggle}
                onChange={() => {
                  if (!contactSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setSelectedModule(
                              CUSTOM_NUMBER_INPUT_MODULES.CONTACT
                            );
                            setAutoNumberPopupToggle(true);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={contactSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.ACCOUNT_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel text={`Account`} className="mb-xs fw-m" />
              {crmSettings?.accountSeqCodeStatus ===
                TenantInfoSeqCodeStatus.IN_PROGRESS && getInfoIcon()}
            </div>
            <div className="ml-l">
              <Toggle
                isOn={accountSeqCodeToggle}
                onChange={() => {
                  if (!accountSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setSelectedModule(
                              CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT
                            );
                            setAutoNumberPopupToggle(true);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={accountSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.DEAL_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel text={`Deal`} className="mb-xs fw-m" />
              {crmSettings?.dealSeqCodeStatus ===
                TenantInfoSeqCodeStatus.IN_PROGRESS && getInfoIcon()}
            </div>
            <div className="ml-l">
              <Toggle
                isOn={dealSeqCodeToggle}
                onChange={() => {
                  if (!dealSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setSelectedModule(CUSTOM_NUMBER_INPUT_MODULES.DEAL);
                            setAutoNumberPopupToggle(true);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={dealSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.ACTIVITY_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel text={`Activity`} className="mb-xs fw-m" />
              {crmSettings?.activitySeqCodeStatus ===
                TenantInfoSeqCodeStatus.IN_PROGRESS && getInfoIcon()}
            </div>
            <div className="ml-l">
              <Toggle
                isOn={activitySeqCodeToggle}
                onChange={() => {
                  if (!activitySeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setSelectedModule(
                              CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
                            );
                            setAutoNumberPopupToggle(true);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={activitySeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
      </div>
      {autoNumberPopupToggle && getAutoNumberSection()}
    </div>
  );
}
