import EmailAlertSettings from "../settings/EmailAlert";
import GroupingSettings from "../settings/GroupingSettings";
import AccountLinking from "../settings/AccountLinking";
import AccountLinkingForDealAndQuote from "../settings/AccountLinkingForDealAndQuote";
import UpliftDownLiftSetting from "../settings/UpliftDownLiftSetting";
import PriceBookModuleSelectionPopup from "../settings/PriceBookModuleSelectionPopup";
import QuoteSyncPopupSetting from "../settings/QuoteSyncPopupSetting";
import QuoteApprovalLockPopupSetting from "../settings/QuoteApprovalLockPopup";
import SequenceCodeSettings from "../settings/sequnceCodeSettings";
import { useAppSelector } from "../../redux/hooks";
import { SETTING_MODULE } from "../../constants/Constant";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import NoRecordFound from "../common/NoRecordFound";
import TicketAutoMailCCSetting from "../settings/TicketAutoMailCCSetting";
import QuoteActionSetting from "../settings/QuoteActionSetting";
import QuoteEditBlockMultiUser from "../settings/QuoteEditBlockMultiUser";
import QuoteApprovalRestartOnReject from "../settings/QuoteApprovalRestartOnReject";
import QuoteDraftSequence from "../settings/QuoteDraftSequence";
import AutoSelectOwnerSubOwnerForDeal from "../settings/AutoSelectOwnerSubOwnerForDeal";
import TicketAssigneeBasedOnRole from "../settings/TicketAssigneeBasedOnRole";
import TicketRequestorEmailCriteria from "../settings/TicketRequestorEmailCriteria";
import DefaultPipelineSelection from "../settings/DefaultPipelineSelection";
import QuotePushBackSetting from "../settings/quotePushBackSettng";
import ContactTabVisibility from "../settings/ContactTabVisibility";
const AdvancedSettingsPage = () => {
  const settingPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, SETTING_MODULE)
  );

  const getEmptyState = () => (
    <div className="column parent-width">
      <NoRecordFound
        title={" "}
        subTitle={`Looks like you don't have permission`}
      />
    </div>
  );

  const checkForNoPermissions = () => {
    return (
      !settingPermissions[USER_ACTION_TYPES.EMAIL_ALERT] &&
      !settingPermissions[USER_ACTION_TYPES.PRODUCT_GROUPING] &&
      !settingPermissions[USER_ACTION_TYPES.ACCOUNT_LINKAGE] &&
      !settingPermissions[USER_ACTION_TYPES.CONTACT_LINKAGE_TO_QUOTE_DEAL] &&
      !settingPermissions[USER_ACTION_TYPES.UPLIFT_DONWLIFT_QUOTE] &&
      !settingPermissions[USER_ACTION_TYPES.PRICE_BOOK_CRITERIA_MODULE] &&
      !settingPermissions[USER_ACTION_TYPES.QUOTATION_SYNC] &&
      !settingPermissions[USER_ACTION_TYPES.QUOTE_APPROVAL_LOCK_ENABED] &&
      !settingPermissions[USER_ACTION_TYPES.QUOTE_EDIT_BLOCK_MULTI_USER] &&
      !settingPermissions[USER_ACTION_TYPES.QUOTE_APPROVAL_RESTART_ON_REJECT] &&
      !settingPermissions[USER_ACTION_TYPES.QUOTE_ACTIONS] &&
      !settingPermissions[USER_ACTION_TYPES.CONTACT_SEQ_CODE] &&
      !settingPermissions[USER_ACTION_TYPES.SUPPORT_TICKET_AUTO_REPLY] &&
      !settingPermissions[USER_ACTION_TYPES.DRAFT_DOCUMENT_NUMBER_ALLOCATION] &&
      !settingPermissions[USER_ACTION_TYPES.TICKET_ASSIGNEE_ROLES] &&
      !settingPermissions[
        USER_ACTION_TYPES.TICKET_REQUESTOR_EMAIL_MATCH_CRITERIA
      ]
    );
  };

  return (
    <div className="parent-size overflow-y-auto pr-r pb-l">
      {settingPermissions[USER_ACTION_TYPES.EMAIL_ALERT] && (
        <EmailAlertSettings />
      )}
      {settingPermissions[USER_ACTION_TYPES.PRODUCT_GROUPING] && (
        <GroupingSettings />
      )}
      {settingPermissions[USER_ACTION_TYPES.ACCOUNT_LINKAGE] && (
        <AccountLinking />
      )}
      {settingPermissions[USER_ACTION_TYPES.CONTACT_LINKAGE_TO_QUOTE_DEAL] && (
        <AccountLinkingForDealAndQuote />
      )}
      {settingPermissions[USER_ACTION_TYPES.UPLIFT_DONWLIFT_QUOTE] && (
        <UpliftDownLiftSetting />
      )}
      {settingPermissions[USER_ACTION_TYPES.PRICE_BOOK_CRITERIA_MODULE] && (
        <PriceBookModuleSelectionPopup />
      )}
      {settingPermissions[USER_ACTION_TYPES.QUOTATION_SYNC] && (
        <QuoteSyncPopupSetting />
      )}
      {settingPermissions[
        USER_ACTION_TYPES.QUOTE_APPROVAL_RESTART_ON_REJECT
      ] && <QuoteApprovalRestartOnReject />}
      {settingPermissions[USER_ACTION_TYPES.QUOTE_APPROVAL_LOCK_ENABED] && (
        <QuoteApprovalLockPopupSetting />
      )}
      {settingPermissions[USER_ACTION_TYPES.QUOTE_EDIT_BLOCK_MULTI_USER] && (
        <QuoteEditBlockMultiUser />
      )}
      {settingPermissions[USER_ACTION_TYPES.QUOTE_ACTIONS] && (
        <QuoteActionSetting />
      )}
      {(settingPermissions[USER_ACTION_TYPES.CONTACT_SEQ_CODE] ||
        settingPermissions[USER_ACTION_TYPES.ACCOUNT_SEQ_CODE] ||
        settingPermissions[USER_ACTION_TYPES.DEAL_SEQ_CODE] ||
        settingPermissions[USER_ACTION_TYPES.ACTIVITY_SEQ_CODE]) && (
        <SequenceCodeSettings />
      )}
      {settingPermissions[USER_ACTION_TYPES.SUPPORT_TICKET_AUTO_REPLY] && (
        <TicketAutoMailCCSetting />
      )}
      {settingPermissions[
        USER_ACTION_TYPES.DRAFT_DOCUMENT_NUMBER_ALLOCATION
      ] && <QuoteDraftSequence />}
      {settingPermissions[USER_ACTION_TYPES.AUTO_POPULATE_OWNER_SUB_OWNER] && (
        <AutoSelectOwnerSubOwnerForDeal />
      )}
      {settingPermissions[USER_ACTION_TYPES.TICKET_ASSIGNEE_ROLES] && (
        <TicketAssigneeBasedOnRole />
      )}
      {settingPermissions[
        USER_ACTION_TYPES.TICKET_REQUESTOR_EMAIL_MATCH_CRITERIA
      ] && <TicketRequestorEmailCriteria />}
      {settingPermissions[USER_ACTION_TYPES.DEFAULT_PIPELINE_VIEW] && (
        <DefaultPipelineSelection />
      )}
      {settingPermissions[USER_ACTION_TYPES.QUOTE_PULLBACK_ENABLED] && (
        <QuotePushBackSetting />
      )}
      {(settingPermissions[USER_ACTION_TYPES.CONTACT_ESTIMATES_TAB_VISIBLE] ||
        settingPermissions[USER_ACTION_TYPES.CONTACT_INVOICES_TAB_VISIBLE]) && (
        <ContactTabVisibility />
      )}
      {checkForNoPermissions() && getEmptyState()}
    </div>
  );
};

export default AdvancedSettingsPage;
