import React, { useEffect, useRef, useState } from "react";
import {
  DKButton,
  showToast,
  TOAST_TYPE,
  INPUT_VIEW_DIRECTION,
  DKInput,
  DKIcon,
  DKIcons
} from "deskera-ui-library";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchSequenceFormats,
  getSequenceFormats,
  getTenantsDetails,
  getContactSequenceFormats,
  fetchContactSequenceFormats,
  getAccountSequenceFormats,
  getDealSequenceFormats,
  getActivitySequenceFormats,
  fetchDealSequenceFormats,
  fetchAccountSequenceFormats,
  fetchActivitySequenceFormats
} from "../../../redux/slices/booksSlice";
import { useDebounce } from "../../../utility/Debounce";
import Utility, { sanitizeForExcel } from "../../../utility/Utility";
import CustomNumberFormatService from "../../../services/CustomNumberFormat";
import {
  defaultCustomNumberFormatPreFixSuffix,
  generateSequenceCodeWithPrefixSuffix
} from "./CustomNumber";
import {
  APP_NAME,
  CUSTOM_NUMBER_INPUT_MODULES,
  POPUP_CLICK_TYPE
} from "../../../constants/Constant";
import CustomDropdowList from "./CustomDropdowList";
import CustomNumberFormat from "./CustomNumberFormat";
import { selectPermissionsByModule } from "../../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../../constants/Permission";

function CustomNumberFormatInput(props: any) {
  /**
   * Custom Number Format
   */
  const dispatch = useAppDispatch();
  const compactInputRef = useRef<any>(null);
  const tenantDetails = useAppSelector(getTenantsDetails);
  const permissions = useAppSelector((state) =>
    selectPermissionsByModule(state, "quote")
  );

  // const refInitialState: UpdateCallBacksRefType = {
  //     pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  //     storeCallbacksRef: { updateOrder: 'click' }
  // };

  // const addOrderRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const quoteCustomNumberList: any = useAppSelector(getSequenceFormats);
  const contactCustomNumberList: any = useAppSelector(
    getContactSequenceFormats
  );

  const accountCustomNumberList: any = useAppSelector(
    getAccountSequenceFormats
  );
  const dealCustomNumberList: any = useAppSelector(getDealSequenceFormats);
  const activityCustomNumberList: any = useAppSelector(
    getActivitySequenceFormats
  );

  const [showCustomNumberDropdownList, setShowCustomNumberDropdownList] =
    useState<boolean>(false);

  const showCustomNumberDropdown = () => {
    setShowCustomNumberDropdownList((value) => !value);
  };

  const [showCustomNumberPopup, setShowCustomNumberPopup] =
    useState<boolean>(false);

  const [manualModeActive, setManualModeActive] = useState(
    props.autoNumberingFormat?.manualMode ?? false
  );
  const [manualEntry, setManualEntry] = useState<string>(
    props.autoNumberingFormat?.text ?? ""
  );
  const debouncedManualEntry = useDebounce(manualEntry, 500);

  const initialCustomNumberFormatState = [] as any;

  const [customNumberFormatList, setCustomNumberFormatList] = useState(
    initialCustomNumberFormatState
  );

  const [sequenceCode, setSequenceCode] = useState(
    props.autoNumberingFormat?.text ?? ""
  );
  const [customFormatNumberData, setCustomFormatNumberData] = useState("");

  useEffect(() => {
    renderUpdatedCustomNumberFormat();
  });
  useEffect(() => {
    if (showCustomNumberPopup) {
      props?.onCustomNumberPopupScrollToTop?.();
    }
  }, [showCustomNumberPopup]);

  useEffect(() => {
    if (props.openList) {
      setShowCustomNumberDropdownList(true);
    }
    if (
      !Utility.isEmptyObject(props.autoNumberingFormat) &&
      [
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
        CUSTOM_NUMBER_INPUT_MODULES.DEAL,
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
        CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
      ].includes(props.module)
    ) {
      if (props.autoNumberingFormat.manualMode) {
        setManualModeActive(true);
        setSequenceCode(props.autoNumberingFormat.text);
        setManualEntry(props.autoNumberingFormat.text);
      }
    }
  }, [props]);

  useEffect(() => {
    const checkCodeExists = async () => {
      if (!Utility.isEmptyObject(debouncedManualEntry)) {
        const codeExists =
          await CustomNumberFormatService.checkNumberFormatExistance(
            debouncedManualEntry,
            props.module
          );
        if (codeExists?.status === 200) {
          setSequenceCode(debouncedManualEntry);
          let data = {};
          if (
            [
              CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
              CUSTOM_NUMBER_INPUT_MODULES.DEAL,
              CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
              CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
            ].includes(props.module) &&
            !Utility.isEmptyObject(props.autoNumberingFormat)
          ) {
            data = {
              text: debouncedManualEntry,
              id: null,
              manualMode: manualModeActive
            };
          } else {
            data = {
              text: debouncedManualEntry,
              id: null,
              manualMode: manualModeActive
            };
          }
          props.selectedFormat(data);
        } else if (codeExists?.status === 409) {
          setManualEntry("");
          showToast(codeExists?.message, TOAST_TYPE.WARNING);
        }
      } else {
        if (
          [
            CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
            CUSTOM_NUMBER_INPUT_MODULES.DEAL,
            CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
            CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
          ].includes(props.module) &&
          !props.showCompact
        ) {
          resetSequenceCode();
        } else {
          let data = {};
          data = {
            text: "",
            id: null,
            manualMode: manualModeActive
          };
          props.selectedFormat(data);
        }
      }
    };

    checkCodeExists();
  }, [debouncedManualEntry]);

  const resetSequenceCode = () => {
    if (
      [
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
        CUSTOM_NUMBER_INPUT_MODULES.DEAL,
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
        CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
      ].includes(props.module) &&
      !Utility.isEmptyObject(props?.autoNumberingFormat?.id)
    ) {
      const defaultFormatIndex = customNumberFormatList.findIndex(
        (format: any) => format.id === props.autoNumberingFormat.id
      );
      const defaultFormat = customNumberFormatList[defaultFormatIndex];
      if (!Utility.isEmptyObject(defaultFormat)) {
        setSequenceCode(defaultFormat.sequenceCode);
        sendSelectedFormat(defaultFormatIndex, defaultFormat.sequenceCode);
      }
    } else {
      if (!Utility.isEmptyObject(customNumberFormatList)) {
        const defaultFormatIndex = customNumberFormatList.findIndex(
          (format: any) => format.isDefault
        );
        const defaultFormat = customNumberFormatList[defaultFormatIndex];
        if (!Utility.isEmptyObject(defaultFormat)) {
          if (
            defaultCustomNumberFormatPreFixSuffix(
              defaultFormat.module,
              defaultFormat.sequenceCode
            )
          ) {
            let sequenceCode =
              generateSequenceCodeWithPrefixSuffix(defaultFormat);
            setSequenceCode(sequenceCode);
            sendSelectedFormat(defaultFormatIndex, sequenceCode);
          } else {
            setSequenceCode(defaultFormat.sequenceCode);
            sendSelectedFormat(defaultFormatIndex, defaultFormat.sequenceCode);
          }
        }
      }
    }
  };

  useEffect(() => {
    resetSequenceCode();
  }, [customNumberFormatList]);

  const customNumberpopupBtnConfig: any[] = [
    {
      title: "Cancel",
      class: "border-m mr-s",
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: "Save",
      class: "bg-app text-white mr-s",
      clickAction: POPUP_CLICK_TYPE.SAVE_CUSTOM_DOCUMENT_NUMBER
    }
  ];

  const onSequenceCodeClick = (index: any, value: any) => {
    if (
      defaultCustomNumberFormatPreFixSuffix(value.module, value.sequenceCode)
    ) {
      let sequenceCode = generateSequenceCodeWithPrefixSuffix(value);
      setSequenceCode(sequenceCode);
      sendSelectedFormat(index, sequenceCode);
    } else {
      setSequenceCode(value.sequenceCode);
      sendSelectedFormat(index, value.sequenceCode);
    }
    setShowCustomNumberDropdownList(false);
  };

  const onSequenceCodeEdit = (index: any, value: any) => {
    setShowCustomNumberPopup(true);
    setCustomFormatNumberData(value);
  };

  const onSequenceCodeDelete = (index: any, value: any) => {
    if (value) {
      let payload = { sequenceId: value.id };
      CustomNumberFormatService.deleteCustomFormatNumber(payload).then(
        (response: any) => {
          if (response && response.status === 409) {
            setManualEntry("");
            showToast(response.message, TOAST_TYPE.WARNING);
          }

          if (response && response.status === 200) {
            showToast("Deleted Successfully", TOAST_TYPE.SUCCESS);
            fetchUpdatedCustomNumberFormat();
          }
        },
        (err) => {
          console.error("Error while custom number format: ", err);
        }
      );
    }
  };

  const sendSelectedFormat = (index: any, value: any) => {
    let text = value.toString();
    let id: any = "",
      data: any = {};

    switch (props.module) {
      case CUSTOM_NUMBER_INPUT_MODULES.QUOTE:
        id = quoteCustomNumberList[index].id;
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
        id = contactCustomNumberList[index].id;
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.DEAL:
        id = dealCustomNumberList[index].id;
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
        id = accountCustomNumberList[index].id;
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY:
        id = activityCustomNumberList[index].id;
        break;
    }
    if (
      [
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
        CUSTOM_NUMBER_INPUT_MODULES.DEAL,
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
        CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
      ].includes(props.module) &&
      props?.autoNumberingFormat?.manualMode
    ) {
      data = { ...props?.autoNumberingFormat };
    } else {
      if (!Utility.isEmptyObject(props?.autoNumberingFormat)) {
        data = {
          text,
          id,
          manualMode: manualModeActive
        };
      } else {
        data = {
          text,
          id,
          manualMode: manualModeActive
        };
      }
    }
    props.selectedFormat(data);
  };
  const closePopup = () => {
    setShowCustomNumberPopup(false);
    setCustomFormatNumberData("");
  };
  const savePopup = () => {
    // CustomNumberFormatService.createCustomNumberFormat()
  };

  const catchClicks = (data: any) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowCustomNumberPopup(false);
        setCustomFormatNumberData("");
        break;
      case POPUP_CLICK_TYPE.SAVE_CUSTOM_DOCUMENT_NUMBER:
        // addOrderRef.current?.storeCallbacksRef.saveCustomDocumentNumber();
        break;
    }
  };

  const fetchUpdatedCustomNumberFormat = () => {
    switch (props.module) {
      case CUSTOM_NUMBER_INPUT_MODULES.QUOTE:
        dispatch(
          fetchSequenceFormats(tenantDetails?.useSeparateSequenceFormat)
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
        dispatch(
          fetchContactSequenceFormats({
            payload: {
              application: APP_NAME,
              module: CUSTOM_NUMBER_INPUT_MODULES.CONTACT
            }
          })
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.DEAL:
        dispatch(
          fetchDealSequenceFormats({
            payload: {
              application: APP_NAME,
              module: CUSTOM_NUMBER_INPUT_MODULES.DEAL
            }
          })
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
        dispatch(
          fetchAccountSequenceFormats({
            payload: {
              application: APP_NAME,
              module: CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT
            }
          })
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY:
        dispatch(
          fetchActivitySequenceFormats({
            payload: {
              application: APP_NAME,
              module: CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY
            }
          })
        );
        break;
    }
  };

  const renderUpdatedCustomNumberFormat = () => {
    switch (props.module) {
      case CUSTOM_NUMBER_INPUT_MODULES.QUOTE:
        setCustomNumberFormatList(quoteCustomNumberList);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
        setCustomNumberFormatList(contactCustomNumberList);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
        setCustomNumberFormatList(accountCustomNumberList);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.DEAL:
        setCustomNumberFormatList(dealCustomNumberList);
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACTIVITY:
        setCustomNumberFormatList(activityCustomNumberList);
        break;
    }
  };

  const onMouseUp = (e: any) => {
    const target = e.target;
    const editingContainer = target.closest("#custom-number-list");

    if (!editingContainer) {
      setShowCustomNumberDropdownList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (compactInputRef && (props.autoFocus ?? true)) {
      compactInputRef.current?.focus();
    }
  }, [manualModeActive]);

  /**
   * Custom Number Format
   */

  return (
    <div
      className={
        props.showCompact ? "" : "position-relative parent-width column"
      }
    >
      {props.showCompact && (
        <div className="position-relative custom-number-compact-input d-flex justify-content-end">
          {!manualModeActive && (
            <DKButton
              className={
                "parent-width compact-custom-no-input justify-content-end "
              }
              style={{
                borderRadius: 4,
                padding: props.buttonStylePadding ?? 0
              }}
              title={sequenceCode ? sequenceCode : "Select"}
              onClick={() =>
                permissions?.[USER_ACTION_TYPES.DOCUMENT_SEQUENCE_CODE_EDITABLE]
                  ? showCustomNumberDropdown()
                  : null
              }
            />
          )}
          {manualModeActive && (
            <>
              <input
                name="custom-input-box"
                ref={compactInputRef}
                type="text"
                className={`${
                  Utility.isEmptyObject(manualEntry) && manualModeActive
                    ? `border-red`
                    : `border-transparent`
                } parent-width text-align-right p-m listPickerBG rounded border-gray-300 app-font`}
                style={{
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 4
                }}
                disabled={!manualModeActive}
                value={manualEntry}
                onChange={(e: any) =>
                  setManualEntry(sanitizeForExcel(e.target.value))
                }
              />
              <div
                className={`position-absolute cursor-hand opacity-0 disable-input-mode`}
                style={{ top: 7, left: "0.375rem" }}
                title="Disable manual input"
                onClick={() => {
                  setManualModeActive(false);
                  resetSequenceCode();
                }}
              >
                <DKIcon
                  src={DKIcons.ic_close_}
                  className="ic-s"
                  style={{ height: 12 }}
                />
              </div>
            </>
          )}
        </div>
      )}
      {!props.showCompact && (
        <div
          className={
            "position-relative parent-width justify-content-between " +
            (props.isRow ? "row" : "column")
          }
        >
          <div className={"mt-1 parent-width position-relative"}>
            <DKInput
              title={
                props.hideLabel ? "" : props.label || "Auto-Numbering Format"
              }
              autoFocus={manualModeActive ? true : false}
              direction={
                props.isRow
                  ? INPUT_VIEW_DIRECTION.HORIZONTAL
                  : INPUT_VIEW_DIRECTION.VERTICAL
              }
              value={manualEntry}
              titleStyle={props?.titleStyle}
              placeholder={
                sequenceCode ? sequenceCode : "Auto-Numbering Format"
              }
              readOnly={!manualModeActive}
              onClick={() =>
                manualModeActive ? "" : showCustomNumberDropdown()
              }
              onChange={(value: string) => {
                if (!Utility.isEmptyObject(value)) {
                  setManualEntry(value);
                } else {
                  const data = {
                    ...props.autoNumberingFormat,
                    text: "",
                    manualMode: manualModeActive
                  };
                  props.selectedFormat(data);
                  setManualEntry("");
                }
              }}
            />
            {manualModeActive && (
              <div
                className={`position-absolute right-2 cursor-hand ${
                  props.isRow ? "top-25" : "bottom-25"
                } opacity-60`}
                title="Disable manual mode"
                onClick={() => {
                  setManualEntry("");
                  const data = {
                    ...props.autoNumberingFormat,
                    manualMode: false
                  };
                  props.selectedFormat(data);
                  setManualModeActive(false);
                  showCustomNumberDropdown();
                }}
              >
                <DKIcon src={DKIcons.ic_close} className="ic-s" />
              </div>
            )}
          </div>
        </div>
      )}
      {showCustomNumberDropdownList && (
        <div
          id="custom-number-list"
          className={`custom-number-list position-absolute ${props.extraClass}`}
          style={{
            width: "15.3rem",
            marginTop: "-1rem",
            top: "3rem",
            marginBottom: "-1rem",
            right: 0,
            zIndex: 10
          }}
        >
          <CustomDropdowList
            className="shadow-l"
            data={customNumberFormatList}
            needIcon={false}
            createNewButton={{
              title: `+ Custom Number Format`,
              className: "text-blue fw-b ml-xs",
              onClick: () => {
                setShowCustomNumberPopup(true);
              }
            }}
            manualInputButton={{
              title: `Manual input`,
              className: "text-blue fw-b ml-xs",
              onClick: () => {
                setShowCustomNumberPopup(false);
                showCustomNumberDropdown();
                setManualModeActive(true);
                const data = {
                  ...props.autoNumberingFormat,
                  id: null,
                  text: "",
                  manualMode: true
                };
                props.selectedFormat(data);
              }
            }}
            onSelect={(index: number, value: any) => {
              onSequenceCodeClick(index, value);
            }}
            onEdit={(index: number, value: any) => {
              onSequenceCodeEdit(index, value);
            }}
            onDelete={(index: number, value: any) => {
              onSequenceCodeDelete(index, value);
            }}
          />
        </div>
      )}
      {showCustomNumberPopup && (
        <div className="transparent-background">
          <div className="popup-window" style={{}}>
            <CustomNumberFormat
              data={customFormatNumberData}
              documentType={props.module}
              onClose={() => closePopup()}
              onSave={() => {
                fetchUpdatedCustomNumberFormat();
                setShowCustomNumberPopup(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomNumberFormatInput;
